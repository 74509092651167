import { useEffect } from "react";
import { useState } from "react";

const useGeolocation = () => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: 0, lng: 0 },
  });

  const onSuccess = (loc) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: loc.coords.latitude,
        lng: loc.coords.longitude,
      },
    });
  };

  const onError = (err) => {
    setLocation({
      loaded: true,
      err,
    });
  };

  useEffect(() => {
    if (!navigator.geolocation) {
      onError({ code: 0, message: "cannot get geolocation" });
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  });

  return [location, setLocation];
};

export default useGeolocation;
