import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getMessageRooms } from "../../actions/messageActions";
import { logout } from "../../actions/requestActions";
import { VILLAS_RESET_FILTER } from "../../constants/villaConstants";
import "./Header.css";

const Header = () => {
  const [active, setActive] = useState("");
  const pathName = ["faq", "contact", "evenement"];
  const getPathURL = window.location.pathname;
  const overlayHero = pathName.find((path) => getPathURL?.includes(path));
  const { user } = useSelector((state) => state.createRequest);
  const { rooms } = useSelector((state) => state.rooms);
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownCorporate, setShowDropdownCorporate] = useState(false);
  const [showDropdownPrivate, setShowDropdownPrivate] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleNavActive = (menu) => {
    if (menu === "privateEvent") {
      return;
    }
    setOpenMenu(false);
    setActive(menu);
    if (!window.location.pathname?.includes("villas")) {
      dispatch({ type: VILLAS_RESET_FILTER });
    }
  };

  const handleClickItmeDropdown = (item) => {
    history.push(`/${item}`);
    setShowDropdown(false);
    if (!window.location.pathname?.includes("villas")) {
      dispatch({ type: VILLAS_RESET_FILTER });
    }
  };

  useEffect(() => {
    if (!window.location.pathname?.includes("message")) {
      dispatch(getMessageRooms());
    }

    if (!window.location.pathname?.includes("villas")) {
      dispatch({ type: VILLAS_RESET_FILTER });
    }

    setActive(
      window.location.pathname.substring(1) === ""
        ? "home"
        : window.location.pathname.substring(1)
    );

    window.onclick = (e) => {
      setActive(
        window.location.pathname.substring(1) === ""
          ? "home"
          : window.location.pathname.substring(1)
      );

      if (!e.target.closest("#menu-dropdown") && showDropdown) {
        setShowDropdown(false);
      }
    };
    // eslint-disable-next-line
  }, [dispatch]);
  return (
    <header className={`container relative`}>
      <Link to="/">
        <div className="header__logo" onClick={() => setActive("home")}>
          <img src="/images/logo/logo.png" alt="Maiden" />
          <span>Maiden Agency</span>
        </div>
      </Link>
      <nav>
        <input
          type="checkbox"
          className="header__checked"
          checked={openMenu}
          onChange={() => setOpenMenu((openMenu) => !openMenu)}
        />
        <i className="cross-mark" onClick={() => setOpenMenu(false)}></i>
        <div
          className={`header__item header__menu ${overlayHero && "text-black"}`}
        >
          <svg
            width="525"
            height="468"
            viewBox="0 0 525 468"
            fill="none"
            className="header__mobileIcon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0H525V23.3333H0V0Z" fill="black" />
            <path d="M0 221.88H525V245.213H0V221.88Z" fill="black" />
            <path d="M0 443.76H525V467.093H0V443.76Z" fill="black" />
          </svg>
        </div>
        <div
          className={`header__item header__profile ${
            active?.includes("message") && "active"
          }  ${overlayHero && "text-black"}`}
          onClick={() => handleNavActive("message")}
        >
          <div className="header__menuDropdownContainer">
            <svg
              viewBox="0 0 20 23"
              onClick={() => {
                setShowDropdown((showDropdown) => !showDropdown);
                setShowDropdownCorporate(false);
                setShowDropdownPrivate(false);
              }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.9506 16.3802C15.3783 15.5669 12.7849 14.5 10 14.5C7.21513 14.5 4.62175 15.5669 3.04942 16.3802C2.03431 16.9052 1.42295 17.9351 1.2883 19.07L1 21.5H19L18.7117 19.07C18.577 17.9351 17.9657 16.9052 16.9506 16.3802Z"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 10C12.4853 10 14.5 7.98528 14.5 5.5C14.5 3.01472 12.4853 1 10 1C7.51472 1 5.5 3.01472 5.5 5.5C5.5 7.98528 7.51472 10 10 10Z"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {showDropdown && (
              <ul className="header__menuDropdownItems" id="menu-dropdown">
                <li
                  onClick={() => handleClickItmeDropdown("message")}
                  className={`${active?.includes("message") && "active"}`}
                >
                  <div>
                    <svg
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00098 2C3.68727 2 1.00098 4.68629 1.00098 8V12C1.00098 12.2403 1.01511 12.4774 1.04259 12.7104C1.01489 12.802 1 12.8993 1 13V21.8258C1 22.6801 2.00212 23.141 2.65079 22.585L7.43827 18.4815C7.80075 18.1708 8.26243 18 8.73985 18H19.001C22.3147 18 25.001 15.3137 25.001 12V8C25.001 4.68629 22.3147 2 19.001 2H7.00098Z"
                        strokeWidth="2"
                      />
                    </svg>
                    <span>Message</span>
                  </div>
                  {user ? (
                    rooms.filter(
                      (room) => room?.request?.client_id === user?.user_id
                    ).length ? (
                      <span className="messageMenu__unread">
                        {
                          rooms.filter(
                            (room) => room?.request?.client_id === user?.user_id
                          ).length
                        }
                      </span>
                    ) : null
                  ) : (
                    ""
                  )}
                </li>
                <li
                  onClick={() => handleClickItmeDropdown("reservation")}
                  className={`${active?.includes("reservation") && "active"}`}
                >
                  <div>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="2"
                        y="3"
                        width="20"
                        height="19"
                        rx="3"
                        strokeWidth="2"
                      />
                      <path
                        d="M7 1V3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 1V3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 8H22"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 13H9.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 13H16.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 17H9.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 17H16.5"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Réservations</span>
                  </div>
                </li>
                {/* <li
                  onClick={() => handleClickItmeDropdown("quizz")}
                  className={`${active?.includes("quizz") && "active"} quizz`}
                >
                  <div>
                    <span>Quizz</span>
                  </div>
                </li> */}
                {/* <li>
                  <div>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.9506 17.3802C17.3783 16.5669 14.7849 15.5 12 15.5C9.21513 15.5 6.62175 16.5669 5.04942 17.3802C4.03431 17.9052 3.42295 18.9351 3.2883 20.07L3 22.5H21L20.7117 20.07C20.577 18.9351 19.9657 17.9052 18.9506 17.3802Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 11C14.4853 11 16.5 8.98528 16.5 6.5C16.5 4.01472 14.4853 2 12 2C9.51472 2 7.5 4.01472 7.5 6.5C7.5 8.98528 9.51472 11 12 11Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span> Mon profil</span>
                  </div>
                </li> */}
                {user ? (
                  <span
                    className="header__menuDropdownItemAuth logout"
                    onClick={() => {
                      dispatch(logout());
                      history.push("/");
                      setShowDropdown(false);
                    }}
                  >
                    Déconnexion
                  </span>
                ) : (
                  <span
                    className="header__menuDropdownItemAuth"
                    onClick={() => handleClickItmeDropdown("auth")}
                  >
                    Connexion
                  </span>
                )}
              </ul>
            )}
          </div>
        </div>

        <ul className="header__items">
          <li
            className={`header__item ${active?.includes("home") && "active"} ${
              overlayHero && "text-black"
            }`}
            onClick={() => handleNavActive("home")}
          >
            <Link to="/">Accueil</Link>
          </li>

          <li
            className={`header__item ${
              active?.includes("privateEvent") && "active"
            } ${overlayHero && "text-black"}`}
            onClick={() => handleNavActive("privateEvent")}
          >
            <span
              className="md:hidden text-black flex font-semibold  items-center gap-x-2"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              Évènement privé
              {showMobileMenu ? (
                <svg
                  fill="#ffffff"
                  height="10px"
                  width="10px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 490 490"
                  className="mb-[2px]"
                >
                  <polygon
                    points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490
               489.292,457.678 277.331,245.004 489.292,32.337 "
                  />
                </svg>
              ) : (
                <svg
                  className="hidden md:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  class="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                </svg>
              )}
            </span>
            {showMobileMenu ? (
              <ul className="md:hidden flex-col gap-y-2 pl-5 mt-3 flex">
                <li>
                  <a
                    href="/privateEvent#mariage"
                    onClick={() => setOpenMenu(false)}
                  >
                    Mariage
                  </a>
                </li>
                <li>
                  <a
                    href="/privateEvent#babyShower"
                    onClick={() => setOpenMenu(false)}
                  >
                    Baby Shower
                  </a>
                </li>
                <li>
                  <a
                    href="/privateEvent#baptism"
                    onClick={() => setOpenMenu(false)}
                  >
                  Fête religieuse
                  </a>
                </li>
                <li>
                  <a
                    href="/privateEvent#birthday"
                    onClick={() => setOpenMenu(false)}
                  >
                    Anniversaire
                  </a>
                </li>
                <li>
                  <a href="/evjf" onClick={() => setOpenMenu(false)}>
                    EVJF
                  </a>
                </li>
              </ul>
            ) : (
              <></>
            )}

            <span
              className="hidden sm:hidden md:flex gap-x-2 font-semibold"
              onClick={() => {
                setShowDropdownPrivate(!showDropdownPrivate);
                setShowDropdown(false);
                setShowDropdownCorporate(false);
              }}
            >
              Évènement privé
              <svg
                className="hidden md:block"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="black"
                class="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
            </span>
          </li>
          <li
            className={`header__item ${
              active?.includes("corporateEvent") && "active"
            } ${overlayHero && "text-black"}`}
            onClick={() => handleNavActive("corporateEvent")}
          >
            <Link to="/corporateEvent">Évènement d'entreprise</Link>
          </li>

          <li
            className={`header__item ${
              active?.includes("contact") && "active"
            } ${overlayHero && "text-black"}`}
            onClick={() => handleNavActive("contact")}
          >
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>

      {showDropdownPrivate && (
        <ul className="absolute hidden  top-[80px] left-[50%] bg-brownBg px-10 py-3 md:flex flex-col gap-y-3 rounded-xl">
          <li>
            <div>
              <a href="/privateEvent#mariage">Mariage</a>
            </div>
          </li>

          <li>
            <div>
              <a href="/privateEvent#babyShower">Baby Shower</a>
            </div>
          </li>
          <li>
            <div>
              <a href="/privateEvent#baptism">Fête religieuse
</a>
            </div>
          </li>
          <li>
            <div>
              <a href="/privateEvent#birthday">Anniversaire</a>
            </div>
          </li>
          <li>
            <div>
              <a href="/evjf">EVJF</a>
            </div>
          </li>
        </ul>
      )}
    </header>
  );
};

export default Header;
