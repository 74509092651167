// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentionLegal .heroSkeleton,
.mentionLegal .hero {
  margin-top: unset !important;
}

.mentionLegal__content {
  padding: 4em 0;
  line-height: 1.75;
}

.mentionLegal h3,
.mentionLegal h2 {
  font-weight: bolder;
}

.mentionLegal ul {
  list-style: disc;
  padding-left: 1.25em;
}

.mentionLegal a {
  color: var(--clr-primary);
}
`, "",{"version":3,"sources":["webpack://./src/pages/MentionLegal/MentionLegal.css"],"names":[],"mappings":"AAAA;;EAEE,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".mentionLegal .heroSkeleton,\n.mentionLegal .hero {\n  margin-top: unset !important;\n}\n\n.mentionLegal__content {\n  padding: 4em 0;\n  line-height: 1.75;\n}\n\n.mentionLegal h3,\n.mentionLegal h2 {\n  font-weight: bolder;\n}\n\n.mentionLegal ul {\n  list-style: disc;\n  padding-left: 1.25em;\n}\n\n.mentionLegal a {\n  color: var(--clr-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
