import axios from "axios";
import moment from "moment";
import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  MAKE_REQUEST_FAIL,
  MAKE_REQUEST_REQUEST,
  MAKE_REQUEST_SUCCESS,
  SET_REQUEST,
} from "../constants/requestConstants";

export const setRequest = (data) => (dispatch) => {
  dispatch({ type: SET_REQUEST, payload: data });
};

export const makeRequest =
  (requests, dataValues, isNewUser) => async (dispatch) => {
    dispatch({ type: MAKE_REQUEST_REQUEST });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/requests`,
        {
          request: {
            ...dataValues,
            payload: {
              ...requests,
            },
          },
        }
      );
      dispatch({
        type: MAKE_REQUEST_SUCCESS,
        payload: isNewUser ? { ...res.data, isNewUser } : res.data,
      });
    } catch (err) {
      dispatch({
        type: MAKE_REQUEST_FAIL,
        payload:
          err.response && err.response.data.error
            ? err.response.data.error
            : err.erorr,
      });
    }
  };

export const login = (data) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/auth`,
      data
    );
    dispatch({ type: LOGIN_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload:
        err.response && err.response.data.error
          ? err.response.data.error
          : err.error,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("maiden_data");
  localStorage.removeItem("maiden_user");

  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: SET_REQUEST,
    payload: {
      date_from: moment()._d,
      date_to: moment().add(1, "day")._d,
      guests: 0,
      destination_id: "",
      property: null,
      activities: [],
      decoration: {},
      products: [],
      bundles: [],
      delivery: "",
      tag: "original",
      offer_id: null,
    },
  });
};
