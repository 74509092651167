// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datesPopup {
  position: absolute;
  opacity: 0;
  align-items: center;
  left: 0;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: var(--clr-light);
  border: 3px solid var(--clr-secondary);
  border: none;
  box-shadow: -0.25rem 0 2rem rgba(0, 0, 0, 0.15);
  pointer-events: none;
  border-radius: 1em;
  top: calc(100% - 1rem);
  transition: 0.25s;
}
.datesPopup.show {
  opacity: 1;
  pointer-events: all;
}

.datesPopup
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.datesPopup
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  display: none !important;
}
.datesPopup .react-datepicker-popper {
  position: relative !important;
  transform: unset !important;
  top: 0 !important;
  margin: 0 1rem;
}

.datesPopup .react-datepicker__header {
  background: transparent;
}
.datesPopup .react-datepicker {
  background: transparent;
  border: none;
}

.datesPopup .react-datepicker-wrapper {
  opacity: 0;
  position: absolute;
}

@media (min-width: 622px) {
  .datesPopup {
    flex-wrap: nowrap;
  }
}
@media (min-width: 992px) {
  .datesPopup {
    left: unset;
    right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/DatesPopup/DatesPopup.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,mBAAmB;EACnB,OAAO;EACP,aAAa;EACb,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,4BAA4B;EAC5B,sCAAsC;EACtC,YAAY;EACZ,+CAA+C;EAC/C,oBAAoB;EACpB,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;;;;;;EAME,wBAAwB;AAC1B;AACA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,WAAW;IACX,QAAQ;EACV;AACF","sourcesContent":[".datesPopup {\n  position: absolute;\n  opacity: 0;\n  align-items: center;\n  left: 0;\n  display: flex;\n  width: fit-content;\n  height: fit-content;\n  background: var(--clr-light);\n  border: 3px solid var(--clr-secondary);\n  border: none;\n  box-shadow: -0.25rem 0 2rem rgba(0, 0, 0, 0.15);\n  pointer-events: none;\n  border-radius: 1em;\n  top: calc(100% - 1rem);\n  transition: 0.25s;\n}\n.datesPopup.show {\n  opacity: 1;\n  pointer-events: all;\n}\n\n.datesPopup\n  .react-datepicker-popper[data-placement^=\"bottom\"]\n  .react-datepicker__triangle::after,\n.datesPopup\n  .react-datepicker-popper[data-placement^=\"bottom\"]\n  .react-datepicker__triangle::before {\n  display: none !important;\n}\n.datesPopup .react-datepicker-popper {\n  position: relative !important;\n  transform: unset !important;\n  top: 0 !important;\n  margin: 0 1rem;\n}\n\n.datesPopup .react-datepicker__header {\n  background: transparent;\n}\n.datesPopup .react-datepicker {\n  background: transparent;\n  border: none;\n}\n\n.datesPopup .react-datepicker-wrapper {\n  opacity: 0;\n  position: absolute;\n}\n\n@media (min-width: 622px) {\n  .datesPopup {\n    flex-wrap: nowrap;\n  }\n}\n@media (min-width: 992px) {\n  .datesPopup {\n    left: unset;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
