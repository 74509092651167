import React, { useEffect, useState } from "react";
import heroImg from "../../assets/hero/contact_page.jpeg";
import Hero from "../../components/Hero";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Loading from "../../components/Loading";
import "./Contact.css";
import { validateContact } from "../../utils/validateForm";

const Contact = ({ withoutBanner = false, withoutText = false }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
  });
  const form = useRef();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleRemoveValues = (name) => {
    setValues({ ...values, [name]: "" });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    const { errors, valid } = validateContact(values);
    if (!valid) {
      setErrors(errors);
      setLoading(false);

      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          setLoading(false);
          setSuccess(true);
          setValues({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            message: "",
          });
        },
        () => {
          setErrors({ server: "Une erreur s'est produite depuis le serveur" });
          setLoading(false);
        }
      );
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [success]);

  return (
    <div className="contact">
      {!withoutBanner ? (
        <Hero
          title="Des questions ? Contactez nous !"
          heroImg={heroImg}
          model="Model 2"
          headText="Contact"
          height="medium"
        />
      ) : (
        <></>
      )}

      <div className="container">
        {withoutText ? (
          <></>
        ) : (
          <h4 className="contact__text">
            Notre objectif est de vous aider et de vous accompagner dans
            l’organisation et la réalisation de votre événement. Un projet ? une
            idée ? N'hésitez pas à nous contacter et nous vous répondrons dans
            les plus brefs délais.
          </h4>
        )}

        <div className="contact__form">
          <div>
            <form ref={form} onSubmit={handleSubmitForm}>
              {success && (
                <span className="contact__success">
                  Message envoyé avec succès
                </span>
              )}
              {errors.server && (
                <span className="contact__error">{errors.server}</span>
              )}
              <div className="contact__input">
                <div>
                  <div
                    className={`contact__inputContainer ${
                      errors.first_name && "error"
                    }`}
                  >
                    <input
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      type="text"
                      id="first-name"
                      placeholder="Prénom"
                    />
                    <label htmlFor="name">Prénom</label>
                    <svg
                      className="contact__inputClose"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleRemoveValues("first_name")}
                    >
                      <path
                        d="M1 1L13 13"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 13L13 1.00002"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {errors.first_name && (
                    <span className="error-text">{errors.first_name}</span>
                  )}
                </div>
                <div>
                  <div
                    className={`contact__inputContainer ${
                      errors.last_name && "error"
                    }`}
                  >
                    <input
                      name="last_name"
                      onChange={handleChange}
                      value={values.last_name}
                      type="text"
                      placeholder="Nom"
                      onClick={() => handleRemoveValues("last_name")}
                      id="last-name"
                    />
                    <label htmlFor="last-name">Nom</label>
                    <svg
                      className="contact__inputClose"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L13 13"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 13L13 1.00002"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {errors.last_name && (
                    <span className="error-text">{errors.last_name}</span>
                  )}
                </div>
                <div>
                  <div
                    className={`contact__inputContainer ${
                      errors.phone && "error"
                    }`}
                  >
                    <input
                      type="number"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      id="phone-number"
                      placeholder="Téléphone mobile"
                    />
                    <label htmlFor="phone-number">Téléphone mobile</label>
                    <svg
                      className="contact__inputClose"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleRemoveValues("phone")}
                    >
                      <path
                        d="M1 1L13 13"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 13L13 1.00002"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {errors.phone && (
                    <span className="error-text">{errors.phone}</span>
                  )}
                </div>
                <div>
                  <div
                    className={`contact__inputContainer ${
                      errors.email && "error"
                    }`}
                  >
                    <input
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      type="text"
                      placeholder="Adresse e-mail"
                      id="email"
                    />
                    <label htmlFor="email">Adresse e-mail</label>
                    <svg
                      className="contact__inputClose"
                      viewBox="0 0 14 14"
                      fill="none"
                      onClick={() => handleRemoveValues("email")}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L13 13"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 13L13 1.00002"
                        stroke="#6E7191"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {errors.email && (
                    <span className="error-text">{errors.email}</span>
                  )}
                </div>
              </div>
              <textarea
                className={`${errors.message && "error"}`}
                name="message"
                id=""
                cols="30"
                rows="10"
                value={values.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <span className="error-text">{errors.message}</span>
              )}
              <button className="btn" disabled={loading}>
                {loading ? <Loading /> : "Envoyer mon message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
