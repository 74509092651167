import { useEffect, useState } from "react";
import "./PaymentSummary.css";
import moment from "moment";
import { getDestination } from "../../actions/destinationActions";
import { useDispatch, useSelector } from "react-redux";
import { getVilla } from "../../actions/villaActions";
import Delivery from "../Delivery";
import { useHistory } from "react-router-dom";
import InputLabelFloat from "../InputLabelFloat";
import { setRequest } from "../../actions/requestActions";

const PaymentSummary = ({ dataRequest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editDates, setEditDates] = useState(false);
  const [editTravellers, setEditTravellers] = useState(false);
  const { destination, loading: loadingDestination } = useSelector(
    (state) => state.destination
  );
  const { villa, loading: loadingVilla } = useSelector((state) => state.villa);
  const [activityExpand, setActivityExpand] = useState(false);
  const [coffretExpand, setCoffretExpand] = useState(false);
  const [goodiesExpand, setGoodiesExpand] = useState(false);
  // const [goodiesExpand, setGoodiesExpand] = useState(false);

  useEffect(() => {
    dispatch(getDestination(dataRequest.destination_id));
    dispatch(getVilla(dataRequest.property));
  }, [dataRequest, dispatch]);

  return (
    <>
      <div className="payment__sumary">
        <h4 className="payment__sumaryTitle">Le voyage</h4>
        <ul className="payment__sumaryData">
          <li>
            <span className="payment__sumaryLabel">
              Dates
              {editDates ? (
                <i
                  className="cross-mark primary"
                  onClick={() => setEditDates(false)}
                ></i>
              ) : (
                <svg
                  onClick={() => setEditDates(true)}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                    stroke="#A94E4D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10.7003 2.64868L14.7409 6.68929"
                    stroke="#A94E4D"
                    strokeWidth="1.5"
                  />
                </svg>
              )}
            </span>

            {editDates ? (
              <div className="payment__summaryEditDate">
                <InputLabelFloat
                  name="when"
                  label="Quand ?"
                  placeholder="Quand ?"
                  value={dataRequest.date_from}
                  type={{
                    type: "date",
                    minDate: dataRequest.date_from,
                    selected: dataRequest.date_from,
                  }}
                  handleInputDate={(date) => {
                    if (date > dataRequest.date_to) {
                      dispatch(
                        setRequest({
                          ...dataRequest,
                          tag: "original",
                          offer_id: null,
                          date_from: date.setDate(date.getDate()),
                          date_to: date.setDate(date.getDate() + 1),
                        })
                      );
                    } else {
                      dispatch(
                        setRequest({
                          ...dataRequest,
                          tag: "original",
                          offer_id: null,
                          date_from: date,
                        })
                      );
                    }
                  }}
                />
                <InputLabelFloat
                  name="until"
                  label="Jusqu’au ?"
                  placeholder="Jusqu’au ?"
                  type={{
                    type: "date",
                    minDate: dataRequest.date_to,
                    selected: dataRequest.date_to,
                  }}
                  value={dataRequest.date_to}
                  handleInputDate={(date) =>
                    dispatch(
                      setRequest({
                        ...dataRequest,
                        tag: "original",
                        offer_id: null,
                        date_to: date,
                      })
                    )
                  }
                />
              </div>
            ) : (
              <span>
                Du {moment(dataRequest.date_from).format("L")} au&nbsp;
                {moment(dataRequest.date_to).format("L")}
              </span>
            )}
          </li>
          <li>
            <span className="payment__sumaryLabel">
              Invités
              {editTravellers ? (
                <i
                  className="cross-mark primary"
                  onClick={() => setEditTravellers(false)}
                ></i>
              ) : (
                <svg
                  onClick={() => setEditTravellers(true)}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                    stroke="#A94E4D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10.7003 2.64868L14.7409 6.68929"
                    stroke="#A94E4D"
                    strokeWidth="1.5"
                  />
                </svg>
              )}
            </span>
            {editTravellers ? (
              <input
                type="number"
                value={dataRequest.guests}
                className="payment__summaryInput"
                onChange={(e) =>
                  dispatch(
                    setRequest({
                      ...dataRequest,
                      tag: "original",
                      offer_id: null,
                      guests: e.target.valueAsNumber,
                    })
                  )
                }
              />
            ) : (
              <span>{dataRequest.guests} invités</span>
            )}
          </li>
          <li>
            <span className="payment__sumaryLabel">
              Destination
              <svg
                viewBox="0 0 18 18"
                fill="none"
                onClick={() => history.push("/destinations")}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10.7003 2.64868L14.7409 6.68929"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                />
              </svg>
            </span>

            <span>
              {!loadingDestination
                ? destination?.city && destination?.country
                  ? `${destination.city}, ${destination.country}`
                  : "N/A"
                : "Charger des données..."}
            </span>
          </li>
          <li>
            <span className="payment__sumaryLabel">
              Villa
              <svg
                viewBox="0 0 18 18"
                fill="none"
                onClick={() => history.push("/villas")}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10.7003 2.64868L14.7409 6.68929"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
            <span>
              {!loadingVilla
                ? villa?.title
                  ? `${villa.title}`
                  : "N/A"
                : "Charger des données..."}
            </span>
          </li>
        </ul>
      </div>
      <div className="payment__sumary decoration">
        <h4 className="payment__sumaryTitle">Les options</h4>
        <ul className="payment__sumaryData option">
          <li>
            <span className="payment__sumaryLabel">
              Activité(s) sélectionnée(s)
              <svg
                viewBox="0 0 18 18"
                fill="none"
                onClick={() => history.push("/activities")}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10.7003 2.64868L14.7409 6.68929"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
            <span
              onClick={() =>
                setActivityExpand((activityExpand) => !activityExpand)
              }
              className={`payment__sumaryActivity ${activityExpand && "show"} ${
                dataRequest?.activities?.length && "cursor-click"
              }`}
            >
              {dataRequest?.activities?.length} activité(s) sélectionnée(s)&nbsp;
              {dataRequest?.activities?.length ? (
                <svg
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 298.3L430.2 131.1C434.5 126.9 441.6 127 446 131.3L476.6 161.2C481 165.5 481.1 172.5 476.8 176.7L264.1 380.9C261.9 383.1 258.9 384.1 256 383.9C253 384 250.1 383 247.9 380.9L35.2 176.7C30.9 172.5 31 165.5 35.4 161.2L66 131.3C70.4 127 77.5 126.9 81.8 131.1L256 298.3Z"
                    fill="#a94e4d"
                  />
                </svg>
              ) : (
                ""
              )}
            </span>
            {dataRequest?.activities?.length && activityExpand ? (
              <>
                <ul>
                  {dataRequest.activities.map((act) => (
                    <li key={act.id}>
                      {act.title} - quantité: {act.qty}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </li>
          <li>
            <span className="payment__sumaryLabel">
              Coffret(s) sélectionné(s)
              <svg
                viewBox="0 0 18 18"
                fill="none"
                onClick={() => history.push("/coffrets")}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10.7003 2.64868L14.7409 6.68929"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
            <span
              onClick={() =>
                setCoffretExpand((coffretExpand) => !coffretExpand)
              }
              className={`payment__sumaryActivity ${coffretExpand && "show"} ${
                dataRequest?.bundles?.length && "cursor-click"
              }`}
            >
              {dataRequest?.bundles?.length} coffret(s) sélectionné(s)&nbsp;
              {dataRequest?.bundles?.length ? (
                <svg
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 298.3L430.2 131.1C434.5 126.9 441.6 127 446 131.3L476.6 161.2C481 165.5 481.1 172.5 476.8 176.7L264.1 380.9C261.9 383.1 258.9 384.1 256 383.9C253 384 250.1 383 247.9 380.9L35.2 176.7C30.9 172.5 31 165.5 35.4 161.2L66 131.3C70.4 127 77.5 126.9 81.8 131.1L256 298.3Z"
                    fill="#a94e4d"
                  />
                </svg>
              ) : (
                ""
              )}
            </span>
            {dataRequest?.bundles?.length && coffretExpand ? (
              <>
                <ul className="payment__sumaryDataList">
                  {dataRequest.bundles.map((bundle) => (
                    <li key={bundle.id}>
                      {bundle.name || bundle.title || "N/A"} - quantité:{" "}
                      {bundle.qty}
                      <br />
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </li>
          <li>
            <span className="payment__sumaryLabel">
              Goodies sélectionné(s)
              <svg
                viewBox="0 0 18 18"
                fill="none"
                onClick={() => history.push("/coffrets")}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10.7003 2.64868L14.7409 6.68929"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
            <span
              onClick={() =>
                setGoodiesExpand((goodiesExpand) => !goodiesExpand)
              }
              className={`payment__sumaryActivity ${goodiesExpand && "show"} ${
                dataRequest?.products?.length && "cursor-click"
              }`}
            >
              {dataRequest?.products?.length} goodies sélectionné(s)&nbsp;
              {dataRequest?.products?.length ? (
                <svg
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 298.3L430.2 131.1C434.5 126.9 441.6 127 446 131.3L476.6 161.2C481 165.5 481.1 172.5 476.8 176.7L264.1 380.9C261.9 383.1 258.9 384.1 256 383.9C253 384 250.1 383 247.9 380.9L35.2 176.7C30.9 172.5 31 165.5 35.4 161.2L66 131.3C70.4 127 77.5 126.9 81.8 131.1L256 298.3Z"
                    fill="#a94e4d"
                  />
                </svg>
              ) : (
                ""
              )}
            </span>
            {dataRequest?.products?.length && goodiesExpand ? (
              <>
                <ul className="payment__sumaryDataList">
                  {dataRequest.products.map((product) => (
                    <li key={product.id}>
                      {product.name || product.title || "N/A"} - quantité:{" "}
                      {product.qty}
                      <br />
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </li>
          <li>
            <span className="payment__sumaryLabel">
              Service décoration
              <svg
                viewBox="0 0 18 18"
                onClick={() => history.push("/decorations")}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10.7003 2.64868L14.7409 6.68929"
                  stroke="#A94E4D"
                  strokeWidth="1.5"
                />
              </svg>
            </span>

            <span>
              {dataRequest.decoration ? (
                dataRequest.decoration.type?.includes("Box") ? (
                  `${dataRequest.decoration.type || "N/A"} ${
                    dataRequest.decoration.details
                      ? `, ${dataRequest.decoration.details}`
                      : ""
                  }`
                ) : dataRequest.decoration.type?.includes("Service") ? (
                  <>
                    {dataRequest.decoration.type || "N/A"}
                    <br />
                    Budget : {dataRequest.decoration.budget || "0"}
                    <br />
                    Précisions : {dataRequest.decoration.details || "-"}
                    <br />
                    Décoration extérieure :&nbsp;
                    {dataRequest.decoration.exterior_decoration ? "Oui" : "Non"}
                    <br />
                    Nombre de pièces : {dataRequest.decoration.piece || "0"}
                  </>
                ) : (
                  dataRequest.decoration.type
                )
              ) : (
                "N/A"
              )}
            </span>
          </li>
        </ul>
      </div>
      <Delivery popup={false} dataRequest={dataRequest} />
    </>
  );
};

export default PaymentSummary;
