import { useSelector } from "react-redux";
import PaymentInformation from "../../components/PaymentInformation";
import PaymentSummary from "../../components/PaymentSummary";
import StepBar from "../../components/StepBar";
import PAYMENT_IMG from "../../assets/hero/payment_page.jpg";
import "./Payment.css";

const Payment = () => {
  const { dataRequest } = useSelector((state) => state.request);

  return (
    <>
      <div className="payment container">
        <div className="payment__header">
          <h1>Votre demande de devis</h1>
          <div>
            <svg
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10.5"
                cy="10.5001"
                r="8.70833"
                stroke="#14142B"
                strokeWidth="2"
              />
              <path
                d="M10.5 6.54175V11.2917L12.4792 13.2709"
                stroke="#14142B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="payment__response">
              Délai de réponse : <span>12 à 24 heures</span>
            </span>
          </div>
        </div>
        <section className="payment__container">
          <div className="payment__detail">
            <img
              src={PAYMENT_IMG}
              alt="payment_hero"
              className="payment__imgDestination"
            />
            <PaymentSummary dataRequest={dataRequest} />
          </div>
          <PaymentInformation dataRequest={dataRequest} />
        </section>
      </div>
      <StepBar
        completedStepOne
        completedStepTwo
        completedStepThree
        completedStepFour
        completedStepFive
        loading
      />
    </>
  );
};

export default Payment;
