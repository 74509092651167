import { useDispatch, useSelector } from "react-redux";
import { setRequest } from "../../../../actions/requestActions";
import { truncate } from "../../../../utils/truncate";
import imgGoodies from "../../../../assets/imgGoodies.png";
import "./GoodiesCard.css";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { COFFRET_SELECTED } from "../../../../constants/coffretConstants";
SwiperCore.use([Pagination, Navigation]);

const GoodiesCard = ({ goods, type }) => {
  const dispatch = useDispatch();
  const { dataRequest } = useSelector((state) => state.request);
  // const productData = dataRequest.products
  //   .find((product) => (type ? type.id === product.id : !product.packaging))
  //   ?.goodies.find((g) => g.id === goods.id);

  const productData = dataRequest.products.find(
    (product) => product.filterId === goods.title + goods.id
  );

  const handleGoods = () => {
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        products: [
          ...dataRequest.products,
          {
            ...goods,
            filterId: goods.title + goods.id,
            qty: 1,
            packaging: null,
          },
        ],
      })
    );

    // if (type) {
    //   const isExist = dataRequest.products.find((data) => type.id === data.id);
    //   dispatch(
    //     setRequest({
    //       ...dataRequest,
    //       products: isExist
    //         ? dataRequest.products.map((data) =>
    //             type.id === data.id
    //               ? {
    //                   ...data,

    //                   goodies: [...data.goodies, { ...goods, qty: 1 }],
    //                 }
    //               : data
    //           )
    //         : [
    //             ...dataRequest.products,
    //             {
    //               ...type,
    //               custom: true,
    //               qty: 1,
    //               goodies: [{ ...goods, qty: 1 }],
    //             },
    //           ],
    //     })
    //   );
    // } else {
    //   const isExist = dataRequest.products.find((data) => !data.packaging);
    //   dispatch(
    //     setRequest({
    //       ...dataRequest,
    //       products: isExist
    //         ? dataRequest.products.map((data) =>
    //             !data.packaging
    //               ? {
    //                   ...data,
    //                   goodies: [...data.goodies, { ...goods, qty: 1 }],
    //                 }
    //               : data
    //           )
    //         : [
    //             ...dataRequest.products,
    //             { packaging: null, qty: 1, goodies: [{ ...goods, qty: 1 }] },
    //           ],
    //     })
    //   );
    // }
  };

  const addCount = () => {
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        products: dataRequest.products.map((g) =>
          g.filterId === goods.title + goods.id ? { ...g, qty: g.qty + 1 } : g
        ),
      })
    );
    // dispatch(
    //   setRequest({
    //     ...dataRequest,
    //     products: dataRequest.products.map((data) =>
    //       type
    //         ? data.id === type.id
    //           ? {
    //               ...data,
    //               goodies: data.goodies.map((g) =>
    //                 g.id === productData.id ? { ...g, qty: g.qty + 1 } : g
    //               ),
    //             }
    //           : data
    //         : !data.packaging
    //         ? {
    //             ...data,
    //             goodies: data.goodies.map((g) =>
    //               g.id === productData.id ? { ...g, qty: g.qty + 1 } : g
    //             ),
    //           }
    //         : data
    //     ),
    //   })
    // );
  };

  const minusCount = () => {
    if (productData?.qty > 1) {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          products: dataRequest.products.map((g) =>
            g.filterId === goods.title + goods.id ? { ...g, qty: g.qty - 1 } : g
          ),
        })
      );
    } else {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          products: dataRequest.products.filter(
            (g) => g.filterId !== goods.title + goods.id
          ),
        })
      );
    }
    // if (productData?.qty > 1) {
    //   dispatch(
    //     setRequest({
    //       ...dataRequest,
    //       products: dataRequest.products.map((data) =>
    //         type
    //           ? data.id === type.id
    //             ? {
    //                 ...data,
    //                 goodies: data.goodies.map((g) =>
    //                   g.id === productData.id ? { ...g, qty: g.qty - 1 } : g
    //                 ),
    //               }
    //             : data
    //           : !data.packaging
    //           ? {
    //               ...data,
    //               goodies: data.goodies.map((g) =>
    //                 g.id === productData.id ? { ...g, qty: g.qty - 1 } : g
    //               ),
    //             }
    //           : data
    //       ),
    //     })
    //   );
    // } else {
    //   const gdies = dataRequest.products.find((data) =>
    //     type ? data.id === type.id : !data.packaging
    //   );
    //   if (gdies.goodies.length > 1) {
    //     dispatch(
    //       setRequest({
    //         ...dataRequest,
    //         products: dataRequest.products.map((data) =>
    //           type
    //             ? data.id === type.id
    //               ? {
    //                   ...data,
    //                   goodies: data.goodies.filter(
    //                     (g) => g.id !== productData.id
    //                   ),
    //                 }
    //               : data
    //             : !data.packaging
    //             ? {
    //                 ...data,
    //                 goodies: data.goodies.filter(
    //                   (g) => g.id !== productData.id
    //                 ),
    //               }
    //             : data
    //         ),
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       setRequest({
    //         ...dataRequest,
    //         products: dataRequest.products.filter((data) =>
    //           type ? data.id !== type.id : data.packaging
    //         ),
    //       })
    //     );
    //   }
    // }
  };

  const handleShowDetails = () => {
    dispatch({
      type: COFFRET_SELECTED,
      payload: goods,
    });
  };

  return (
    <div className="goodiesCard" key={goods.id}>
      <div className="goodiesCard__iconContainer">
        <Swiper spaceBetween={0} slidesPerView={1} navigation>
          {goods.images.length ? (
            goods.images.map((image) => (
              <SwiperSlide>
                <div className="goodiesCard__iconContainer">
                  <div className="loading-dot">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <img
                    src={image?.url || imgGoodies}
                    alt={image.name}
                    className="goodiesCard__icon"
                  />
                </div>
              </SwiperSlide>
            ))
          ) : (
            <>
              <SwiperSlide>
                <img
                  src={goods?.image?.url || imgGoodies}
                  alt="helicopter"
                  className="goodiesCard__icon"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={goods?.image?.url || imgGoodies}
                  alt="helicopter"
                  className="goodiesCard__icon"
                />
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </div>
      <div className="goodiesCard__details">
        <div className="goodiesCard__text">
          <h3 onClick={handleShowDetails}>{goods.title}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: truncate(goods.description, 30),
            }}
          />
          {goods.description.length > 30 ? (
            <span onClick={handleShowDetails}>voir plus</span>
          ) : (
            ""
          )}
          <h4 className="goodiesCard__textPrice">
            Tarif <br />
            <span>{goods?.price?.toFixed(2) || 0}€</span>
          </h4>
        </div>
        {productData && productData?.qty > 0 ? (
          <div className="filterBar__counter">
            <button className="btn-minus" onClick={minusCount}></button>
            <span>{productData?.qty}</span>
            <button className="btn-add" onClick={addCount}></button>
          </div>
        ) : (
          <button className="goodiesCard__btn btn" onClick={handleGoods}>
            <svg
              viewBox="0 0 41 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.365128 20.2169C0.365128 9.17122 9.31943 0.216919 20.3651 0.216919C31.4108 0.216919 40.3651 9.17122 40.3651 20.2169C40.3651 31.2626 31.4108 40.2169 20.3651 40.2169C9.31943 40.2169 0.365128 31.2626 0.365128 20.2169Z"
                fill="#A94E4D"
              />
              <path
                d="M20.365 14.2169V26.2169"
                stroke="#FCFCFC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.365 20.2169H26.365"
                stroke="#FCFCFC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default GoodiesCard;
