import { useEffect, useRef } from "react";
import { useState } from "react/cjs/react.development";
import ActivityCard from "../ActivityCard";
import "./ActivitiesSection.css";

const ActivitiesSection = ({ activity }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage, setPerActivitiesPerPage] = useState(6);
  const activitiesProgressBars = useRef(null);

  //   to make pagination
  const getAllActivities = (activities) => {
    // get current activitoes

    const indexOfLastActivity = currentPage * activitiesPerPage;
    const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
    const currentActivities = activities.slice(
      indexOfFirstActivity,
      indexOfLastActivity
    );
    return currentActivities.map((e, index) => (
      <ActivityCard activity={e} key={e + index} />
    ));
  };

  const nextActivitySection = () => {
    setCurrentPage((currentPage) => currentPage + 1);
    activitiesProgressBars.current.style.width = `${
      (((currentPage + 1) * activitiesPerPage) / activity.activities.length) *
      100
    }%`;
  };

  const prevActivitySection = () => {
    setCurrentPage((currentPage) => currentPage - 1);
    activitiesProgressBars.current.style.width = `${
      (((currentPage - 1) * activitiesPerPage) / activity.activities.length) *
      100
    }%`;
  };

  const moreActivity = () => {
    setPerActivitiesPerPage((activitiesPerPage) => activitiesPerPage + 6);
  };

  useEffect(() => {
    if (activitiesProgressBars.current) {
      activitiesProgressBars.current.style.width = `${
        ((currentPage * activitiesPerPage) / activity.activities.length) * 100
      }%`;
    }

    window.onresize = () => {
      if (window.innerWidth > 992) {
        setPerActivitiesPerPage(6);
      }
    };
  }, [currentPage, activitiesPerPage, activity]);
  return (
    <section className="activitiesSection">
      {currentPage > 1 && (
        <button
          className="activitiesSection__btnNav left btn"
          onClick={prevActivitySection}
        >
          <svg
            className="arrow"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2L8.96317 8.96317L2 15.9263"
              stroke="#FCFCFC"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
      <h2 className="activitiesSection__title">{activity.category}</h2>
      {activity.activities.length ? (
        <>
          <div className="activitiesSection__container">
            {getAllActivities(activity.activities)}
          </div>
          {Math.ceil(activity.activities.length / activitiesPerPage) !== 1 && (
            <div className="activitiesSection__seeMore" onClick={moreActivity}>
              Voir Plus
            </div>
          )}

          {Math.ceil(activity.activities.length / activitiesPerPage) !== 1 && (
            <div className="activitiesSection__pagination">
              <div className="activitiesSection__paginationProgressContainer">
                <span
                  ref={activitiesProgressBars}
                  className="activitiesSection__paginationProgressBar"
                ></span>
              </div>
            </div>
          )}
          {currentPage * activitiesPerPage !== activity.activities.length &&
            activity.activities.length > 6 && (
              <button
                className="activitiesSection__btnNav right btn"
                onClick={nextActivitySection}
              >
                <svg
                  className="arrow"
                  viewBox="0 0 11 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L8.96317 8.96317L2 15.9263"
                    stroke="#FCFCFC"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
        </>
      ) : (
        <div className="loading-container-text">
          Aucune activité disponible pour cette destination
        </div>
      )}
    </section>
  );
};

export default ActivitiesSection;
