import HomeActivities from "../../components/HomeComponents/HomeActivities";
import HomeExperience from "../../components/HomeComponents/HomeExperience";
import HomeHero from "../../components/HomeComponents/HomeHero";
import HomePartner from "../../components/HomeComponents/HomePartner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./Home.css";
import Country from "../../components/Country";

const Home = () => {
  const history = useHistory();
  return (
    <>
      <div className="home ">
        <HomeHero />
        <div>
          <section className="home__quizz">
            <div className="container">
              <h2>Découvrez notre quizz</h2>
              <p>
                Vous ne savez pas où partir ? <br /> Répondez à quelques
                questions et nous vous proposerons un séjour sur mesure
              </p>
              <button
                className="btn"
                onClick={() => {
                  window.scrollTo({ top: 0 });
                  history.push("/quizz");
                }}
              >
                Faire le quizz
                <svg
                  className="arrow"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.66666 1.33337L6.30877 5.97549L1.66666 10.6176"
                    stroke="#FCFCFC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </section>
          <HomePartner />
          <HomeExperience />
          <HomeActivities />
        </div>
      </div>
      <Country />
    </>
  );
};

export default Home;
