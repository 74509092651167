// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterPopupMoreCategory {
  margin-bottom: 2em;
}

.filterPopupMoreCategory__locations {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.filterPopupMoreCategory__location label{
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/FilterPopup/FilterPopupComponents/FilterPopupMoreCategory/FilterPopupMoreCategory.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,2DAA2D;AAC7D;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".filterPopupMoreCategory {\n  margin-bottom: 2em;\n}\n\n.filterPopupMoreCategory__locations {\n  display: grid;\n  gap: 1rem;\n  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));\n}\n\n.filterPopupMoreCategory__location label{\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
