// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeActivities {
  padding: 2em 0;
}

.homeActivities__header {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.homeActivities__header h2 {
  font-size: 1.5rem;
  color: var(--clr-gray800);
  /* white-space: nowrap; */
}

.homeActivities__container {
  display: grid;
  gap: 1.5rem;
  margin-top: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.homeActivities__headerBtn {
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.homeActivities__headerBtn span {
  display: none;
  margin-right: 0.5rem;
}

@media (min-width: 375px) {
  .homeActivities__container {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }

  .homeActivities__header h2 {
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) {
  .homeActivities__headerBtn {
    width: unset;
    padding: 1.25em 1.75em;
    height: unset;
    max-width: unset;
  }

  .homeActivities__headerBtn span {
    display: flex;
  }

  .homeActivities__header h2 {
    font-size: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/HomeComponents/HomeActivities/HomeActivities.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,2DAA2D;AAC7D;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE;IACE,2DAA2D;EAC7D;;EAEA;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".homeActivities {\n  padding: 2em 0;\n}\n\n.homeActivities__header {\n  width: 100%;\n  justify-content: space-between;\n  display: flex;\n  align-items: center;\n}\n\n.homeActivities__header h2 {\n  font-size: 1.5rem;\n  color: var(--clr-gray800);\n  /* white-space: nowrap; */\n}\n\n.homeActivities__container {\n  display: grid;\n  gap: 1.5rem;\n  margin-top: 1.5rem;\n  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));\n}\n\n.homeActivities__headerBtn {\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  max-width: 2.5rem;\n  height: 2.5rem;\n  padding: 0;\n}\n\n.homeActivities__headerBtn span {\n  display: none;\n  margin-right: 0.5rem;\n}\n\n@media (min-width: 375px) {\n  .homeActivities__container {\n    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));\n  }\n\n  .homeActivities__header h2 {\n    font-size: 1.8rem;\n  }\n}\n@media (min-width: 768px) {\n  .homeActivities__headerBtn {\n    width: unset;\n    padding: 1.25em 1.75em;\n    height: unset;\n    max-width: unset;\n  }\n\n  .homeActivities__headerBtn span {\n    display: flex;\n  }\n\n  .homeActivities__header h2 {\n    font-size: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
