// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homePartner {
  padding: 2em 0;
}

.homePartner h2 {
  font-size: 1.5rem;
  color: var(--clr-gray800);
  white-space: nowrap;
  text-align: center;
}

.homePartner__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 1.5rem;
  justify-content: center;
  align-items: center;
  /* justify-content: space-evenly; */
}

.homePartner__container img {
  margin: 0 1rem;
  /* max-height: 8rem; */
  width: 100%;
  height: 100%;
  max-width: 8rem;
  object-fit: contain;
}

.homePartner__container a {
  margin-top: 1rem;
}

@media (min-width: 375px) {
  .homePartner h2 {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) {
  .homePartner h2 {
    font-size: 2rem;
  }
  .homePartner {
    padding: 3em 0 3em 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CorporatePartner/CorporatePartner.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,SAAS;EACT,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,mCAAmC;AACrC;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".homePartner {\n  padding: 2em 0;\n}\n\n.homePartner h2 {\n  font-size: 1.5rem;\n  color: var(--clr-gray800);\n  white-space: nowrap;\n  text-align: center;\n}\n\n.homePartner__container {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 2rem;\n  margin-top: 1.5rem;\n  justify-content: center;\n  align-items: center;\n  /* justify-content: space-evenly; */\n}\n\n.homePartner__container img {\n  margin: 0 1rem;\n  /* max-height: 8rem; */\n  width: 100%;\n  height: 100%;\n  max-width: 8rem;\n  object-fit: contain;\n}\n\n.homePartner__container a {\n  margin-top: 1rem;\n}\n\n@media (min-width: 375px) {\n  .homePartner h2 {\n    font-size: 1.8rem;\n  }\n}\n\n@media (min-width: 768px) {\n  .homePartner h2 {\n    font-size: 2rem;\n  }\n  .homePartner {\n    padding: 3em 0 3em 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
