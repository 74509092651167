// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqQuestionBox {
  padding: 2em;
  margin-top: 2rem;
  border-radius: 1em;
  background: var(--clr-gray200);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.faqQuestionBox span {
  font-size: 1.25rem;
  font-weight: 600;
  display: block;
}

.faqQuestionBox p {
  margin-top: 2rem;
  padding-top: 2em;
  border-top: 1px solid var(--clr-gray500);
  line-height: 1.7;
}
`, "",{"version":3,"sources":["webpack://./src/components/FaqQuestionBox/FaqQuestionBox.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;EAC9B,2CAA2C;EAC3C,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,wCAAwC;EACxC,gBAAgB;AAClB","sourcesContent":[".faqQuestionBox {\n  padding: 2em;\n  margin-top: 2rem;\n  border-radius: 1em;\n  background: var(--clr-gray200);\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);\n  cursor: pointer;\n}\n\n.faqQuestionBox span {\n  font-size: 1.25rem;\n  font-weight: 600;\n  display: block;\n}\n\n.faqQuestionBox p {\n  margin-top: 2rem;\n  padding-top: 2em;\n  border-top: 1px solid var(--clr-gray500);\n  line-height: 1.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
