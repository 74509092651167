import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoffretGoodies,
  getCoffretPaniers,
  getCoffrets,
} from "../../actions/coffretActions";
import CoffretsCard from "../../components/CoffretComponents/CoffretsCard";
import Goodies from "../../components/CoffretComponents/Goodies";
import Hero from "../../components/Hero";
import StepBar from "../../components/StepBar";
import "./Coffrets.css";
import goodiesSkeleton from "../../utils/goodies.json";
import CardSkeleton from "../../components/Skeleton/CardSkeleton";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import ActivityDetails from "../../components/ActivitiesComponent/ActivityDetails";
import { COFFRET_SELECTED } from "../../constants/coffretConstants";
import CoffretGoodiesPopup from "../../components/CoffretComponents/CoffretGoodiesPopup";
import HERO from "../../assets/hero/coffret_page.jpeg";
SwiperCore.use([Pagination, Navigation]);

const Coffrets = () => {
  const dispatch = useDispatch();
  const mainContainerCoffret = useRef(null);
  const mainContainerCoffretBd = useRef(null);
  const mainContainerPanier = useRef(null);
  const mainContainerPanierBd = useRef(null);
  const navigationPrevPanierRef = useRef(null);
  const navigationNextPanierRef = useRef(null);
  const navigationPrevPanierBdRef = useRef(null);
  const navigationNextPanierBdRef = useRef(null);
  const navigationPrevCoffretRef = useRef(null);
  const navigationNextCoffretRef = useRef(null);
  const navigationPrevCoffretBdRef = useRef(null);
  const navigationNextCoffretBdRef = useRef(null);
  const orderSizePanier = ["S", "M", "L"];
  let defColl = new Intl.Collator("fi");

  const [isCustom, setIsCustom] = useState(false);
  const { selectedCoffret, selectedCoffretBundles } = useSelector(
    (state) => state.selectedCoffret
  );
  const [navShowCoffret, setNavShowCoffret] = useState({
    left: false,
    right: true,
  });
  const [navShowCoffretBd, setNavShowCoffretBd] = useState({
    left: false,
    right: true,
  });
  const [navShowPanier, setNavShowPanier] = useState({
    left: false,
    right: true,
  });
  const [navShowPanierBd, setNavShowPanierBd] = useState({
    left: false,
    right: true,
  });

  const { goodies, loading: loadingGoodies } = useSelector(
    (state) => state.coffretGoodies
  );
  const { coffrets, loading: loadingCoffret } = useSelector(
    (state) => state.coffret
  );
  const { paniers, loading: loadingPaniers } = useSelector(
    (state) => state.coffretPaniers
  );

  const handleCloseDetailsCoffret = () => {
    dispatch({
      type: COFFRET_SELECTED,
      payload: null,
    });
  };

  const handleSwiper = (swiper, leftArrow, rightArrow) => {
    setTimeout(() => {
      swiper.params.navigation.prevEl = leftArrow.current;
      swiper.params.navigation.nextEl = rightArrow.current;
      swiper.navigation.destroy();
      swiper.navigation.init();
      swiper.navigation.update();
    }, 0);
  };

  useEffect(() => {
    dispatch(getCoffretGoodies());
    dispatch(getCoffretPaniers());
    dispatch(getCoffrets());
  }, [dispatch]);

  return (
    <div className="coffrets">
      <Hero
        title="Sélectionnez vos accessoires"
        heroImg={HERO}
        isDestination={false}
      />

      <div className="coffrets__container container">
        <h1 className="coffrets__mainTitle">Bride to be</h1>
        <section
          className="coffrets__section max-left-side-coffret"
          ref={mainContainerCoffretBd}
        >
          <h2 className="coffrets__title">- Coffrets</h2>
          <div className="coffrets__contentContainerCoffret">
            <svg
              className={`${
                navShowCoffretBd.left && "show"
              } coffrets__chevron left`}
              viewBox="0 0 80 80"
              ref={navigationPrevCoffretBdRef}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M46.6667 63.3333L23.4562 40.1228L46.6667 16.9122"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
              }}
              navigation={{
                prevEl: navigationPrevCoffretBdRef.current
                  ? navigationPrevCoffretBdRef.current
                  : undefined,
                nextEl: navigationNextCoffretBdRef.current
                  ? navigationNextCoffretBdRef.current
                  : undefined,
              }}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex === 0)
                  setNavShowCoffretBd({ right: true, left: false });
                else setNavShowCoffretBd({ right: true, left: true });
                if (
                  swiper.activeIndex ===
                  Math.floor(
                    coffrets.filter((c) => c.bundle_type === "Bride to be")
                      .length / 2
                  )
                )
                  setNavShowCoffretBd({ right: false, left: true });
              }}
              onSwiper={(swiper) =>
                handleSwiper(
                  swiper,
                  navigationPrevCoffretBdRef,
                  navigationNextCoffretBdRef
                )
              }
            >
              {!coffrets.filter((c) => c.bundle_type === "Bride to be")
                .length && loadingCoffret
                ? goodiesSkeleton.map(
                    (_, idx) =>
                      idx < 6 && (
                        <SwiperSlide key={idx}>
                          <CardSkeleton />
                        </SwiperSlide>
                      )
                  )
                : coffrets
                    .filter((c) => c.bundle_type === "Bride to be")
                    .sort((a, b) =>
                      defColl.compare(
                        a.name?.toLowerCase().trim(),
                        b.name?.toLowerCase().trim()
                      )
                    )
                    .map((coffret, idx) => (
                      <SwiperSlide key={coffret.id}>
                        <CoffretsCard
                          coffret={coffret}
                          idx={idx}
                          isCustom={isCustom}
                          type="bundle"
                          setIsCustom={setIsCustom}
                        />
                      </SwiperSlide>
                    ))}
            </Swiper>
            <svg
              ref={navigationNextCoffretBdRef}
              className={`${
                window.innerWidth > 767
                  ? coffrets.filter((c) => c.bundle_type === "Bride to be")
                      .length > 2 &&
                    navShowCoffretBd.right &&
                    "show"
                  : navShowCoffretBd.right && "show"
              } coffrets__chevron right`}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.3333 16.6667L56.5439 39.8773L33.3333 63.0878"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </section>
        <section
          className="coffrets__section max-left-side-coffret"
          ref={mainContainerPanierBd}
        >
          <h2 className="coffrets__title">- Paniers</h2>
          <div className="coffrets__contentContainerCoffret">
            <svg
              className={`${
                navShowPanierBd.left && "show"
              } coffrets__chevron left`}
              viewBox="0 0 80 80"
              ref={navigationPrevPanierBdRef}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M46.6667 63.3333L23.4562 40.1228L46.6667 16.9122"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
              }}
              navigation={{
                prevEl: navigationPrevPanierBdRef.current
                  ? navigationPrevPanierBdRef.current
                  : undefined,
                nextEl: navigationNextPanierBdRef.current
                  ? navigationNextPanierBdRef.current
                  : undefined,
              }}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex === 0)
                  setNavShowPanierBd({ right: true, left: false });
                else setNavShowPanierBd({ right: true, left: true });
                if (
                  swiper.activeIndex ===
                  Math.floor(
                    paniers.filter((c) => c.bundle_type === "Bride to be")
                      .length / 2
                  )
                )
                  setNavShowPanierBd({ right: false, left: true });
              }}
              onSwiper={(swiper) =>
                handleSwiper(
                  swiper,
                  navigationPrevPanierBdRef,
                  navigationNextPanierBdRef
                )
              }
            >
              {!paniers.filter((c) => c.bundle_type === "Bride to be").length &&
              loadingPaniers
                ? goodiesSkeleton.map(
                    (_, idx) =>
                      idx < 6 && (
                        <SwiperSlide key={idx}>
                          <CardSkeleton />
                        </SwiperSlide>
                      )
                  )
                : paniers
                    .filter((c) => c.bundle_type === "Bride to be")
                    .sort(function (a, b) {
                      let aSize = a.name
                          .replaceAll(" ", "")
                          .substring(
                            a.name.replaceAll(" ", "").length - 1,
                            a.name.replaceAll(" ", "").length
                          ),
                        bSize = b.name
                          .replaceAll(" ", "")
                          .substring(
                            b.name.replaceAll(" ", "").length - 1,
                            b.name.replaceAll(" ", "").length
                          );
                      return (
                        orderSizePanier.indexOf(aSize) -
                        orderSizePanier.indexOf(bSize)
                      );
                    })
                    .map((coffret, idx) => (
                      <SwiperSlide key={coffret.id}>
                        <CoffretsCard
                          coffret={coffret}
                          idx={idx}
                          isCustom={isCustom}
                          type="bundle"
                          setIsCustom={setIsCustom}
                        />
                      </SwiperSlide>
                    ))}
            </Swiper>
            <svg
              ref={navigationNextPanierBdRef}
              className={`${
                window.innerWidth > 767
                  ? paniers.filter((c) => c.bundle_type === "Bride to be")
                      .length > 2 &&
                    navShowPanierBd.right &&
                    "show"
                  : navShowPanierBd.right && "show"
              } coffrets__chevron right`}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.3333 16.6667L56.5439 39.8773L33.3333 63.0878"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </section>
        <h1 className="coffrets__mainTitle">Bridesmaids</h1>
        <section
          className="coffrets__section max-left-side-coffret"
          ref={mainContainerCoffret}
        >
          <h2 className="coffrets__title">- Coffrets</h2>
          <div className="coffrets__contentContainerCoffret">
            <svg
              className={`${
                navShowCoffret.left && "show"
              } coffrets__chevron left`}
              viewBox="0 0 80 80"
              ref={navigationPrevCoffretRef}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M46.6667 63.3333L23.4562 40.1228L46.6667 16.9122"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
              }}
              navigation={{
                prevEl: navigationPrevCoffretRef.current
                  ? navigationPrevCoffretRef.current
                  : undefined,
                nextEl: navigationNextCoffretRef.current
                  ? navigationNextCoffretRef.current
                  : undefined,
              }}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex === 0)
                  setNavShowCoffret({ right: true, left: false });
                else setNavShowCoffret({ right: true, left: true });
                if (
                  swiper.activeIndex ===
                  Math.floor(
                    coffrets.filter((c) => c.bundle_type === "Bridesmaid")
                      .length / 2
                  )
                )
                  setNavShowCoffret({ right: false, left: true });
              }}
              onSwiper={(swiper) =>
                handleSwiper(
                  swiper,
                  navigationPrevCoffretRef,
                  navigationNextCoffretRef
                )
              }
            >
              {!coffrets.filter((c) => c.bundle_type === "Bridesmaid").length &&
              loadingCoffret
                ? goodiesSkeleton.map(
                    (_, idx) =>
                      idx < 6 && (
                        <SwiperSlide key={idx}>
                          <CardSkeleton />
                        </SwiperSlide>
                      )
                  )
                : coffrets
                    .filter((c) => c.bundle_type === "Bridesmaid")
                    .sort((a, b) =>
                      defColl.compare(
                        a.name?.toLowerCase().trim(),
                        b.name?.toLowerCase().trim()
                      )
                    )
                    .map((coffret, idx) => (
                      <SwiperSlide key={coffret.id}>
                        <CoffretsCard
                          coffret={coffret}
                          idx={idx}
                          isCustom={isCustom}
                          type="bundle"
                          setIsCustom={setIsCustom}
                        />
                      </SwiperSlide>
                    ))}
            </Swiper>
            <svg
              ref={navigationNextCoffretRef}
              className={`${
                window.innerWidth > 767
                  ? coffrets.filter((c) => c.bundle_type === "Bridesmaid")
                      .length > 2 &&
                    navShowCoffret.right &&
                    "show"
                  : navShowCoffret.right && "show"
              } coffrets__chevron right`}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.3333 16.6667L56.5439 39.8773L33.3333 63.0878"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </section>
        <section
          className="coffrets__section max-left-side-coffret"
          ref={mainContainerPanier}
        >
          <h2 className="coffrets__title">- Paniers</h2>
          <div className="coffrets__contentContainerCoffret">
            <svg
              className={`${
                navShowPanier.left && "show"
              } coffrets__chevron left`}
              viewBox="0 0 80 80"
              ref={navigationPrevPanierRef}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M46.6667 63.3333L23.4562 40.1228L46.6667 16.9122"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
              }}
              navigation={{
                prevEl: navigationPrevPanierRef.current
                  ? navigationPrevPanierRef.current
                  : undefined,
                nextEl: navigationNextPanierRef.current
                  ? navigationNextPanierRef.current
                  : undefined,
              }}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex === 0)
                  setNavShowPanier({ right: true, left: false });
                else setNavShowPanier({ right: true, left: true });
                if (
                  swiper.activeIndex ===
                  Math.floor(
                    paniers.filter((c) => c.bundle_type === "Bridesmaid")
                      .length / 2
                  )
                )
                  setNavShowPanier({ right: false, left: true });
              }}
              onSwiper={(swiper) =>
                handleSwiper(
                  swiper,
                  navigationPrevPanierRef,
                  navigationNextPanierRef
                )
              }
            >
              {!paniers.filter((c) => c.bundle_type === "Bridesmaid").length &&
              loadingPaniers
                ? goodiesSkeleton.map(
                    (_, idx) =>
                      idx < 6 && (
                        <SwiperSlide key={idx}>
                          <CardSkeleton />
                        </SwiperSlide>
                      )
                  )
                : paniers
                    .filter((c) => c.bundle_type === "Bridesmaid")
                    .sort(function (a, b) {
                      let aSize = a.name
                          .replaceAll(" ", "")
                          .substring(
                            a.name.replaceAll(" ", "").length - 1,
                            a.name.replaceAll(" ", "").length
                          ),
                        bSize = b.name
                          .replaceAll(" ", "")
                          .substring(
                            b.name.replaceAll(" ", "").length - 1,
                            b.name.replaceAll(" ", "").length
                          );
                      return (
                        orderSizePanier.indexOf(aSize) -
                        orderSizePanier.indexOf(bSize)
                      );
                    })
                    .map((coffret, idx) => (
                      <SwiperSlide key={coffret.id}>
                        <CoffretsCard
                          coffret={coffret}
                          idx={idx}
                          isCustom={isCustom}
                          type="bundle"
                          setIsCustom={setIsCustom}
                        />
                      </SwiperSlide>
                    ))}
            </Swiper>
            <svg
              ref={navigationNextPanierRef}
              className={`${
                window.innerWidth > 767
                  ? paniers.filter((c) => c.bundle_type === "Bride to be")
                      .length > 2 &&
                    navShowPanier.right &&
                    "show"
                  : navShowPanier.right && "show"
              } coffrets__chevron right`}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.3333 16.6667L56.5439 39.8773L33.3333 63.0878"
                stroke="#14142B"
                strokeWidth="6.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </section>

        <Goodies goodies={goodies} loading={loadingGoodies} />
      </div>
      <ActivityDetails
        title={selectedCoffret?.name || selectedCoffret?.title}
        id={
          selectedCoffret?.id +
          (selectedCoffret?.title || selectedCoffret?.name)
        }
        isIcon={false}
        isButton={false}
        isCoffret={true}
        closeDetails={handleCloseDetailsCoffret}
        description={selectedCoffret?.description}
        price={selectedCoffret?.global_price || selectedCoffret?.price}
        images={{
          multiple: true,
          images: selectedCoffret?.images.length ? selectedCoffret?.images : [],
        }}
      />
      <CoffretGoodiesPopup
        show={isCustom && selectedCoffretBundles}
        type={selectedCoffretBundles}
        closePopup={() => setIsCustom(false)}
      />
      <StepBar
        completedStepOne
        completedStepTwo
        completedStepThree
        completedStepFour
        loading
      />
    </div>
  );
};

export default Coffrets;
