import ActivitiesCard from "../../ActivitiesComponent/ActivityCard";
// import activities from "../../../utils/activities.json";
import { useHistory } from "react-router";
import "./HomeActivities.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActivities } from "../../../actions/activityActions";

const HomeActivities = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { activities, loading } = useSelector((state) => state.activity);

  const findLengthEachActivity = () => {
    let newActivities = activities[0];
    for (let i = 0; i < activities.length; i++) {
      if (activities[i].activities?.length > newActivities?.activities.length)
        newActivities = activities[i];
    }
    return newActivities;
  };


  useEffect(() => {
    dispatch(getActivities());
  }, [dispatch]);

  return (
    <section className="homeActivities container">
      <div className="homeActivities__header">
        <h2>Activités</h2>
        <button
          className="homeActivities__headerBtn btn"
          onClick={() => history.push("/activities")}
        >
          <span>En voir plus</span>
          <svg
            className="arrow"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66666 1.33337L6.30877 5.97549L1.66666 10.6176"
              stroke="#FCFCFC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className="homeActivities__container">
        {!loading
          ? findLengthEachActivity()?.activities.map(
              (activity, i) =>
                i < 6 && (
                  <ActivitiesCard key={activity.id} activity={activity} />
                )
            )
          : null}
      </div>
    </section>
  );
};

export default HomeActivities;
