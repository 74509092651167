import { useHistory } from "react-router";
import { truncate } from "../../utils/truncate";

import moment from "moment";
import "moment/locale/fr";

import "./BlogCard.css";

moment.locale("fr");

const BlogCard = ({ post, featured, layoutType = "row" }) => {
  const history = useHistory();

  // remove html tag for text highlight or text desc for display card
  const stripOutHTMLText = (html) => {
    let text = document.createElement("DIV");
    text.innerHTML = html;
    return truncate(
      text.textContent || text.innerText || "",
      layoutType === "row" ? 150 : 500
    );
  };

  return (
    <div
      className={`blogCard ${featured && "featured"} ${layoutType}`}
      onClick={() => history.push(`/blog/${post.id}`)}
    >
      <img src={post?.image?.url} alt={post?.title} className="blogCard__img" />
      <div className="blogCard__details">
        <span className="blogCard__created">
          {moment(post.created_at).format("LLL")}
        </span>
        <div>
          <h3 className="blogCard__title">{post?.title}</h3>
          <p className="blogCard__description">
            {post?.description ? stripOutHTMLText(post?.description) : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
