import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../components/Loading";
import { emailPatten, validateResetPassword } from "../../utils/validateForm";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const [token] = useState(
    window.location.search.split("reset_password_token=")[1]
  );
  const [isCreatePassword] = useState(
    window.location.search.split("create=")[1]
  );

  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = async (e) => {
    if (token) {
      const { valid, errors: errorsPassword } = validateResetPassword(values);

      if (!valid) {
        setErrors({ ...errors, ...errorsPassword });
        return;
      }

      setErrors({});

      setLoading(true);

      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/public/update_password`,
        {
          reset_password_token: token,
          password: values.password,
          password_confirmation: values.confirmPassword,
        }
      );

      if (res.data.error) {
        setErrors({
          ...errors,
          tokenError:
            "Le lien a expiré. Vous pouvez faire mot de passe oublié afin de recevoir un nouveau lien pour créer votre mot de passe (lien valide 24h)",
        });
      } else {
        setSuccess(
          "Réinitialiser le mot de passe avec succès, redirigera vers la page de connexion dans 3 secondes"
        );
        setTimeout(() => {
          history.push("/auth/login");
        }, 3000);
      }
      setLoading(false);
    } else {
      e.preventDefault();
      setErrors({});
      setSuccess("");
      if (!values.email.trim()) {
        setErrors({ ...errors, email: "L'e-mail ne peut pas être vide" });
        return;
      }
      if (!values.email.match(emailPatten)) {
        setErrors({
          ...errors,
          email: "L'e-mail doit être une adresse e-mail valide",
        });
        return;
      }
      setLoading(true);
      // for send new password
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/public/set_password?email=${values.email.toLowerCase()}`
      );

      if (res.data.error) {
        setErrors({
          ...errors,
          email: res.data.error,
        });
      } else {
        setSuccess(res.data.success);
      }
      setLoading(false);
    }
  };

  const handleChange = (e, maxLength) => {
    const value = e.target.value.slice(0, maxLength);
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleRemoveValues = (name) => {
    setValues({ ...values, [name]: "" });
  };

  return (
    <div className="forgotPassword container">
      <div className="forgotPassword__container">
        <div>
          <h2 className="forgotPassword__title">
            {isCreatePassword
              ? "Créer votre mot de passe"
              : "Reinitialisation de votre mot de passe"}
          </h2>
        </div>
        <div className="forgotPassword__connect">
          <h4>Vous avez déjà un compte ?</h4>
          <div className="forgotPassword__connectOptions">
            <button
              className="forgotPassword__connectBtn btn"
              onClick={() => history.push("/auth")}
            >
              Je ne suis pas encore inscrit
              <svg
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.56207 12.7139C7.16556 15.3174 11.3867 15.3174 13.9902 12.7139C16.5937 10.1104 16.5937 5.88933 13.9902 3.28583C11.3867 0.682337 7.16556 0.682337 4.56207 3.28583"
                  stroke="#a94e4d"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33337 6L10.1112 8L8.33337 10"
                  stroke="#a94e4d"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 8L1.66667 8"
                  stroke="#a94e4d"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>

        <span className="forgotPassword__or">OU</span>
        {success && (
          <span className="forgotPassword__alertSucces">{success}</span>
        )}
        {errors && errors.tokenError && (
          <span className="forgotPassword__alert">{errors.tokenError}</span>
        )}
        {!token ? (
          <form className="forgotPassword__input login" onSubmit={handleSubmit}>
            <div
              className={`forgotPassword__inputContainer ${
                errors.email && "error"
              }`}
            >
              <input
                type="text"
                placeholder="Adresse e-mail"
                value={values.email}
                onChange={(e) => handleChange(e, 40)}
                name="email"
                id="email"
              />
              <label htmlFor="email">Adresse e-mail</label>
              <svg
                className="forgotPassword__inputClose"
                viewBox="0 0 14 14"
                fill="none"
                onClick={() => handleRemoveValues("email")}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L13 13"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13L13 1.00002"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {errors.email && (
                <span className="error-text">{errors.email}</span>
              )}
            </div>
          </form>
        ) : (
          <form className="forgotPassword__input login" onSubmit={handleSubmit}>
            <div
              className={`forgotPassword__inputContainer ${
                errors.password && "error"
              }`}
            >
              <input
                type="password"
                placeholder="Mot de passe"
                onChange={(e) => handleChange(e, 40)}
                name="password"
                id="password"
                value={values.password}
              />
              <label htmlFor="password">Mot de passe</label>
              <svg
                className="forgotPassword__inputClose"
                viewBox="0 0 14 14"
                onClick={() => handleRemoveValues("password")}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L13 13"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13L13 1.00002"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {errors.password && (
                <span className="error-text">{errors.password}</span>
              )}
            </div>
            <div
              className={`forgotPassword__inputContainer ${
                errors.confirmPassword && "error"
              }`}
            >
              <input
                type="password"
                placeholder="Confirmez le mot de passe"
                onChange={(e) => handleChange(e, 40)}
                name="confirmPassword"
                id="confirmPassword"
                value={values.confirmPassword}
              />
              <label htmlFor="password">Confirmez le mot de passe</label>
              <svg
                className="forgotPassword__inputClose"
                viewBox="0 0 14 14"
                onClick={() => handleRemoveValues("confirmPassword")}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L13 13"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13L13 1.00002"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {errors.confirmPassword && (
                <span className="error-text">{errors.confirmPassword}</span>
              )}
            </div>
          </form>
        )}

        <button
          className="forgotPassword__btn btn"
          // disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? (
            <Loading />
          ) : !token ? (
            "Réinitialiser mon mot de passe"
          ) : (
            <>
              {isCreatePassword
                ? "Créer votre mot de passe"
                : "Changer mon mot de passe"}
              <svg
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 9L16.1667 12L13.5 15"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 12L1.5 12"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M9.5 5V2H23.5V22H9.5V19"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          )}
        </button>
        {!token && (
          <span className="forgotPassword__note">
            Veuillez renseigner votre adresse email afin de recevoir un lien de
            reinitialisation concernant votre mot de passe
          </span>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
