import React, { useEffect, useState } from "react";
import accessoirePersonnalise1 from "../../assets/grid/accessoire-personnalise1.jpg";
import accessoirePersonnalise2 from "../../assets/grid/accessoire_personnalise2.jpg";
import accessoirePersonnalise3 from "../../assets/grid/accessoire_personnalise3.jpg";
import accessoirePersonnalise4 from "../../assets/grid/accessoire_personnalise4.jpg";
import evenementInterne1 from "./../../assets/grid/evenement-interne1.jpg";
import evenementInterne2 from "./../../assets/grid/evenement-interne2.jpg";
import evenementInterne3 from "./../../assets/grid/evenement-interne3.jpg";
import evenementInterne4 from "./../../assets/grid/evenement-interne4.jpg";
import inauguration1 from "./../../assets/grid/inauguration1.jpeg";
import inauguration2 from "./../../assets/grid/inauguration2.jpeg";
import inauguration3 from "./../../assets/grid/inauguration3.jpeg";
import influence1 from "./../../assets/grid/influence1.jpg";
import influence2 from "./../../assets/grid/influence2.jpg";
import influence3 from "./../../assets/grid/influence3.jpg";
import influence4 from "./../../assets/grid/influence4.jpg";
import influence5 from "./../../assets/grid/influence5.jpg";
import lancementProduit1 from "./../../assets/grid/lancement-produit1.jpg";
import lancementProduit2 from "./../../assets/grid/lancement-produit2.jpg";
import lancementProduit3 from "./../../assets/grid/lancement-produit3.jpg";
import lancementProduit4 from "./../../assets/grid/lancement-produit4.jpg";
import lancementProduit5 from "./../../assets/grid/lancement-produit5.jpg";
import soireeLancement1 from "./../../assets/grid/soiree-lancement1.jpeg";
import soireeLancement2 from "./../../assets/grid/soiree-lancement2.jpg";
import soireeLancement3 from "./../../assets/grid/soiree-lancement3.jpg";
import soireeLancement4 from "./../../assets/grid/soiree-lancement4.jpg";

const EvenementGrid = () => {
  const datas = [
    {
      img: [
        accessoirePersonnalise1,
        accessoirePersonnalise2,
        accessoirePersonnalise3,
        accessoirePersonnalise4,
      ],
      label: "Accessoire personnalisé",
    },
    {
      img: [
        evenementInterne1,
        evenementInterne2,
        evenementInterne3,
        evenementInterne4,
      ],
      label: "Événement interne",
    },
    {
      img: [inauguration1, inauguration2, inauguration3],
      label: "Inauguration",
    },
    {
      img: [influence1, influence2, influence3, influence4, influence5],
      label: "Influence",
    },
    {
      img: [
        lancementProduit1,
        lancementProduit2,
        lancementProduit3,
        lancementProduit4,
        lancementProduit5,
      ],
      label: "Lancement de produits",
    },
    {
      img: [
        soireeLancement1,
        soireeLancement2,
        soireeLancement3,
        soireeLancement4,
      ],
      label: "Soirée de lancement",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % datas.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [datas.length]);

  return (
    <div className="grid grid-cols-1 grid-rows-6 md:grid-cols-2 gap-x-0 sm:gap-x-3 md:gap-x-5 xl:gap-x-6 lg:gap-x-8  gap-y-6 md:gap-y-24 lg:gap-y-20 md:grid-row-3 lg:grid-cols-3 lg:grid-rows-2 w-full mx-auto  px-2 md:px-10  py-16">
      {datas.map((data, index) => (
        <div
          className="mx-auto size-[24rem] sm:size-[24rem] md:size-[23rem] lg:size-[21rem] xl:size-[27rem] group  relative flex items-center justify-center"
          key={index}
        >
          <img
            src={data.img[currentIndex % data.img.length]}
            alt={data.label}
            className="h-full w-full object-cover relative"
          />
          <div className="group-hover:opacity-0 transition-opacity duration-200 absolute text-center bg-white bg-opacity-75 w-[90%] h-[90%]  z-20 flex items-center justify-center font-bold text-primary text-2xl">
            {data.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EvenementGrid;
