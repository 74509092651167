import "./HomePartner.css";

const HomePartner = () => {
  return (
    <section className="homePartner container">
      <h2>Ils parlent de nous</h2>
      <div className="homePartner__container">
        <a
          href="https://amp-madame.lefigaro.fr/business/maiden-agency-donner-vie-a-des-evjf-memorables-uniques-et-haut-de-gamme-20220224"
          rel="noreferrer"
          target="_blank"
        >
          <img src="/images/logo/partner1.png" alt="" />
        </a>
        <a
          href="https://www.moncarnet-gala.fr/articles/view/MAIDEN-AGENCY"
          rel="noreferrer"
          target="_blank"
        >
          <img src="/images/logo/partner2.png" alt="" />
        </a>
        <a
          href="https://www.grazia.fr/lifestyle/insolite/evjf-les-meilleures-activites-pour-une-celebration-au-sommet-76799.html/amp"
          rel="noreferrer"
          target="_blank"
        >
          <img src="/images/logo/partner3.webp" alt="" />
        </a>
          <img src="/images/logo/partner4.png" alt="" />
      </div>
    </section>
  );
};

export default HomePartner;
