export const DESTINATIONS_REQUEST = "DESTINATIONS_REQUEST";
export const DESTINATIONS_SUCCESS = "DESTINATIONS_SUCCESS";
export const DESTINATIONS_FAIL = "DESTINATIONS_FAIL";

export const DESTINATION_REQUEST = "DESTINATION_REQUEST"
export const DESTINATION_SUCCESS = "DESTINATION_SUCCESS";
export const DESTINATION_FAIL = "DESTINATION_FAIL";

export const DESTINATION_SEARCH_REQUEST = "DESTINATION_SEARCH_REQUEST"
export const DESTINATION_SEARCH_SUCCESS = "DESTINATION_SEARCH_SUCCESS";
export const DESTINATION_SEARCH_FAIL = "DESTINATION_SEARCH_FAIL";

export const DESTINATION_SUGESSTION_REQUEST = "DESTINATION_SUGESSTION_REQUEST";
export const DESTINATION_SUGESSTION_SUCCESS = "DESTINATION_SUGESSTION_SUCCESS";
export const DESTINATION_SUGESSTION_FAIL = "DESTINATION_SUGESSTION_FAIL";