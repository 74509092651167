// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq {
  padding-bottom: 3em;
}

.faq__topSection {
  display: grid;
  gap: 1rem;
  margin-top: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
}

.faq__topBox {
  background: #eff0f7;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);
  padding: 1.75em 2em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--clr-gray800);
}

.faq__topBox h3 {
  /* font-weight: 600; */
  text-align: center;
  margin: 0.25rem 0;
}

.faq__topBox span {
  line-height: 1.7;
  font-size: 0.9rem;
  text-align: center;
}

.faq__questions {
  margin-top: 1rem;
}

.faq button {
  margin: 3rem auto 0 auto;
  border-radius: 1em;
  padding: 1.25em 1.75em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Faq/Faq.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,4DAA4D;AAC9D;;AAEA;EACE,mBAAmB;EACnB,6CAA6C;EAC7C,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".faq {\n  padding-bottom: 3em;\n}\n\n.faq__topSection {\n  display: grid;\n  gap: 1rem;\n  margin-top: 3rem;\n  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));\n}\n\n.faq__topBox {\n  background: #eff0f7;\n  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);\n  padding: 1.75em 2em;\n  border-radius: 0.75em;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: fit-content;\n  color: var(--clr-gray800);\n}\n\n.faq__topBox h3 {\n  /* font-weight: 600; */\n  text-align: center;\n  margin: 0.25rem 0;\n}\n\n.faq__topBox span {\n  line-height: 1.7;\n  font-size: 0.9rem;\n  text-align: center;\n}\n\n.faq__questions {\n  margin-top: 1rem;\n}\n\n.faq button {\n  margin: 3rem auto 0 auto;\n  border-radius: 1em;\n  padding: 1.25em 1.75em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
