import { useParams } from "react-router";
import Country from "../../components/Country";
import DestinationsHero from "../../components/DestinationsHero";
import "./Destinations.css";
import HERO from "../../assets/hero/destination_page.jpg"
import "../../components/Footer/Footer.css";



const Destinations = () => {
  let { query } = useParams();

  return (
    <>
      <div className="destinations">
      <span className="footer__label">Destinations</span>
        <DestinationsHero
          query={query}
          image={HERO}
        />
      </div>
      <Country query={query} />
    </>
  );
};

export default Destinations;
