import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setRequest } from "../../actions/requestActions";
import "./DatesPopup.css";
// import "moment/min/locales";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

const DatesPopup = ({ show, dateOne, dateTwo }) => {
  const dispatch = useDispatch();
  const { dataRequest } = useSelector((state) => state.request);

  return (
    <div className={`datesPopup ${show && "show"}`}>
      <div>
        <DatePicker
          ref={dateOne}
          locale="fr"
          minDate={moment(dataRequest.date_from).toDate()}
          open={true}
          dateFormat="dd/MM/yyyy"
          selected={moment(dataRequest.date_from).toDate()}
          onChange={(date) => {
            if (date > dataRequest.date_to) {
              dispatch(
                setRequest({
                  ...dataRequest,
                  tag: "original",
                  offer_id: null,
                  date_from: date.setDate(date.getDate()),
                  date_to: date.setDate(date.getDate() + 1),
                })
              );
            } else {
              dispatch(
                setRequest({
                  ...dataRequest,
                  tag: "original",
                  offer_id: null,
                  date_from: date,
                })
              );
            }
          }}
        />
      </div>
      <div>
        <DatePicker
          minDate={moment(dataRequest.date_to).toDate()}
          dateFormat="dd/MM/yyyy"
          ref={dateTwo}
          open={true}
          locale="fr"
          selected={moment(dataRequest.date_to).toDate()}
          onChange={(date) =>
            dispatch(
              setRequest({
                ...dataRequest,
                tag: "original",
                offer_id: null,
                date_to: date,
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default DatesPopup;
