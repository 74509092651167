import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./CoffretsCard.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { truncate } from "../../../utils/truncate";
import {
  COFFRET_SELECTED,
  // COFFRET_SELECTED_BUNDLES,
} from "../../../constants/coffretConstants";
import { useDispatch, useSelector } from "react-redux";
// import { setRequest } from "../../../actions/requestActions";
import { setRequest } from "../../../actions/requestActions";
import { currency } from "../../../utils/format";
SwiperCore.use([Pagination, Navigation]);

const CoffretsCard = ({ coffret, setIsCustom, type }) => {
  const dispatch = useDispatch();
  const { dataRequest } = useSelector((state) => state.request);
  const coffretData =
    type === "bundle"
      ? dataRequest?.bundles?.find(
          (bundle) =>
            (bundle.filterId || (bundle.name || bundle.title) + bundle.id) ===
            (coffret.name || coffret.title) + coffret.id
        )
      : dataRequest?.products?.find(
          (product) =>
            (product.filterId ||
              (product.title || product.name) + product.id) ===
            (coffret.name || coffret.title) + coffret.id
        );

  const addCount = () => {
    if (type === "product") {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          products: coffretData
            ? dataRequest.products.map((g) =>
                (g.filterId || (g.name || g.title) + g.id) ===
                (coffret.name || coffret.title) + coffret.id
                  ? { ...g, qty: g.qty + 1 }
                  : g
              )
            : [
                ...dataRequest.products,
                {
                  ...coffret,
                  filterId: (coffret.name || coffret.title) + coffret.id,
                  qty: 1,
                },
              ],
        })
      );
    } else {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          bundles: coffretData
            ? dataRequest.bundles
              ? dataRequest.bundles.map((g) =>
                  (g.filterId || (g.name || g.title) + g.id) ===
                  (coffret.name || coffret.title) + coffret.id
                    ? { ...g, qty: g.qty + 1 }
                    : g
                )
              : [
                  {
                    ...coffret,
                    filterId: (coffret.name || coffret.title) + coffret.id,
                    qty: 1,
                  },
                ]
            : [
                ...dataRequest.bundles,
                {
                  ...coffret,
                  filterId: (coffret.name || coffret.title) + coffret.id,
                  qty: 1,
                },
              ],
        })
      );
    }
  };

  const minusCount = () => {
    if (coffretData?.qty > 1) {
      if (type === "product") {
        dispatch(
          setRequest({
            ...dataRequest,
            tag: "original",
            offer_id: null,
            products: dataRequest.products.map((g) =>
              (g.filterId || (g.name || g.title) + g.id) ===
              (coffret.name || coffret.title) + coffret.id
                ? { ...g, qty: g.qty - 1 }
                : g
            ),
          })
        );
      } else {
        dispatch(
          setRequest({
            ...dataRequest,
            tag: "original",
            offer_id: null,
            bundles: dataRequest.bundles.map((g) =>
              (g.filterId || (g.name || g.title) + g.id) ===
              (coffret.name || coffret.title) + coffret.id
                ? { ...g, qty: g.qty - 1 }
                : g
            ),
          })
        );
      }
    } else {
      if (type === "product") {
        dispatch(
          setRequest({
            ...dataRequest,
            tag: "original",
            offer_id: null,
            products: dataRequest.products.filter(
              (g) =>
                (g.filterId || (g.name || g.title) + g.id) !==
                (coffret.name || coffret.title) + coffret.id
            ),
          })
        );
      } else {
        dispatch(
          setRequest({
            ...dataRequest,
            tag: "original",
            offer_id: null,
            bundles: dataRequest.bundles.filter(
              (g) =>
                (g.filterId || (g.name || g.title) + g.id) !==
                (coffret.name || coffret.title) + coffret.id
            ),
          })
        );
      }
    }
  };

  const handleShowDetails = () => {
    dispatch({
      type: COFFRET_SELECTED,
      payload: coffret,
    });
  };

  return (
    <div className="coffretsCard">
      <div className="coffretsCard__content">
        <div className="coffretsCard__imgContainer">
          <Swiper spaceBetween={0} slidesPerView={1} navigation>
            {coffret.images.length ? (
              coffret.images.map((image) => (
                <SwiperSlide>
                  <img
                    src={image.url || "/images/coffrets/image-box1.png"}
                    alt={image.name}
                  />
                </SwiperSlide>
              ))
            ) : (
              <>
                <SwiperSlide>
                  <img
                    src={
                      coffret?.image?.url || "/images/coffrets/image-box1.png"
                    }
                    alt="coffrets"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={
                      coffret?.image?.url || "/images/coffrets/image-box1.png"
                    }
                    alt="coffrets"
                  />
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </div>
        <div className="coffretsCard__textBody">
          <h3 className="coffretsCard__title">
            {coffret.name || coffret.title}
          </h3>
          <p
            className="coffretsCard__description"
            dangerouslySetInnerHTML={{
              __html: truncate(coffret.description, 250),
            }}
          />
          {coffret.description.length > 250 ? (
            <span onClick={handleShowDetails} className="btn">
              voir plus
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="coffretsCard__bottom">
        <div className="coffretsCard__more">
          {coffret.packaging && (
            <div className="coffretsCard__optionsContainer">
              <span>
                Options&nbsp;
                {/* {coffretData ? (
                <span className="coffretsCard__optionSelected">
                  ({coffretData?.goodies.reduce((a, b) => a + b.qty, 0)}&nbsp;
                  goodies sélectionnés)
                </span>
              ) : null} */}
              </span>
              <ul className="coffretsCard__options">
                {/* <li className="coffretsCard__option">Été</li>
            <li className="coffretsCard__option">Hiver</li> */}
                <li
                  className="coffretsCard__option"
                  // onClick={() => {
                  //   dispatch({
                  //     type: COFFRET_SELECTED_BUNDLES,
                  //     payload: coffret,
                  //   });
                  //   setIsCustom(true);
                  // }}
                >
                  <a
                    href="#goodies-section"
                    className="coffretsCard__optionAnchor"
                  >
                    Sur-mesure
                  </a>
                </li>
              </ul>
            </div>
          )}

          <span className="coffretsCard__textPrice">
            Tarif <br />
            <span>
              {coffret?.global_price
                ? currency(coffret?.global_price?.toFixed(0))
                : currency(coffret?.price?.toFixed(0)) || 0}
              €
            </span>
          </span>
          <div className="coffrets__counterContainer">
            <span>Quantité</span>
            <div className="coffrets__counter">
              <button
                className="btn-minus"
                onClick={() => minusCount()}
              ></button>
              <span>{coffretData?.qty || 0}</span>
              <button className="btn-add" onClick={() => addCount()}></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoffretsCard;
