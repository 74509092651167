export const testimonials = [
  {
    id: "1",
    name: "Anaïs",
    text: `Notre amie Raphaële a vécu un séjour inoubliable elle était plus que ravie, et je tenais à remercier toute l'équipe Maiden Agency 
    Merci pour cet EVJF !`,
    // expertise: "Surf Instructor",
    image: "/images/home/testimonial_N1.jpeg"
  },
  {
    id: "2",
    name: "Lesia",
    text: `Je souhaiterais encore remercier Louise de Maiden Agency pour ce superbe voyage ! c'était vraiment parfait. Bravo et à bientôt !`,
    // expertise: "Surf Instructor",
    image: "/images/home/testimonial_N2.jpeg"
  },
  {
    id: "3",
    name: "Mathilde",
    text: `Merci pour cet EVJF de folie, vraiment c'était incroyable ! Merci pour vos conseils et votre disponibilité ! Vous étiez un vrai soutien pour organiser ce week-end à distance ! Les maillots de bain ont fait leur effet ! 
    Merci beaucoup`,
    // expertise: "Surf Instructor",
    image: "/images/home/testimonial_N3.jpeg"
  },
  {
    id: "4",
    name: "Iris",
    text: `Je souhaiterais vous remercier pour l'organisation des différents évènements! Ma soeur la mariée et la team bride etaient ravies !
    La journée bateau s'est extrêmement bien passée!`,
    // expertise: "Surf Instructor",
    image: "/images/home/testimonial_4.jpeg"
  },
];
