import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { destinationSearch } from "../../actions/destinationActions";
// import { setRequest } from "../../actions/requestActions";
// import { filterVilla } from "../../actions/villaActions";
import SearchBar from "../SearchBar";
import "./DestinationsHero.css";

const DestinationsHero = ({ query, image }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [destination, setDestination] = useState("");
  const { dataRequest } = useSelector((state) => state.request);
  const [isSuggestions, setIsSuggestions] = useState(false);
  // const { filters } = useSelector((state) => state.villaFilter);
  const [values, setValues] = useState({
    where: "",
    when: "",
    until: "",
    addGuest: "",
  });

  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/destinations/${values.where}`);
    dispatch(destinationSearch(values.where));
    setDestination(values.where);
  };

  const handleInputValue = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.name === "budget"
          ? e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
          : e.target.value,
    });

    setIsSuggestions(false);
    if (e.target.name === "where") {
      setIsSuggestions(true);
    }
  };

  const handleRemoveValue = (type) => {
    history.push(`/destinations`);
    setValues({
      ...values,
      [type]: "",
    });
  };

  useEffect(() => {
    if (query) {
      setValues((values) => ({ ...values, where: query }));
    }

    document.onclick = (e) => {
      if (e.target.closest(`where-${Date.now()}`) === null) {
        setIsSuggestions(false);
      }
    };
  }, [query]);

  return (
    <section className="destinationsHero">
      <div className="destinationsHero__imgContainer">
        <img src={image} alt="" />
      </div>
      <div className="destinationsHero__content">
        <h1 className="destinationsHero__title">
          Sélectionnez votre destination <br />
          {/* Résultats de recherche <br />&nbsp; */}
          <strong>{destination || query || "Destinations"}</strong>
        </h1>
        <SearchBar
          values={values}
          handleInputValue={handleInputValue}
          handleRemoveValue={handleRemoveValue}
          handleSearch={handleSearch}
          dataRequest={dataRequest}
          isSuggestions={isSuggestions}
        />
      </div>
    </section>
  );
};

export default DestinationsHero;
