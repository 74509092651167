import imgOne from "../assets/decorations/img1.jpeg";
import imgTwo from "../assets/decorations/img2.jpeg";
import imgThree from "../assets/decorations/img3.jpeg";
import imgFour from "../assets/decorations/img4.jpeg";
import imgFive from "../assets/decorations/img5.jpeg";
import imgSix from "../assets/decorations/img6.jpeg";

export const images = [
  {
    id: 1,
    img: imgTwo,
  },
  {
    id: 2,
    img: imgOne,
  },
  {
    id: 3,
    img: imgThree,
  },
  {
    id: 4,
    img: imgFour,
  },
  {
    id: 5,
    img: imgFive,
  },
  {
    id: 6,
    img: imgSix,
  },
];
