// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterPopupServices {
  padding: 2em 0;
}

.filterPopupServices__services {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.filterPopupServices__service {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.filterPopupServices__serviceNameContainer {
  display: flex;
  align-items: center;
}

.filterPopupServices__serviceName {
  margin-left: 0.5rem;
}

.filterPopupServices__serviceIcon {
  /* width: 100%; */
  width: 3rem;
  object-fit: contain;
  /* max-width: 2rem; */
}

.filterPopupServices__counterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 20%;
  width: 100%;
  max-width: 8rem;
}

.filterPopupServices__counterContainer > span {
  margin-bottom: 0.5rem;
}

.filterPopupServices__counter {
  display: flex;
  width: 100%;
  max-width: 7rem;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
}

.filterPopupServices__counter span {
  font-weight: bolder;
}
`, "",{"version":3,"sources":["webpack://./src/components/FilterPopup/FilterPopupComponents/FilterPopupServices/FilterPopupServices.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,2DAA2D;AAC7D;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,eAAe;EACf,WAAW;EACX,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".filterPopupServices {\n  padding: 2em 0;\n}\n\n.filterPopupServices__services {\n  display: grid;\n  gap: 1rem;\n  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));\n}\n\n.filterPopupServices__service {\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  display: flex;\n}\n\n.filterPopupServices__serviceNameContainer {\n  display: flex;\n  align-items: center;\n}\n\n.filterPopupServices__serviceName {\n  margin-left: 0.5rem;\n}\n\n.filterPopupServices__serviceIcon {\n  /* width: 100%; */\n  width: 3rem;\n  object-fit: contain;\n  /* max-width: 2rem; */\n}\n\n.filterPopupServices__counterContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1 1 20%;\n  width: 100%;\n  max-width: 8rem;\n}\n\n.filterPopupServices__counterContainer > span {\n  margin-bottom: 0.5rem;\n}\n\n.filterPopupServices__counter {\n  display: flex;\n  width: 100%;\n  max-width: 7rem;\n  gap: 0.5rem;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.filterPopupServices__counter span {\n  font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
