import "./PrivateEventSection.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect, useState } from "react";
const PrivateEventSection = ({
  title,
  subtitle,
  content,
  linkLabel,
  linkHref,
  imagesCarousel,
  reverse = false,
  bgWhite = false,
  anchor,
  imgText,
  centerImage = false,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % totalSlides);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const totalSlides = imgText.length;
  return (
    <div
      id={anchor}
      className={`px-4 items-center ${
        centerImage ? "md:items-center" : "md:items-start"
      } md:px-[10%] md:gap-y-0 gap-y-6 py-16 flex flex-col justify-between gap-x-4 ${
        reverse ? "md:flex-row-reverse" : "md:flex-row"
      } ${bgWhite ? "bg-white" : "bg-brownBg"}`}
    >
      <div className="flex md:max-w-[60%] flex-col gap-y-3 px-6">
        <h2 className="text-4xl text-primary">{title}</h2>
        <h3 className="text-2xl font-semibold hidden">{subtitle}</h3>
        <p className="font-light text-justify">{content}</p>
        <a
          href={linkHref}
          className="bg-primary mt-6 md:mx-0 mx-auto rounded-full w-fit text-white px-6 py-4 hover:bg-primary/80 transition-colors"
        >
          {linkLabel}
        </a>
      </div>
      <div className="h-full w-full md:w-[40%] ">
        <Carousel selectedItem={slideIndex} onChange={setSlideIndex}>
          {imagesCarousel.map((image, index) => (
            <div key={index} className="relative w-full h-[30rem] group">
              <img
                src={image}
                className="object-cover absolute left-0 top-0 z-20 w-full h-full rounded-2xl"
                alt={`Slide-${index}`}
              />

              <div className="absolute inset-0 flex items-center z-30 justify-center">
                <div className="bg-white bg-opacity-75  w-[310px] md:w-[371px] py-44 rounded-lg text-center group-hover:bg-opacity-0 transition-opacity duration-600 ease-in-out">
                  <p className="text-primary font-semibold text-2xl">
                    {imgText}
                  </p>
                </div>
                <div className="absolute inset-0 flex items-center z-30 justify-center group-hover:opacity-0 transition-opacity duration-600 ease-in-out"></div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default PrivateEventSection;
