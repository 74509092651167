import "./HeroSkeleton.css";

const HeroSkeleton = ({ heroImg }) => {
  return (
    <div className={`heroSkeleton ${!heroImg && "no-img"}`}>
      <div className="heroSkeleton__imgContainer">
        <img src={heroImg} alt="activities hero" />
      </div>
      <div className="heroSkeleton__content">
        <div>
          <div className="heroSkeleton__location ">
            <div className="skeleton-card"></div>,{" "}
            <div className="skeleton-card"></div>
          </div>{" "}
          <br />
          <div className="heroSkeleton__text skeleton-card"></div>
        </div>
        <div className="heroSkeleton__date skeleton-card"></div>
      </div>
    </div>
  );
};

export default HeroSkeleton;
