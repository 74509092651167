export const SET_REQUEST = "SET_REQUEST"

export const MAKE_REQUEST_REQUEST = "MAKE_REQUEST_REQUEST"
export const MAKE_REQUEST_SUCCESS = "MAKE_REQUEST_SUCCESS"
export const MAKE_REQUEST_FAIL = "MAKE_REQUEST_FAIL"
export const MAKE_REQUEST_DONE = "MAKE_REQUEST_DONE"


export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"