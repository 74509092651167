import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessageRoom } from "../../../actions/messageActions";
import { MESSAGE_ROOM_RESET } from "../../../constants/messageConstants";
import MessageAllMessage from "../MessageAllMessage";
import MessageConversation from "../MessageConversation";
import MessageProfile from "../MessageProfile";
import "./MessageSection.css";

const MessageSection = () => {
  const [mobileVer, setMobileVer] = useState(false);
  const { room } = useSelector((state) => state.room);
  const [allImages, setAllImages] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [textCity, setTextCity] = useState("");
  // const [activeChat, setActiveChat] = useState(null)
  const dispatch = useDispatch();

  // maybe do this later to display user message from backend
  const handleOpenChat = (id) => {
    dispatch(getMessageRoom(id));
  };

  useEffect(() => {
    window.onresize = () => {
      if (window.innerWidth < 992) {
        setMobileVer(true);
      }
    };

    if (window.innerWidth < 992) {
      setMobileVer(true);
    }
  }, []);
  return (
    <div className="messageSection">
      <h2 className="messageSection__title">Messages</h2>
      {Object.keys(room).length ? (
        <span
          onClick={() => dispatch({ type: MESSAGE_ROOM_RESET })}
          className="btn messageSection__backBtn"
        >
          Retour
        </span>
      ) : null}
      <div className="messageSection__container">
        {mobileVer ? (
          Object.keys(room).length ? (
            <>
              <MessageConversation
                setAllImages={setAllImages}
                setAllFiles={setAllFiles}
                textCity={textCity}
                setTextCity={setTextCity}
              />
              <MessageProfile
                images={allImages}
                files={allFiles}
                textCity={textCity}
              />
            </>
          ) : (
            <MessageAllMessage openChat={handleOpenChat} />
          )
        ) : (
          <>
            <MessageAllMessage openChat={handleOpenChat} />
            <MessageConversation
              setAllImages={setAllImages}
              setAllFiles={setAllFiles}
              textCity={textCity}
              setTextCity={setTextCity}
            />
            <MessageProfile
              images={allImages}
              files={allFiles}
              textCity={textCity}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MessageSection;
