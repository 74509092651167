// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  height: -moz-fit-content;
  height: fit-content;
}

.mesaage__heroText h1 {
  font-size: 2.5rem;
  margin-bottom: 0.25rem;
}

.mesaage__heroText span {
  color: var(--clr-primary);
}

.message__hero {
  padding: 1em 0;
}

.message__container {
  margin-block: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  /* gap: 1rem; */
  /* height: 50rem; */
}

@media (min-width: 992px) {
  .message__hero {
    background: var(--clr-primary);
    padding: 3em 0;
  }

  .mesaage__heroText {
    color: var(--clr-light);
  }

  .mesaage__heroText h1 {
    color: var(--clr-light);
  }

  .mesaage__heroText span {
    color: var(--clr-light);
  }
}

@media (min-width: 1920px) {
  .message__container {
    flex-direction: row;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Message/Message.css"],"names":[],"mappings":"AAAA;EACE,wBAAmB;EAAnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE;IACE,8BAA8B;IAC9B,cAAc;EAChB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".message {\n  height: fit-content;\n}\n\n.mesaage__heroText h1 {\n  font-size: 2.5rem;\n  margin-bottom: 0.25rem;\n}\n\n.mesaage__heroText span {\n  color: var(--clr-primary);\n}\n\n.message__hero {\n  padding: 1em 0;\n}\n\n.message__container {\n  margin-block: 2rem;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  /* gap: 1rem; */\n  /* height: 50rem; */\n}\n\n@media (min-width: 992px) {\n  .message__hero {\n    background: var(--clr-primary);\n    padding: 3em 0;\n  }\n\n  .mesaage__heroText {\n    color: var(--clr-light);\n  }\n\n  .mesaage__heroText h1 {\n    color: var(--clr-light);\n  }\n\n  .mesaage__heroText span {\n    color: var(--clr-light);\n  }\n}\n\n@media (min-width: 1920px) {\n  .message__container {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
