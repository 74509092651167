// import { useEffect, useState } from "react";
import VillasDetail from "../../VillasComponents/VillasDetails";
import "./QuizzCard.css";
import { useDispatch, useSelector } from "react-redux";
// import { getVillas } from "../../../actions/villaActions";
import { createQuizzAnswers } from "../../../actions/quizzActions";

const QuizzCard = ({ quizz, result, currentQuestion }) => {
  // show details just for results of the quizz
  const dispatch = useDispatch();
  // const [openDetails, setOpenDetails] = useState({});
  const { answers } = useSelector((state) => state.quizzAnswer);
  const handleOpenDetails = () => {
    const isExist = answers.find(
      (answer) => answer.questionNumber === currentQuestion
    );

    dispatch(
      createQuizzAnswers(
        isExist
          ? answers.map((answer) =>
              answer.questionNumber === currentQuestion
                ? { ...answer, answer: quizz.title, id: quizz.id }
                : answer
            )
          : [
              ...answers,
              {
                answer: quizz.title,
                id: quizz.id,
                questionNumber: currentQuestion,
              },
            ]
      )
    );
  };

  return (
    <>
      <div
        className={`quizzCard ${
          answers.find((asnwer) => asnwer.questionNumber === currentQuestion)
            ?.id === quizz.id && "selected"
        }  ${result && "quizz-result"}`}
        onClick={handleOpenDetails}
      >
        {result && (
          <img
            src={
              quizz.img || quizz.images.length
                ? quizz.images[0].url
                : "/images/quizz/image1.png"
            }
            alt={quizz.title}
            className="quizzCard__img"
          />
        )}
        <h3>{quizz.title}</h3>
        {result && (
          <ul className="quizzCard__details">
            <li className="quizzCard__detail">
              <img src="/images/icon/airplane.svg" alt="" />
              Paris — Casablanca
            </li>
            <li className="quizzCard__detail">
              <img src="/images/icon/calendar.svg" alt="" />
              12/12/21 — 26/12/21
            </li>
            <li className="quizzCard__detail">
              <img src="/images/icon/profile.svg" alt="" />
              Vous, et 6 personnes
            </li>
            <li className="quizzCard__detail">
              <img src="/images/icon/home.svg" alt="" />
              L’Oasis Paradisiaque
            </li>
            <li className="quizzCard__detail">
              <img src="/images/icon/gauge.svg" alt="" />6 activités inclues
            </li>
            <li className="quizzCard__detail">
              <img src="/images/icon/pallete.svg" alt="" />
              Décorations comprises
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default QuizzCard;
