import { FAQ_FAIL, FAQ_REQUEST, FAQ_SUCCESS } from "../constants/faqConstants";

export const faqReducer = (state = { faqs: [] }, action) => {
  switch (action.type) {
    case FAQ_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        faqs: action.payload,
      };
    case FAQ_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
