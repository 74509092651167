import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UPDATE_QUOTE_STATUS } from "../../../constants/quoteConstants";
import { currency } from "../../../utils/format";
import Loading from "../../Loading";
import "./MessageReservationDetails.css";

const MessageReservationDetails = ({
  loadingVillas,
  loadingDestinations,
  destinations,
  villas,
  deviceType,
  detail,
  quoteId,
  position,
  alwaysOpen,
  designType,
  setTextCity,
  initialPrice,
  orders,
}) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("pending");
  const { user } = useSelector((state) => state.createRequest);
  const [loadingQuoteAccept, setLoadingQuoteAccept] = useState(false);
  const [loadingQuoteDecline, setLoadingQuoteDecline] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [date, setDate] = useState({
    from: null,
    to: null,
  });
  const [activities, setActivities] = useState([]);
  const [decoration, setDecoration] = useState("");
  const [guests, setGuests] = useState(0);
  const [villa, setVilla] = useState(null);
  const [destination, setDestination] = useState(null);
  const [newPrice, setNewPrice] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);
  const [fullPaymentLink, setFullPaymentLink] = useState(null);
  const [orderStatus, setOrderStatus] = useState("");
  const [products, setProducts] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [decorationDetails, setDecorationDetails] = useState(null);
  const [coffretExpand, setCoffretExpand] = useState(false);
  const [activityExpand, setActivityExpand] = useState(false);

  const handleAcceptQuote = async (quoteId) => {
    try {
      setLoadingQuoteAccept(true);
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/quotes/${quoteId}/accept`,
        { quote_id: quoteId },
        {
          headers: {
            Authorization: user.token,
          },
        }
      );
      if (res.status === 200) {
        setLoadingQuoteAccept(false);
        setStatus("accepted");
        dispatch({
          type: UPDATE_QUOTE_STATUS,
          payload: {
            quoteId,
            status: "accepted",
          },
        });
        if (res.data.stripe_link) setPaymentLink(res.data.stripe_link);
      }
    } catch (err) {
      setLoadingQuoteAccept(false);
      return err;
    }
  };
  const handleDeclineQuote = async (quoteId) => {
    try {
      setLoadingQuoteDecline(true);
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/quotes/${quoteId}/decline`,
        { quote_id: quoteId },
        {
          headers: {
            Authorization: user.token,
          },
        }
      );
      if (res.status === 200) {
        setStatus("error");
        setLoadingQuoteDecline(false);
        dispatch({
          type: UPDATE_QUOTE_STATUS,
          payload: {
            quoteId,
            status: "error",
          },
        });
      }
    } catch (err) {
      setLoadingQuoteDecline(false);
      return err;
    }
  };

  const handleEstimatePrice = (
    villa,
    activities,
    decoration,
    bundles,
    products
  ) => {
    const villaPrice = villa?.price || 0;
    const activitiesPrice =
      activities?.reduce((a, b) => a + b.price * b.qty, 0) || 0;
    const productsPrice =
      products?.reduce((a, b) => a + b.price * b.qty, 0) || 0;
    const bundlesPrice =
      bundles?.reduce((a, b) => a + b.global_price * b.qty, 0) || 0;
    let decorationPrice = 0;
    if (decoration?.type?.includes("Box")) {
      const split = decoration.details.split(" ");
      decorationPrice = Number(split[split.length - 1].split("€")[0]) || 0;
    }
    if (decoration?.type?.includes("Service")) {
      const split = decoration?.budget.split(" ");
      if (split[0]?.toLowerCase() === "plus") {
        decorationPrice = Number(split[split.length - 1].split("€")[0]) || 0;
      } else {
        decorationPrice = Number(split[1].split("€")[0]) || 0;
      }
    }
    if (decoration?.type?.includes("Pas")) {
      decorationPrice = 0;
    }

    const total =
      villaPrice +
      activitiesPrice +
      decorationPrice +
      productsPrice +
      bundlesPrice;

    return currency(total);
  };

  useEffect(() => {
    if (quoteId) {
      let order = orders.find((d) => d.quote_id === quoteId);
      if (order) {
        setOrderStatus(order.status);
        if (order.status === "half_paid") {
          (async function () {
            try {
              const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/v1/orders/${order.id}/get_stripe_link_to_complete_payment`,
                {
                  headers: {
                    Authorization: user.token,
                  },
                }
              );
              if (res.status === 200) {
                setFullPaymentLink(res.data.stripe_link_to_complete_payment);
              } else {
                setFullPaymentLink(null);
              }
            } catch (err) {
              return err;
            }
          })();
        }
      } else {
        setOrderStatus("");
      }

      (async function () {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/quotes/${quoteId}`,
            {
              headers: {
                Authorization: user.token,
              },
            }
          );
          if (res.status === 200) {
            let quoteStatus = res?.data?.aasm_state?.toLowerCase();
            if (quoteStatus === "accepted") {
              setStatus("accepted");
            }
            if (quoteStatus === "created" || quoteStatus === "sent") {
              setStatus("pending");
            }
            if (quoteStatus === "declined" || quoteStatus === "expired") {
              setStatus("error");
            }
            if (res.data.to) {
              setDate((date) => ({ ...date, to: res.data.to }));
            } else {
              setDate((date) => ({ ...date, to: moment().add(1, "day")._d }));
            }
            if (res.data.from) {
              setDate((date) => ({ ...date, from: res.data.from }));
            } else {
              setDate((date) => ({ ...date, from: moment().add(1, "day")._d }));
            }
            if (res.data.activities) {
              setActivities(res.data.activities);
            } else {
              setActivities([]);
            }
            if (res.data.bundles) {
              setBundles(res.data.bundles);
            } else {
              setBundles([]);
            }

            if (res.data.total_guests) {
              setGuests(res.data.total_guests);
            } else {
              setGuests(1);
            }
            if (res.data.kind_of_decoration) {
              setDecoration(res.data.kind_of_decoration);
              setDecorationDetails({
                details:
                  res.data.kind_of_decoration === "Pas de décoration"
                    ? ""
                    : res.data.decoration_box_details || "",
                budget:
                  res.data.kind_of_decoration === "Pas de décoration"
                    ? ""
                    : res.data.decoration_box_details || "",
                exterior_decoration:
                  res.data.kind_of_decoration === "Pas de décoration"
                    ? false
                    : res.data.exterior_decoration || false,
                piece:
                  res.data.kind_of_decoration === "Pas de décoration" ? 0 : 1,
                theme: "",
                type: res.data.kind_of_decoration || "",
              });
            } else {
              setDecorationDetails(detail.decoration);
              setDecoration(detail.decoration?.type);
            }

            if (res.data.price) {
              setNewPrice(res.data.price);
            } else {
              setNewPrice(initialPrice);
            }
            if (res.data.stripe_link) {
              setPaymentLink(res.data.stripe_link);
            } else {
              setPaymentLink(null);
            }
            if (res.data.property_id) {
              const v = villas.find(
                (villa) => villa.id === res.data.property_id
              );
              setVilla(v);
              if (v) {
                const d = destinations.find(
                  (destination) => destination.id === v.destination_id
                );
                if (setTextCity) {
                  setTextCity(d.city);
                }
                setDestination(d);
              }
            }
          }
        } catch (err) {
          return err;
        }
      })();
    } else {
      setNewPrice(initialPrice);
      setPaymentLink(null);
      setFullPaymentLink(null);
      setActivities(detail?.activities || []);
      setDate({
        from: detail.date_from || moment()._d,
        to: detail.date_to || moment().add(1, "day")._d,
      });

      setDecoration(detail.decoration?.type || "Pas de décoration");
      setDecorationDetails(detail.decoration);
      setProducts(detail?.products || []);
      setBundles(detail?.bundles || []);
      setGuests(detail?.guests);
      if (detail.property)
        setVilla(villas.find((villa) => villa.id === detail.property));
      if (detail.destination_id) {
        const d = destinations.find(
          (destination) => destination.id === detail.destination_id
        );
        if (setTextCity) {
          setTextCity(d.city);
        }
        setDestination(d);
      }
    }
  }, [
    user,
    quoteId,
    villas,
    destinations,
    detail,
    setTextCity,
    initialPrice,
    orders,
  ]);

  return (
    <div
      className={`messageReservationDetails ${deviceType} ${position} ${designType}`}
    >
      {!alwaysOpen && (
        <h4>
          Votre demande de réservation
          <span onClick={() => setIsExpand((isExpand) => !isExpand)}>
            {isExpand ? "Voir moins" : "Voir plus"}
          </span>
        </h4>
      )}
      {isExpand || alwaysOpen ? (
        (quoteId ? !villa : loadingDestinations && loadingVillas) ? (
          <div className="loading-container-text">
            S'il vous plaît, attendez le chargement des données ...
          </div>
        ) : (
          <>
            <ul className="messageReservationDetails__details">
              <li className="messageReservationDetails__detail">
                <img src="/images/icon/airplane.svg" alt="" />
                {destination && (quoteId || detail.destination_id)
                  ? `${destination.country} — ${destination.city}`
                  : "Il n'y en a pas"}
              </li>
              <li className="messageReservationDetails__detail">
                <img src="/images/icon/profile.svg" alt="" />
                {guests || 1} invités
              </li>
              {activities.length ? (
                <li
                  className={`messageReservationDetails__detail ${
                    activityExpand && "show"
                  }  ${designType === "summary" && "cursor-click"}`}
                  onClick={() =>
                    setActivityExpand((activityExpand) => !activityExpand)
                  }
                >
                  <div>
                    <img src="/images/icon/gauge.svg" alt="" />
                    {activities.length || 0} activités incluses
                    {designType === "summary" && (
                      <svg
                        className="chevron"
                        viewBox="0 0 512 512"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256 298.3L430.2 131.1C434.5 126.9 441.6 127 446 131.3L476.6 161.2C481 165.5 481.1 172.5 476.8 176.7L264.1 380.9C261.9 383.1 258.9 384.1 256 383.9C253 384 250.1 383 247.9 380.9L35.2 176.7C30.9 172.5 31 165.5 35.4 161.2L66 131.3C70.4 127 77.5 126.9 81.8 131.1L256 298.3Z"
                          fill="#a94e4d"
                        />
                      </svg>
                    )}
                  </div>
                  {designType === "summary" &&
                  activities?.length &&
                  activityExpand ? (
                    <ul>
                      {activities.map((act) => (
                        <li key={act.id}>
                          {act.title} - quantité: {act.qty}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ) : null}
              {bundles.length ? (
                <li
                  className={`messageReservationDetails__detail gift ${
                    coffretExpand && "show"
                  }  ${designType === "summary" && "cursor-click"}`}
                  onClick={() =>
                    setCoffretExpand((coffretExpand) => !coffretExpand)
                  }
                >
                  <div>
                    <img src="/images/icon/gift.svg" alt="" />
                    {bundles?.length || 0} coffrets inclus&nbsp;
                    {designType === "summary" && (
                      <svg
                        className="chevron"
                        viewBox="0 0 512 512"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256 298.3L430.2 131.1C434.5 126.9 441.6 127 446 131.3L476.6 161.2C481 165.5 481.1 172.5 476.8 176.7L264.1 380.9C261.9 383.1 258.9 384.1 256 383.9C253 384 250.1 383 247.9 380.9L35.2 176.7C30.9 172.5 31 165.5 35.4 161.2L66 131.3C70.4 127 77.5 126.9 81.8 131.1L256 298.3Z"
                          fill="#a94e4d"
                        />
                      </svg>
                    )}
                  </div>
                  {designType === "summary" &&
                  bundles?.length &&
                  coffretExpand ? (
                    <ul>
                      {bundles.map((bundle) => (
                        <li key={bundle.id}>
                          {bundle.name || bundle.title || "N/A"} -
                          quantité:&nbsp;
                          {bundle.qty}
                          <br />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ) : null}
              <li className="messageReservationDetails__detail">
                <img src="/images/icon/calendar.svg" alt="" />
                {date.from && date.to
                  ? `${moment(date.from).format("DD/MM/YYYY")} — 
              ${moment(date.to).format("DD/MM/YYYY")}`
                  : "Il n'y en a pas"}
              </li>
              <li className="messageReservationDetails__detail">
                <img src="/images/icon/home.svg" alt="" />
                {villa?.title && (quoteId || detail.property)
                  ? villa.title
                  : "Il n'y en a pas"}
              </li>

              {decoration !== "Pas de décoration" && (
                <li className="messageReservationDetails__detail">
                  <img src="/images/icon/pallete.svg" alt="" />
                  {decoration === "Pas de décoration"
                    ? decoration
                    : (
                        <>
                          {decoration}&nbsp;
                          {decoration?.includes("Box")
                            ? decorationDetails.details?.includes("300")
                              ? "300€"
                              : "150€"
                            : ""}
                        </>
                      ) || "Pas de décoration"}
                </li>
              )}
            </ul>
            {quoteId && newPrice ? (
              <span className="messageReservationDetails__quote">
                Tarif :&nbsp;
                {currency(newPrice) || 0}€
              </span>
            ) : (
              <span className="messageReservationDetails__quote">
                Tarif estimé à valider par Maiden Agency :&nbsp;
                {currency(newPrice) || 0}€
              </span>
            )}
            {quoteId &&
              status === "accepted" &&
              orderStatus !== "half_paid" &&
              orderStatus !== "paid" &&
              !paymentLink && (
                <span className="messageReservationDetails__errorPayment">
                  il n'y a pas de lien de paiement, veuillez nous contacter à
                  cet e-mail &nbsp;
                  <a href="mailto:contact@maiden-agency.com">
                    contact@maiden-agency.com
                  </a>
                </span>
              )}
            {quoteId &&
              status === "accepted" &&
              (fullPaymentLink || paymentLink) && (
                <button
                  className={`messageReservationDetails__btnPayment ${
                    loadingQuoteDecline && "loading-button-decline"
                  }`}
                >
                  <a
                    href={
                      orderStatus === "half_paid"
                        ? fullPaymentLink
                        : paymentLink
                    }
                  >
                    {orderStatus === "half_paid"
                      ? "Regler le solde de ma réservation"
                      : "Valider le devis et régler 50% d’acompte"}
                  </a>
                </button>
              )}
            {quoteId && status === "pending" ? (
              <div className="messageReservationDetails__btnContainer">
                <button
                  onClick={() => handleAcceptQuote(quoteId)}
                  disabled={loadingQuoteAccept || loadingQuoteDecline}
                >
                  {loadingQuoteAccept ? (
                    <Loading />
                  ) : (
                    <>
                      <i className="tick-mark"></i>Valider
                    </>
                  )}
                </button>
                <button
                  onClick={() => handleDeclineQuote(quoteId)}
                  className={`${
                    loadingQuoteDecline && "loading-button-decline"
                  }`}
                  disabled={loadingQuoteAccept || loadingQuoteDecline}
                >
                  {loadingQuoteDecline ? (
                    <Loading />
                  ) : (
                    <>
                      <i className="cross-mark"></i>
                      Décliner
                    </>
                  )}
                </button>
              </div>
            ) : (
              quoteId &&
              (status === "accepted" || status === "error") && (
                <span
                  className={`messageReservationDetails__detailsQuote ${status}`}
                >
                  {status === "accepted" ? (
                    <>
                      <svg
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="badgeRequest__successIcon"
                      >
                        <path
                          d="M2.33334 7.18286L5.63318 10.4827L12.2328 3.88303"
                          stroke="#00994D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      Vous avez validé ce devis
                    </>
                  ) : (
                    <>
                      <svg
                        className="badgeRequest__errorIcon"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.5 3.5L10.9516 10.9516"
                          stroke="#98014C"
                          strokeWidth="1.16667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.5 10.9517L10.9516 3.50012"
                          stroke="#98014C"
                          strokeWidth="1.16667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Vous avez refusé cette offre
                    </>
                  )}
                </span>
              )
            )}
          </>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default MessageReservationDetails;
