// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badgeRequest {
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 0.5em;
}

.badgeRequest.paid {
  background: var(--clr-success) !important;
  color: var(--clr-success-dark) !important;
}

.badgeRequest.accepted .badgeRequest__errorIcon,
.badgeRequest.accepted .badgeRequest__successIcon,
.badgeRequest.paid .badgeRequest__errorIcon,
.badgeRequest.paid .badgeRequest__pendingIcon {
  display: none;
}

.badgeRequest.paid .badgeRequest__successIcon {
  display: flex;
}

.badgeRequest svg {
  width: 1.15rem;
  margin-right: 0.5rem;
}

.badgeRequest.error {
  background: var(--clr-error);
  color: var(--clr-error-dark);
}

.badgeRequest.error .badgeRequest__successIcon,
.badgeRequest.error .badgeRequest__pendingIcon {
  display: none;
}

.badgeRequest.accepted,
.badgeRequest.valid,
.badgeRequest.pending {
  background: var(--clr-warning);
  color: var(--clr-warning-dark);
}

.badgeRequest.valid .badgeRequest__successIcon,
.badgeRequest.valid .badgeRequest__errorIcon,
.badgeRequest.pending .badgeRequest__successIcon,
.badgeRequest.pending .badgeRequest__errorIcon {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/BadgeRequest/BadgeRequest.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,yCAAyC;EACzC,yCAAyC;AAC3C;;AAEA;;;;EAIE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;EAC5B,4BAA4B;AAC9B;;AAEA;;EAEE,aAAa;AACf;;AAEA;;;EAGE,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;;;;EAIE,aAAa;AACf","sourcesContent":[".badgeRequest {\n  display: flex;\n  align-items: center;\n  padding: 0.5em 1em;\n  font-size: 0.75rem;\n  font-weight: bold;\n  border-radius: 0.5em;\n}\n\n.badgeRequest.paid {\n  background: var(--clr-success) !important;\n  color: var(--clr-success-dark) !important;\n}\n\n.badgeRequest.accepted .badgeRequest__errorIcon,\n.badgeRequest.accepted .badgeRequest__successIcon,\n.badgeRequest.paid .badgeRequest__errorIcon,\n.badgeRequest.paid .badgeRequest__pendingIcon {\n  display: none;\n}\n\n.badgeRequest.paid .badgeRequest__successIcon {\n  display: flex;\n}\n\n.badgeRequest svg {\n  width: 1.15rem;\n  margin-right: 0.5rem;\n}\n\n.badgeRequest.error {\n  background: var(--clr-error);\n  color: var(--clr-error-dark);\n}\n\n.badgeRequest.error .badgeRequest__successIcon,\n.badgeRequest.error .badgeRequest__pendingIcon {\n  display: none;\n}\n\n.badgeRequest.accepted,\n.badgeRequest.valid,\n.badgeRequest.pending {\n  background: var(--clr-warning);\n  color: var(--clr-warning-dark);\n}\n\n.badgeRequest.valid .badgeRequest__successIcon,\n.badgeRequest.valid .badgeRequest__errorIcon,\n.badgeRequest.pending .badgeRequest__successIcon,\n.badgeRequest.pending .badgeRequest__errorIcon {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
