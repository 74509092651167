import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./InputLabelFloat.css";
import { useDispatch, useSelector } from "react-redux";
import { VILLAS_ADD_FILTER } from "../../constants/villaConstants";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setRequest } from "../../actions/requestActions";
import { useState } from "react";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

const InputLabelFloat = ({
  handleRemoveValue,
  handleInputValue,
  name,
  label,
  placeholder,
  handleInputDate,
  handleClick,
  password,
  value,
  maxLength = 500,
  isEditAble = false,
  readOnly = false,
  isSuggestions,
  handleClickEdit,
  filterInvites,
  type = { type: "", minDate: new Date(), selected: new Date() },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.villaFilter);
  const [filterGuests, setFilterGuests] = useState(filterInvites);
  const { suggestions } = useSelector((state) => state.destination);
  const { dataRequest } = useSelector((state) => state.request);

  const addCount = () => {
    dispatch({
      type: VILLAS_ADD_FILTER,
      payload: { ...filters, invites: filters.invites + 1 },
    });
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        guests: dataRequest.guests + 1,
      })
    );
  };
  const minusCount = () => {
    dispatch({
      type: VILLAS_ADD_FILTER,
      payload: {
        ...filters,
        invites: filters.invites <= 1 ? 1 : filters.invites - 1,
      },
    });
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        guests: dataRequest.guests <= 1 ? 1 : dataRequest.guests - 1,
      })
    );
  };

  return (
    <div
      className={`inputLabelFloat ${
        (type.type === "date" || type.type === "counter") && "date"
      }`}
    >
      {type.type === "date" ? (
        <>
          <label htmlFor={`${name}-${Date.now()}`}>{label}</label>
          <DatePicker
            minDate={moment(type.minDate).toDate()}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            selected={moment(type.selected).toDate()}
            onChange={(date) => handleInputDate(date)}
          />
        </>
      ) : type.type === "counter" ? (
        <div className="inputLabelFloat__inputCounterContainer">
          <label htmlFor={`${name}-${Date.now()}`}>{label}</label>
          <div className="inputLabelFloat__counter">
            <button
              className="btn-minus"
              onClick={() => minusCount()}
              type="button"
            ></button>
            <span>{filters.invites}</span>
            <button
              className="btn-add"
              onClick={() => addCount()}
              type="button"
            ></button>
          </div>
        </div>
      ) : (
        <>
          <input
            type={`${password ? "password" : "text"}`}
            placeholder={placeholder}
            id={`${name}-${Date.now()}`}
            onChange={handleInputValue}
            onClick={handleClick}
            name={name}
            value={value}
            autoComplete="off"
            maxLength={maxLength}
            readOnly={readOnly}
          />
          <label htmlFor={`${name}-${Date.now()}`}>{label}</label>
          {!readOnly && (
            <svg
              className="inputLabelFloat__iconClose"
              viewBox="0 0 14 14"
              fill="none"
              onClick={() => handleRemoveValue(name)}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L13 13"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 13L13 1.00002"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {isEditAble && (
            <svg
              className="inputLabelFloat__iconClose"
              viewBox="0 0 18 18"
              fill="none"
              onClick={handleClickEdit}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.66349 11.7463L13.1644 1.24541C13.1918 1.2366 13.224 1.22694 13.2604 1.21709C13.4128 1.1758 13.6318 1.13301 13.8891 1.12946C14.3887 1.12257 15.049 1.2605 15.6901 1.90165C16.3313 2.5428 16.4692 3.2031 16.4623 3.70269C16.4588 3.95996 16.416 4.17898 16.3747 4.33137C16.3648 4.36776 16.3552 4.39991 16.3464 4.42739L5.84547 14.9283L2.22959 15.3622L2.66349 11.7463Z"
                stroke="#A94E4D"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M10.7003 2.64868L14.7409 6.68929"
                stroke="#A94E4D"
                strokeWidth="1.5"
              />
            </svg>
          )}
        </>
      )}
      {isSuggestions &&
      suggestions
        .sort(
          (a, b) =>
            (a.country < b.country ? -1 : a.country > b.country ? 1 : 0) ||
            (a.region < b.region ? -1 : a.region > b.region ? 1 : 0)
        )
        .filter((suggestion) =>
          `${suggestion.country}, ${suggestion.city}, ${suggestion.region}`
            ?.toLowerCase()
            ?.includes(value?.toLowerCase())
        ).length ? (
        <ul className="inputLabelFloat__suggestions">
          {suggestions
            .sort(
              (a, b) =>
                (a.country < b.country ? -1 : a.country > b.country ? 1 : 0) ||
                (a.region < b.region ? -1 : a.region > b.region ? 1 : 0)
            )
            .map(
              (suggestion) =>
                `${suggestion.country}, ${suggestion.city}`
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase()) && (
                  <>
                    <li
                      key={suggestion.id}
                      onClick={() => {
                        if (window.location.pathname?.includes("villas")) {
                          if (filters.invites > filterGuests) {
                            setFilterGuests(filterGuests.invites);
                          }
                          dispatch({
                            type: VILLAS_ADD_FILTER,
                            payload: {
                              ...filters,
                              destinationId: suggestion.id,
                            },
                          });
                        } else {
                          history.push(
                            `/destinations/${suggestion.city?.toLowerCase()}`
                          );
                        }
                      }}
                    >
                      {suggestion.country}, {suggestion.city}
                    </li>
                  </>
                )
            )}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputLabelFloat;
