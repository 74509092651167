import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import searchIcon from "../../assets/search.svg";
import "./Hero.css";
import DatesPopup from "../DatesPopup";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { getDestination } from "../../actions/destinationActions";
import HeroSkeleton from "../Skeleton/HeroSkeleton";
import SearchBar from "../SearchBar";

const Hero = ({
  title,
  heroImg,
  coffrets,
  model,
  isInput,
  headText,
  height,
  handleSearch,
  filterGuests,
  searchBar = { show: false },
  backgroundColor,
  loadingSkeleton = true,
  isDestination = true,
}) => {
  const { filters } = useSelector((state) => state.villaFilter);
  const { dataRequest } = useSelector((state) => state.request);
  const [showDatesPopup, setShowDatesPopup] = useState(false);
  const dispatch = useDispatch();
  const dateOne = useRef(null);
  const { destination, loading } = useSelector((state) => state.destination);
  const { suggestions } = useSelector((state) => state.destination);
  const getDest =
    suggestions.length &&
    suggestions.find((s) =>
      filters.destinationId
        ? Number(filters.destinationId)
          ? s.id === Number(filters.destinationId)
          : s?.country?.toLowerCase().trim() === filters.destinationId ||
            s?.region?.toLowerCase().trim() === filters.destinationId
        : s
    );
  const dateTwo = useRef(null);
  const [navPosition, setNavPosition] = useState(
    window.innerWidth < 768 ? 1 : 0
  );

  useEffect(() => {
    if (localStorage.getItem("maiden_data")) {
      if (!window.location.pathname?.includes("villas")) {
        dispatch(
          getDestination(
            JSON.parse(localStorage.getItem("maiden_data")).destination_id
          )
        );
      }
    }

    window.addEventListener("resize", () => {
      setNavPosition(window.innerWidth < 768 ? 1 : 0);
    });
  }, [dispatch]);

  return loading && loadingSkeleton ? (
    <HeroSkeleton heroImg={heroImg} />
  ) : (
    <>
      <section className="">
        <div
          className={`hero__imgContainer ${height} ${
            !heroImg && "no-img"
          } ${backgroundColor}`}
        >
          <img src={heroImg} alt="activities hero" />
        </div>
        <div
          className={`hero__content  ${model === "Model 2" ? "model2" : ""}`}
        >
          {model !== "Model 2" ? (
            <>
              <div className="w-full">
                <h1 className="hero__title w-full text-center">
                  {window.location.pathname?.includes("villas") ? (
                    filters.destinationId && getDest ? (
                      <>
                        {suggestions.length
                          ? Number(filters.destinationId)
                            ? getDest.city
                            : getDest.country?.toLowerCase().trim() ===
                              filters.destinationId
                            ? getDest.country
                            : filters.destinationId[0].toUpperCase() +
                              filters.destinationId.substring(1)
                          : ""}
                        {getDest.country?.toLowerCase().trim() ===
                        filters.destinationId
                          ? ""
                          : `, ${getDest.country}`}
                        <br />
                      </>
                    ) : (
                      ""
                    )
                  ) : destination && isDestination ? (
                    <>
                      {destination.city}, {destination.country} <br />
                    </>
                  ) : (
                    ""
                  )}
                  <strong>{title}</strong>
                </h1>
                {coffrets && (
                  <small className="hero__optional">facultatif</small>
                )}
              </div>
              <div className="hero__leftSection">
                <div className="hero__date">
                  <div
                    onClick={() => {
                      setShowDatesPopup((showDatesPopup) => !showDatesPopup);
                      if (!showDatesPopup) {
                        dateOne.current.input.focus();
                        dateTwo.current.input.focus();
                      }
                    }}
                  >
                    <h4>Dates</h4>
                    <span>
                      {moment(dataRequest.date_from).format("ll")} -&nbsp;
                      {moment(dataRequest.date_to).format("ll")}
                    </span>
                  </div>
                  <DatesPopup
                    show={showDatesPopup}
                    dateTwo={dateTwo}
                    dateOne={dateOne}
                  />
                </div>
                {coffrets && (
                  <small className="hero__optionalMobile">facultatif</small>
                )}
              </div>
            </>
          ) : (
            <div className="w-full ">
              <h1 className="hero__title w-full text-center">
                {headText}
                <br /> <strong>{title}</strong>
              </h1>
              {isInput && (
                <form>
                  <img src={searchIcon} alt="" />
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </form>
              )}
            </div>
          )}
        </div>
        {searchBar.show && navPosition === 0 && (
          <div className="villas__search container">
            <SearchBar
              values={{
                ...searchBar.values,
                where: window.location.pathname?.includes("villas")
                  ? getDest && filters.destinationId
                    ? Number(filters.destinationId)
                      ? getDest.city
                      : filters.destinationId
                    : searchBar.values.where
                  : destination
                  ? destination?.city
                  : searchBar.values.where,
              }}
              filterGuests={filterGuests || 1}
              handleInputValue={searchBar.handleInputValue}
              handleRemoveValue={searchBar.handleRemoveValue}
              isSuggestions={searchBar.isSuggestions}
              handleSearch={handleSearch}
              dataRequest={dataRequest}
              isFilter={true}
              handleShowFilter={searchBar.handleShowFilter}
            />
          </div>
        )}
      </section>
      {searchBar.show && navPosition === 1 && (
        <div className="villas__search container">
          <SearchBar
            values={{
              ...searchBar.values,
              where: window.location.pathname?.includes("villas")
                ? getDest && filters.destinationId
                  ? Number(filters.destinationId)
                    ? getDest.city
                    : filters.destinationId
                  : searchBar.values.where
                : destination
                ? destination?.city
                : searchBar.values.where,
            }}
            filterGuests={filterGuests || 1}
            handleInputValue={searchBar.handleInputValue}
            handleRemoveValue={searchBar.handleRemoveValue}
            isSuggestions={searchBar.isSuggestions}
            handleSearch={handleSearch}
            dataRequest={dataRequest}
            isFilter={true}
            handleShowFilter={searchBar.handleShowFilter}
          />
        </div>
      )}
    </>
  );
};

export default Hero;
