import {
  ACTIVITIES_REQUEST,
  ACTIVITIES_SUCCESS,
  ACTIVITIES_FAIL,
} from "../constants/activityConstants";

export const activityReducers = (state = { activities: [] }, action) => {
  switch (action.type) {
    case ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: action.payload,
      };
    case ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
