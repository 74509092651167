import moment from "moment";
import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  MAKE_REQUEST_FAIL,
  MAKE_REQUEST_REQUEST,
  MAKE_REQUEST_SUCCESS,
  MAKE_REQUEST_DONE,
  SET_REQUEST,
} from "../constants/requestConstants";

export const requestReducers = (
  state = {
    dataRequest: {
      date_from: moment()._d,
      date_to: moment().add(1, "day")._d,
      guests: 0,
      destination_id: "",
      property: null,
      activities: [],
      decoration: {},
      products: [],
      bundles: [],
      delivery: "",
      tag: "original",
      offer_id: null,
    },
  },
  action
) => {
  switch (action.type) {
    case SET_REQUEST:
      localStorage.setItem("maiden_data", JSON.stringify(action.payload));
      return {
        dataRequest: action.payload,
      };

    default:
      return state;
  }
};

export const makeRequestReducer = (state = { user: null }, action) => {
  switch (action.type) {
    case MAKE_REQUEST_REQUEST:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case MAKE_REQUEST_SUCCESS:
      localStorage.setItem("maiden_user", JSON.stringify(action.payload));
      localStorage.removeItem("maiden_data");
      return {
        success: true,
        user: action.payload,
        loading: false,
      };
    case MAKE_REQUEST_FAIL:
      return {
        error: action.payload,
        loading: false,
      };
    case MAKE_REQUEST_DONE:
      return {
        ...state,
        success: false,
      };
    case LOGIN_REQUEST:
      return {
        loading: true,
      };
    case LOGIN_SUCCESS: {
      localStorage.setItem("maiden_user", JSON.stringify(action.payload));
      localStorage.removeItem("maiden_data");
      return {
        success: true,
        user: action.payload,
        loading: false,
      };
    }
    case LOGIN_FAIL:
      return {
        error: action.payload,
        loading: false,
      };

    case LOGOUT: {
      return {
        user: null,
      };
    }
    default:
      return state;
  }
};
