import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { login, makeRequest } from "../../actions/requestActions";
import { MAKE_REQUEST_DONE } from "../../constants/requestConstants";
import { validateConnect, validateLogin } from "../../utils/validateForm";
import Loading from "../Loading";
import "./PaymentInformation.css";

const PaymentInformation = ({
  dataRequest,
  isLogin,
  title = "Vos informations",
}) => {
  const { authType } = useParams();
  const [isAlreadyHaveAccount, setIsAlreadyHaveAccount] = useState(
    authType === "login"
  );
  const [errors, setErrors] = useState({});
  const { user } = useSelector((state) => state.createRequest);
  const [loadingRegister, setLoadingRegister] = useState(false);

  // values for register
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });
  // values for user
  const [valuesLogin, setValuesLogin] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading,
    error,
    success: successRegister,
  } = useSelector((state) => state.createRequest);
  const handleClick = async (e) => {
    e.preventDefault();
    if (!isAlreadyHaveAccount) {
      const { valid, errors } = validateConnect(values);
      if (!valid) {
        setErrors(errors);
        return;
      }
      setErrors({});
      setLoadingRegister(true);

      let isNewUser = false;

      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/public/check_email?email=${values.email}`
      );

      if (res)
        if (res.data.success && !user) {
          setErrors({
            auth: "Cette email possède déjà un compte client. Veuillez vous connecter ou faire mot de passe oublié",
          });
        } else {
          if (res.data.error) {
            isNewUser = true;
          }
          dispatch(
            makeRequest(
              dataRequest,
              { ...values, email: values.email.toLowerCase() },
              isNewUser
            )
          );
        }

      setLoadingRegister(false);
      setValues({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      });
    } else {
      const { valid, errors } = validateLogin(valuesLogin);
      if (!valid) {
        setErrors(errors);
        return;
      }
      setErrors({});
      dispatch(
        login({ ...valuesLogin, email: valuesLogin.email.toLowerCase() })
      );
      setValuesLogin({
        password: "",
        email: "",
      });
    }
  };

  const handleRequest = async () => {
    try {
      dispatch(
        makeRequest(
          dataRequest,
          {
            first_name: user ? user?.first_name : "",
            last_name: user ? user?.last_name : "",
            phone: user ? user.phone?.toString() : "",
            email: user ? user?.email.toLowerCase() : "",
          },
          false
        )
      );
    } catch (err) {
      return err;
    }
  };

  // register
  const handleChange = (e, maxLength) => {
    const value = e.target.value.slice(0, maxLength);
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleRemoveValues = (name) => {
    setValues({ ...values, [name]: "" });
  };

  // login
  const handleChangeLogin = (e, maxLength) => {
    const value = e.target.value.slice(0, maxLength);
    setValuesLogin({
      ...valuesLogin,
      [e.target.name]: value,
    });
  };

  const handleRemoveValuesLogin = (name) => {
    setValuesLogin({ ...valuesLogin, [name]: "" });
  };

  useEffect(() => {
    if (successRegister) {
      dispatch({ type: MAKE_REQUEST_DONE });
      history.push("/message");
    }
  }, [successRegister, history, user, dispatch]);

  return (
    <div className="paymentInformation">
      <div>
        <h2 className="paymentInformation__title">{title}</h2>
        <span>
          Inscrivez-vous ou connectez-vous à votre compte afin de consulter ou
          effectuer une demande de réservation
        </span>
      </div>
      <div className="paymentInformation__connect">
        <h4>Vous avez déjà un compte ?</h4>

        <div className="paymentInformation__connectOptions">
          {user && loading && !loadingRegister ? (
            <button
              className="paymentInformation__connectBtn btn"
              disabled={true}
            >
              <Loading />
            </button>
          ) : (
            user && (
              <button
                className="paymentInformation__connectBtn btn"
                onClick={handleRequest}
              >
                Continuez la réservation sur mon compte {user.email}
              </button>
            )
          )}

          {!user ? (
            <button
              className="paymentInformation__connectBtn btn"
              onClick={() => {
                if (!isAlreadyHaveAccount) history.push("/auth/login");
                else history.push("/auth");
                setIsAlreadyHaveAccount(
                  (isAlreadyHaveAccount) => !isAlreadyHaveAccount
                );
              }}
            >
              {isAlreadyHaveAccount
                ? "Je ne suis pas encore inscrit"
                : " Je me connecte"}

              <svg
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.56207 12.7139C7.16556 15.3174 11.3867 15.3174 13.9902 12.7139C16.5937 10.1104 16.5937 5.88933 13.9902 3.28583C11.3867 0.682337 7.16556 0.682337 4.56207 3.28583"
                  stroke="#a94e4d"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33337 6L10.1112 8L8.33337 10"
                  stroke="#a94e4d"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 8L1.66667 8"
                  stroke="#a94e4d"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      <span className="paymentInformation__or">OU</span>

      {errors.auth && (
        <span className="paymentInformation__alert">{errors.auth}</span>
      )}

      {!isAlreadyHaveAccount ? (
        <form className="paymentInformation__input" onSubmit={handleClick}>
          {error && <span className="error-text">{error}</span>}
          <div>
            <div
              className={`paymentInformation__inputContainer ${
                errors.first_name && "error"
              }`}
            >
              <input
                name="first_name"
                value={values.first_name}
                onChange={(e) => handleChange(e, 40)}
                type="text"
                id="first-name"
                placeholder="Prénom"
              />
              <label htmlFor="name">Prénom</label>
              <svg
                className="paymentInformation__inputClose"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleRemoveValues("first_name")}
              >
                <path
                  d="M1 1L13 13"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13L13 1.00002"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {errors.first_name && (
              <span className="error-text">{errors.first_name}</span>
            )}
          </div>
          <div>
            <div
              className={`paymentInformation__inputContainer ${
                errors.last_name && "error"
              }`}
            >
              <input
                name="last_name"
                onChange={(e) => handleChange(e, 40)}
                value={values.last_name}
                type="text"
                placeholder="Nom de famille"
                onClick={() => handleRemoveValues("last_name")}
                id="last-name"
              />
              <label htmlFor="last-name">Nom de famille</label>
              <svg
                className="paymentInformation__inputClose"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L13 13"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13L13 1.00002"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {errors.last_name && (
              <span className="error-text">{errors.last_name}</span>
            )}
          </div>
          <div>
            <div
              className={`paymentInformation__inputContainer ${
                errors.phone && "error"
              }`}
            >
              <input
                type="number"
                name="phone"
                value={values.phone}
                onChange={(e) => handleChange(e, 10)}
                id="phone-number"
                placeholder="Numéro de téléphone"
              />
              <label htmlFor="phone-number">Numéro de téléphone</label>
              <svg
                className="paymentInformation__inputClose"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleRemoveValues("phone")}
              >
                <path
                  d="M1 1L13 13"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13L13 1.00002"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {errors.phone && <span className="error-text">{errors.phone}</span>}
          </div>
          <div>
            <div
              className={`paymentInformation__inputContainer ${
                errors.email && "error"
              }`}
            >
              <input
                name="email"
                onChange={(e) => handleChange(e, 40)}
                value={values.email}
                type="text"
                placeholder="Adresse e-mail"
                id="email"
              />
              <label htmlFor="email">Adresse e-mail</label>
              <svg
                className="paymentInformation__inputClose"
                viewBox="0 0 14 14"
                fill="none"
                onClick={() => handleRemoveValues("email")}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L13 13"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 13L13 1.00002"
                  stroke="#6E7191"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {errors.email && <span className="error-text">{errors.email}</span>}
          </div>
        </form>
      ) : (
        <form
          className="paymentInformation__input login"
          onSubmit={handleClick}
        >
          {error && <span className="error-text">{error}</span>}
          <div
            className={`paymentInformation__inputContainer ${
              errors.emailLogin && "error"
            }`}
          >
            <input
              type="text"
              placeholder="Adresse e-mail"
              value={valuesLogin.email}
              onChange={(e) => handleChangeLogin(e, 40)}
              name="email"
              id="email"
            />
            <label htmlFor="email">Adresse e-mail</label>
            <svg
              className="paymentInformation__inputClose"
              viewBox="0 0 14 14"
              fill="none"
              onClick={() => handleRemoveValuesLogin("email")}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L13 13"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 13L13 1.00002"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {errors.emailLogin && (
              <span className="error-text">{errors.emailLogin}</span>
            )}
          </div>

          <div
            className={`paymentInformation__inputContainer ${
              errors.password && "error"
            }`}
          >
            <input
              type="password"
              placeholder="Mot de passe"
              onChange={(e) => handleChangeLogin(e, 40)}
              name="password"
              id="password"
              value={valuesLogin.password}
            />
            <label htmlFor="password">Mot de passe</label>
            <svg
              className="paymentInformation__inputClose"
              viewBox="0 0 14 14"
              onClick={() => handleRemoveValuesLogin("password")}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L13 13"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 13L13 1.00002"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {errors.password && (
              <span className="error-text">{errors.password}</span>
            )}
          </div>
        </form>
      )}

      {isLogin && isAlreadyHaveAccount && (
        <Link
          className="paymentInformation__forgotPassword"
          to="/users/password/edit"
        >
          Mot de passe oublié ?
        </Link>
      )}

      <button
        className="paymentInformation__btn btn"
        disabled={loading || loadingRegister}
        onClick={handleClick}
      >
        {loading || loadingRegister ? (
          <Loading />
        ) : (
          <>
            {isAlreadyHaveAccount ? "Connexion" : "Inscription"}
            {!isAlreadyHaveAccount && (
              <svg
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 9L16.1667 12L13.5 15"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 12L1.5 12"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M9.5 5V2H23.5V22H9.5V19"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </>
        )}
      </button>
      <span className="paymentInformation__note">
        Inscrivez-vous ou connectez-vous à votre compte afin de valider votre
        demande de réservation
      </span>
    </div>
  );
};

export default PaymentInformation;
