import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRequest } from "../../../actions/requestActions";
import "./ActivityDetails.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { currency } from "../../../utils/format";

SwiperCore.use([Pagination, Navigation]);

const ActivityDetails = ({
  icon,
  title,
  closeDetails,
  selected,
  description,
  id,
  images = { multiple: false, images: [] },
  isIcon = true,
  price = 0,
  isCountable,
  handleAddActivity,
  handleRemoveActivity,
  isCoffret,
  isButton = true,
}) => {
  const dispatch = useDispatch();
  const { dataRequest } = useSelector((state) => state.request);
  const activityData = dataRequest.activities.find((a) => a.id === id);
  const { selectedCoffret } = useSelector((state) => state.selectedCoffret);

  const addCount = () => {
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        activities: dataRequest.activities.map((data) =>
          data.id === id ? { ...data, qty: data.qty + 1 } : data
        ),
      })
    );
  };

  const minusCount = () => {
    if (activityData.qty > 1) {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          activities: dataRequest.activities.map((data) =>
            data.id === id ? { ...data, qty: data.qty - 1 } : data
          ),
        })
      );
    } else {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          activities: dataRequest.activities.filter((data) => data.id !== id),
        })
      );
    }
  };

  return (
    <div
      className={`activityDetails ${
        isCoffret &&
        selectedCoffret?.id +
          (selectedCoffret?.title || selectedCoffret?.name) ===
          id &&
        "show"
      }`}
      data-id={id}
    >
      <div>
        {images.multiple ? (
          images.images.length ? (
            <div className="activityDetails__imgContainer">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
              >
                {images.images.map((image) => (
                  <SwiperSlide key={image.id}>
                    <div className="activityDetails__imgContainer">
                      <img
                        src={image.url}
                        alt={image.name}
                        className="activityDetails__img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <svg
                onClick={closeDetails}
                className={`activityDetails__closeIcon ${
                  !images.multiple && "not-multiple-image"
                }`}
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 36.4167C28.619 36.4167 36.4167 28.619 36.4167 19C36.4167 9.38108 28.619 1.58337 19 1.58337C9.38104 1.58337 1.58333 9.38108 1.58333 19C1.58333 28.619 9.38104 36.4167 19 36.4167ZM13.1304 13.1304C13.7487 12.5121 14.7513 12.5121 15.3696 13.1304L19 16.7608L22.6304 13.1304C23.2487 12.5121 24.2513 12.5121 24.8696 13.1304C25.4879 13.7487 25.4879 14.7513 24.8696 15.3696L21.2392 19L24.8696 22.6304C25.4879 23.2487 25.4879 24.2513 24.8696 24.8696C24.2513 25.4879 23.2487 25.4879 22.6304 24.8696L19 21.2392L15.3696 24.8696C14.7513 25.4879 13.7487 25.4879 13.1304 24.8696C12.5121 24.2513 12.5121 23.2487 13.1304 22.6304L16.7608 19L13.1304 15.3696C12.5121 14.7513 12.5121 13.7487 13.1304 13.1304Z"
                  fill="#FCFCFC"
                />
              </svg>
            </div>
          ) : null
        ) : (
          <svg
            onClick={closeDetails}
            className={`activityDetails__closeIcon ${
              !images.multiple && "not-multiple-image"
            }`}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 36.4167C28.619 36.4167 36.4167 28.619 36.4167 19C36.4167 9.38108 28.619 1.58337 19 1.58337C9.38104 1.58337 1.58333 9.38108 1.58333 19C1.58333 28.619 9.38104 36.4167 19 36.4167ZM13.1304 13.1304C13.7487 12.5121 14.7513 12.5121 15.3696 13.1304L19 16.7608L22.6304 13.1304C23.2487 12.5121 24.2513 12.5121 24.8696 13.1304C25.4879 13.7487 25.4879 14.7513 24.8696 15.3696L21.2392 19L24.8696 22.6304C25.4879 23.2487 25.4879 24.2513 24.8696 24.8696C24.2513 25.4879 23.2487 25.4879 22.6304 24.8696L19 21.2392L15.3696 24.8696C14.7513 25.4879 13.7487 25.4879 13.1304 24.8696C12.5121 24.2513 12.5121 23.2487 13.1304 22.6304L16.7608 19L13.1304 15.3696C12.5121 14.7513 12.5121 13.7487 13.1304 13.1304Z"
              fill="#FCFCFC"
            />
          </svg>
        )}

        <div className="activityDetails__top">
          <h2 className="activityDetails__title">
            {isIcon ? <img src={icon} alt="" /> : ""}
            {title}
          </h2>
        </div>
        <p
          className="activityDetails__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="activityDetails__bottom">
        <div className="activityDetails__price">
          <h4>
            €{currency(price?.toFixed(0))}
            <span>{isCountable && "/pers"}</span>
          </h4>
        </div>
        {activityData ? (
          !isCountable ? (
            <button className="btn selected" onClick={handleRemoveActivity}>
              Ajouté au devis
              {selected && <i className="tick-mark"></i>}
            </button>
          ) : (
            <div className="filterBar__counter">
              <button className="btn-minus" onClick={minusCount}></button>
              <span>{activityData.qty}</span>
              <button className="btn-add" onClick={addCount}></button>
            </div>
          )
        ) : (
          isButton && (
            <button className="btn" onClick={handleAddActivity}>
              {selected ? "Ajouté au devis" : "Ajouter au devis"}
              {selected && <i className="tick-mark"></i>}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;
