import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBlogPosts } from "../../actions/blogActions";
import BlogCard from "../../components/BlogCard";
import Loading from "../../components/Loading";
// import posts from "../../utils/posts.json";
import "./Blog.css";

const Blog = () => {
  const dispatch = useDispatch();
  const { posts, error, loading } = useSelector((state) => state.blog);
  const postFeatured = !loading && posts.find((post) => post?.featured);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(getBlogPosts());
  }, [dispatch]);

  return (
    <div className="blog container">
      {loading ? (
        <div className="loading-container-text h-full">
          <Loading />
          S'il vous plaît, attendez...
        </div>
      ) : error ? (
        <div className="loading-container-text h-full">
          Il y a une erreur
        </div>
      ) : (
        <div className="blog__container">
          <>
            {postFeatured && <BlogCard post={postFeatured} featured />}
            {posts.map(
              (post, index) =>
                !post.featured && <BlogCard post={post} key={index} layoutType="column" />
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default Blog;
