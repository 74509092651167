import Hero from "../../components/Hero";
import "./Cgv.css";

const Cgv = () => {
  return (
    <div className="cgv">
      <Hero
        heroImg=""
        backgroundColor="primary"
        model="Model 2"
        loadingSkeleton={false}
        title="CONDITIONS GÉNÉRALES DE VENTE"
        headText="Date de dernière mise à jour : 30 Août 2022"
      />
      <div className="container cgv__content">
        <p>
          <h2>Article 1 – Préambule</h2>
          <br />
          <h3>
            <i>Article 1.1. Désignation du vendeur</i>
          </h3>
          <br />
          <b>Maiden Agency</b> société par actions simplifié enregistrée au RCS
          de Paris sous le numéro 894 747 880, dont le siège social est 152
          Boulevard Haussmann 75008 Paris. <br />
          Téléphone : <a href="tel:+33 6 98 42 19">+33 6 98 42 19 </a>
          <br />
          Adresse mail : &nbsp;
          <a href="mailto:contact@maiden-agency.com">
            contact@maiden-agency.com
          </a>
          <br />
          Représentant légal : Marie ROPERT en sa qualité de Présidente <br />
          Immatriculation au registre des opérateurs de voyage et de séjours
          :&nbsp;
          <b>IM075220005</b> <br />
          Garant financier : <b>APST, 15 avenue Carnot, 750177 - Paris</b>&nbsp;
          <br />
          Assureur responsabilité civile professionnelle :&nbsp;
          <b>Generali, 2 rue Pillet-Will, 75009 - Paris</b> <br />
          Adresse du site internet de vente :&nbsp;
          <a href="www.maiden-agency.com" target="_blank" rel="noreferrer">
            www.maiden-agency.com
          </a>
          <br />
          <br />
          <h3>
            <i>Article 1.2. Objet</i>
          </h3>
          <br />
          Les présentes Conditions Générales de Vente (ci-après « CGV ») ont
          pour objet de définir les droits et obligations des parties dans le
          cadre de la commercialisation par Maiden Agency de Prestations
          fournies directement par elle ou par des prestataires partenaires, à
          destination de personnes ayant la qualité de consommateurs ou
          non-professionnels au sens du code de la consommation ou de voyageur
          au sens du code du tourisme et ayant la capacité juridique de
          contracter (ci-après dénommé « le(s) Client(s) »). <br />
          <br />
          <h3>
            <i>Article 1.3. Définitions</i>
          </h3>
          <br />
          Pour les besoins des présentes CGV, les termes et expressions
          ci-dessous auront le sens qui leur est attribué́ lorsqu’ils sont
          utilisés avec une majuscule, qu’ils soient utilisés au singulier ou au
          pluriel :
          <br />
          <br />
          <u>Client</u> : personne physique ayant la qualité de consommateur ou
          de non-professionnel au sens du code de la consommation, ou de
          voyageur au sens du code du tourisme, qui contracte avec Maiden Agency
          dans le cadre des présentes CGV. <br /> <br />
          <u>Prestation </u> : service de voyage ou forfait touristique au sens
          de l’article L. 211-1 du code du tourisme. <br /> <br />
          <u>Partenaire </u> : tiers prestataire de services, notamment et sans
          que cette liste ne soit exhaustive, tous les fournisseurs des
          Prestations, à savoir : hôtels, loueurs de meublés de tourisme,
          prestataires fournissant les activités proposées au titre des options
          choisies par le Client, agences de voyages partenaires et réceptifs
          locaux. <br /> <br />
          <u>Contrat en ligne </u> : contrat conclu dans le cadre d’achat de
          Prestations sur le site Internet de Maiden Agency. <br /> <br />
          <u>Contrat à distance </u> : tout contrat conclu entre Maiden Agency
          et un consommateur, dans le cadre d'un système organisé de vente ou de
          prestation de services à distance, sans la présence physique
          simultanée du professionnel et du consommateur, par le recours
          exclusif à une ou plusieurs techniques de communication à distance
          jusqu'à la conclusion du contrat, à l’exclusion du site Internet de
          Maiden Agency.
          <br /> <br />
          <u>Support durable </u> : tout instrument permettant au consommateur
          ou à Maiden Agency de stocker des informations qui lui sont adressées
          personnellement afin de pouvoir s'y reporter ultérieurement pendant un
          laps de temps adapté aux fins auxquelles les informations sont
          destinées et qui permet la reproduction à l'identique des informations
          stockées (article L. 121-16 du code de la consommation).
          <br /> <br />
          <h2>Article 2 – Contenu et champ d'application</h2>
          <br />
          Les présentes CGV s'appliquent de plein droit à l’ensemble des
          Prestations vendues ou offertes à la vente par Maiden Agency et
          s'appliquent pour les ventes réalisées par tous circuits de
          distribution et de commercialisation. Toute commande ou achat implique
          l'adhésion sans réserve aux présentes CGV qui prévalent sur toutes
          autres conditions, à l'exception de celles qui ont été acceptées
          expressément par Maiden Agency et figurent sur le contrat de
          réservation.
          <br />
          <br />
          Le Client déclare avoir pris connaissance des présentes CGV et les
          avoir acceptées avant sa réservation et la conclusion du contrat.
          <br />
          <br />
          <h2>Article 3 – Informations précontractuelles</h2>
          <br />
          Le Client reconnait avoir la capacité de conclure le contrat. <br />
          <br />
          Le Client reconnaît avoir eu communication, préalablement à la
          passation de sa commande et/ou à la conclusion du contrat, d'une
          manière lisible et compréhensible, des présentes conditions générales
          de vente et de toutes les informations listées à l'article L. 221-5 du
          code de la consommation ainsi qu’à l’article R. 211-4 du code du
          tourisme.
          <br />
          <br />
          Le Client reconnaît de plus avoir eu communication du formulaire pris
          en application de l’arrêté du 1er mars 2018&nbsp;
          <i>
            « fixant le modèle de formulaire d'information pour la vente de
            voyages et de séjours »
          </i>
          .
          <br />
          <br />
          <h2>Article 4 – Description des Prestations proposées</h2>
          <br />
          L’objectif de Maiden Agency est de permettre à un groupe donné de
          Clients, comprenant 2 à 40 personnes, de réserver des Prestations sur
          son Site afin d’effectuer un séjour entre ami(e)s en France ou à̀
          l’étranger pour un évènement tel que : enterrement de vie de jeunes
          filles/garçons (ci-après <i>« EVJF »</i>), baby shower, anniversaire.
          Pour un évènement donné, il faut donc distinguer au sein du groupe de
          Clients concerné, celui ou ceux des Clients qui sont en charge de
          l’organisation de l’évènement et qui est (sont) donc le(s) point(s) de
          contact privilégié́(s) (ci-après <i>« l’Organisateur »</i>) de Maiden
          Agency et les autres Clients participants à l’évènement (ci-après
          les&nbsp;
          <i>« Participant(e)s »</i>).
          <br />
          <br />
          <h3>
            <i>Article 4.1 Prestations d’hébergement</i>
          </h3>
          <br />
          Des « packs » sont proposés par Maiden Agency comprenant une ou
          plusieurs nuitées dans la ville de destination choisie. Lors de la
          réservation, l’Organisateur précise le type d’hébergement souhaité à
          Maiden Agency dans le formulaire de réservation.
          <br />
          <br />
          <b>Pour les hôtels :</b> L’indication de niveau de confort attribué
          aux hôtels figurant dans le descriptif consultable sur le Site
          correspond à une classification établie en référence aux normes
          locales du pays de la Destination. Il peut donc différer des normes
          françaises. Il peut advenir que, dans des cas circonstances
          exceptionnelles et inévitables ou du fait d’un tiers, un autre hôtel
          de même catégorie, proposant des prestations équivalentes, soit
          substitué à l’hôtel initialement réservé́. <br />
          <br />
          <b>Pour les appartements et villas :</b> La plupart de ces
          hébergements sont soumis à une condition de réservation d’au moins 2
          nuitées. Pour rappel, la capacité d’accueil d’un appartement ou d’une
          villa est définie par son nombre de lits disponibles et non le nombre
          de chambre.
          <br />
          <br />
          <h3>
            <i>Article 4.3 Autres Prestations et options</i>
          </h3>
          <br />
          Des options peuvent être choisies lors de la phase de
          réservation.&nbsp;
          <br />
          <br />
          Il peut advenir que certaines options soient supprimées notamment pour
          des raisons climatiques ou lorsque le nombre de participants requis
          pour la réalisation de l’activité n’est pas atteint. Dans cette
          hypothèse, Maiden Agency fera son possible pour proposer aux Clients
          des Prestations de remplacement sans que des frais supplémentaires
          puissent être mis à la charge des Clients. <br />
          <br />
          De façon générale, il est précisé que les photographies et
          illustrations figurant sur le Site et accompagnant le descriptif des
          Prestations n’ont pas de valeur contractuelle et ne sauraient donc
          engager la responsabilité de Maiden Agency. Elles ont pour objet de
          donner aux Clients un aperçu des Prestations proposées.
          <br />
          <br />
          <h3>
            <i>Article 4.4 Prestations particulières</i>
          </h3>
          <br />
          Dans la limite de ses possibilités, Maiden Agency peut proposer des
          Prestations particulières à la demande spécifique des Clients. Ces
          Prestations particulières ne peuvent concerner que la Prestation
          d’hébergement et/ou les options, les Clients faisant leur affaire des
          prestations de transport.
          <br />
          <br />
          Tout demande de Prestation particulière doit faire l’objet d’une
          demande spécifique sur le formulaire de réservation. Maiden Agency y
          fera suite dans la limite de ses compétences et de ses possibilités et
          proposera aux Clients et Participants concernés un devis spécifique
          correspondant.
          <br />
          <br />
          <h2>Article 4 - Prix</h2>
          <br />
          <h3>
            <i>Article 4.1. Prix définitif et taxes additionnelles</i>
          </h3>
          <br />
          Le prix définitif est annoncé en euros, toutes taxes comprises (TTC)
          sous forme de forfait pour l’entièreté du groupe. Il est calculé en
          fonction du nombre de Participants. <br />
          <br />
          Le prix comprend les éléments indiqués au contrat. Il inclut le prix
          de l’hébergement, les Prestations choisies et les frais de dossier. Il
          est rappelé que le prix des options s’entend en supplément du prix de
          l’hébergement et qu’il est calculé et indiqué sur la base d’un nombre
          de Participants donné. <br />
          <br />
          Sauf mention au contrat, il ne comprend pas les frais de livraison des
          documents de voyages, le transport sur place, les frais de room
          service, les frais de téléphonie, les repas et boissons qui ne sont
          pas pris en compte dans le devis, les pourboires, les assurances
          facultatives ni les dépenses personnelles. De manière générale, tout
          ce qui n’est pas visé dans le devis n’est pas inclut dans le
          prix.&nbsp;
          <br />
          <br />
          Maiden Agency peut proposer, à des périodes de départ déterminées et
          pendant une durée limitée, des offres promotionnelles dites « Offres
          du moment ». L’offre publiée mentionne de manière claire et
          compréhensible sa nature, la catégorie de produits concernée, sa
          valeur, sa période d’application et la durée de l’opération
          promotionnelle. <br />
          <br />
          <h3>
            <i>Article 4.2. Modalités de paiement</i>
          </h3>
          <br />
          Le Client garantit à Maiden Agency qu’il dispose des autorisations
          éventuellement nécessaires pour utiliser le mode de paiement choisi
          par lui, lors de la validation du contrat. Maiden Agency se réserve le
          droit de suspendre toute gestion de réservation et toute exécution des
          Prestations en cas de refus d’autorisation de paiement par carte
          bancaire de la part des organismes officiellement accrédités ou en cas
          de non-paiement de toute somme due au titre du contrat. <br />
          <br />
          Les paiements effectués par le Client ne seront considérés comme
          définitifs qu’après encaissement effectif des sommes dues par Maiden
          Agency. <br />
          <br />
          Le Client dispose de plusieurs moyens de paiement offrant une sécurité
          optimale parmi les suivantes, selon le type de prestation réservée,
          comme indiqué aux conditions particulières de vente : <br />
          <br />
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              a. par carte bancaire de crédit ou privative (carte bleue, carte
              Visa, Eurocard/Mastercard, American Express)
            </li>
            <li>b. par chèque bancaire, </li>
            <li>c. par virement (frais de virement à la charge du Client),</li>
          </ul>
          <br />
          <h2>Article 5 – Révision du prix</h2>
          <br />
          Maiden Agency s'engage à appliquer les tarifs en vigueur indiqués au
          moment de la réservation mais se réserve le droit de modifier
          unilatéralement ses prix sous conditions fixées au présent
          article.&nbsp;
          <br />
          <br />
          Conformément à l’article L. 211-12 du code du tourisme, le prix pourra
          ainsi être modifié à la hausse ou à la baisse après validation de la
          réservation pour prendre en compte l’évolution : <br />
          <br />
          1° Du prix du transport de passagers résultant du coût du carburant ou
          d'autres sources d'énergie ; <br />
          <br />
          2° Du niveau des taxes ou redevances sur les services de voyage
          compris dans le contrat, imposées par un tiers qui ne participe pas
          directement à l'exécution du contrat, y compris les taxes
          touristiques, les taxes d'atterrissage ou d'embarquement et de
          débarquement dans les ports et aéroports ; <br />
          <br />
          3° Des taux de change en rapport avec le contrat. <br />
          <br />
          L’application éventuelle d’une majoration de prix en application de
          l’alinéa précédent sera notifiée de manière claire et compréhensible
          au Client et assortie d’une justification et d’un calcul, sur support
          durable, au plus tard vingt jours avant le début des
          prestations.&nbsp;
          <br />
          <br />
          Réciproquement, le Client a le droit à une réduction de prix
          correspondant à toute baisse des coûts mentionnés aux 1°, 2° et 3°,
          qui intervient après la conclusion du contrat et avant le début du
          voyage ou du séjour. <br />
          <br />
          En cas de diminution du prix, Maiden Agency a le droit de déduire ses
          dépenses administratives réelles du remboursement dû au Client. A la
          demande du Client, Maiden Agency apporte la preuve de ces dépenses
          administratives. <br />
          <br />
          Si la majoration dépasse 8 % du prix total du forfait ou du service de
          voyage, le Client peut accepter la modification proposée, ou demander
          la résiliation du contrat sans payer de frais de résiliation et
          obtenir le remboursement de tous les paiements déjà effectués. <br />
          <br />
          <h2>Article 6 – Réservations et modalités de paiement</h2>
          <br />
          <h3>
            <i>Article 6.1. Réservation</i>
          </h3>
          <br />
          Maiden Agency propose un système de réservation en ligne et à distance
          hors ligne (par téléphone). Les informations figurant sur son site
          internet ne sont pas contractuelles mais seulement informatives.&nbsp;
          <br />
          <br />
          <b>En ligne :</b> L’Organisateur précise le type d’hébergement
          souhaité dans la liste déroulante du formulaire de réservation (villa,
          appartement, hôtel etc). Il coche les options qu’il souhaite
          réserver.&nbsp;
          <br />
          <br />
          <b>A distance (par téléphone) :</b> Après une prise de contact avec
          l’Organisateur et un point téléphonique sur ses besoins et envies,
          l’Organisateur précise le type d’hébergement souhaité. <br />
          <br />
          Maiden Agency envoie par mail un devis détaillé avec le contenu
          détaillé des prestations proposées et le prix calculé en fonction du
          nombre de Participants renseigné par l’Organisateur ou, le cas
          échéant, la taille maximale ou minimal du groupe permettant
          l’organisation de l’évènement. <br />
          <br />
          Il est demandé à l’Organisateur de confirmer à Maiden Agency, à la
          signature du devis le nombre exact de Participants. Pour valider sa
          réservation l’Organisateur doit retourner le devis signé et accompagné
          du règlement de l’acompte. <br />
          <br />
          <h3>
            <i>Article 6.2. Modalités de paiement</i>
          </h3>
          <br />
          <ul>
            <li>
              Pour les séjours comprenant la location d’un meublé de tourisme
              (villa ou appartement) :
            </li>
          </ul>
          <br />
          A la validation de leur réservation, les Clients devront de verser 50
          % d’acompte du montant global du devis. Ce règlement peut être
          effectué soit par paiement unique, soit effectué par chaque
          Participant à hauteur de sa part personnelle. <br />
          <br />
          Le reste du solde est à verser au plus tard 30 jours avant le
          départ.&nbsp;
          <br />
          <br />
          Un dépôt de garantie pourra être demandé soit par Maiden Agency, soit
          par l’hébergeur directement. Le montant et les modalités de paiement
          de ce dépôt sont précisées au Client avant la conclusion du contrat.
          Cette somme sera restituée au Client après la restitution des clefs
          par les Participants et dès lors qu’il aura été constaté que : <br />
          <br />
          <ul className="cgv__listChecklist">
            <li>aucun meuble, objet ou linge n’est absent ou dégradé ;</li>
            <li>l’hébergement n’a subi aucune dégradation. </li>
          </ul>
          <br />
          <ul>
            <li>
              Pour les évènements ne comprenant pas de location d’un meublé de
              tourisme :
            </li>
          </ul>
          <br />
          A la validation de leur réservation, les Clients devront verser 30%
          d’acompte du montant global du devis. Ce règlement peut être effectué
          soit par paiement unique, soit effectué par chaque Participant à
          hauteur de sa part personnelle. <br />
          <br />
          Le reste du solde est à verser au plus tard 14 jours avant
          l’évènement. <br />
          <br />
          Si un Participant paye le prix pour d’autres Participants en sus de sa
          part, il se porte fort que le(s) Participant(s) pour le(s) quel(s) il
          paye se voi(ent) communiquer les informations nécessaires au titre des
          présentes. La confirmation de la réservation n’est effective que
          lorsque le prix des Prestations a été payé pour l’ensemble des
          Participants. <br />
          <br />
          La confirmation adressée par Maiden Agency reprend les éléments
          essentiels de la réservation, tels que l’identification des
          Prestations réservées, le prix payé et, le cas échéant, les modalités
          de paiement spécifiques applicables. Elle sera transmise au Client,
          par courrier électronique dans les meilleurs délais. Tout défaut de
          paiement (paiement irrégulier, incomplet ou inexistant) de la part des
          Clients, qu’il soit total ou partiel, entraînera la suspension de
          l’exécution des Prestations, les frais en découlant étant à la charge
          des Clients, sans préjudice de toute action pouvant être entreprise à
          leur encontre. <br />
          <br />
          <h2>Article 7 – Absence de droit de rétractation</h2>
          <br />
          L’article L. 221-28 du code de la consommation dispose que le droit de
          rétractation ne peut être exercé pour les contrats de prestations de
          services d’hébergement, autres que d’hébergement résidentiel, de
          services de transport de biens, de locations de voiture, de
          restauration ou d’activités de loisirs qui doivent être fournis à une
          date ou à une période déterminée. L’article L. 221-2 du code de la
          consommation exclut également cette faculté pour les transports de
          personnes et les forfaits touristiques. <br />
          <br />
          Maiden Agency se prévaut de cette absence de droit de rétractation et
          indique que pour toutes les prestations entrant dans le champ
          d’application de l’article L. 221-28 ou L. 221-2 du code de la
          consommation,&nbsp;
          <b>le Client ne disposera d’aucun droit de rétractation.</b> <br />
          <br />
          <h2>Article 8 – Modification du contrat </h2>
          <br />
          <h3>
            <i>Article 8.1. Modification à l’initiative de Maiden Agency</i>
          </h3>
          <br />
          Maiden Agency a la possibilité de modifier unilatéralement les clauses
          du Contrat après sa conclusion et avant le début des Prestation, et ce
          sens que le Client ne puisse s’y opposer, sous réserve que la
          modification soit mineure et que le Client en soit informé le plus
          rapidement possible de manière claire, compréhensible et apparente sur
          un support durable. <br />
          <br />
          Si Maiden Agency est contraint de modifier unilatéralement une des
          caractéristiques principales du contrat au sens de l’article R. 211-4
          du code du tourisme, qu’il ne peut satisfaire aux exigences
          particulières convenues avec le Client, ou en cas de hausse du prix
          supérieure à 8 %, il informe le Client dans les meilleurs délais,
          d'une manière claire, compréhensible et apparente, sur un support
          durable : <br />
          <br />
          <ul>
            <li>
              des modifications proposées et, s'il y a lieu, des répercussions
              sur le prix du voyage ou du séjour ;&nbsp;
            </li>
            <li>
              du délai raisonnable dans lequel le Client doit communiquer à
              Maiden Agency la décision qu'il prend ;
            </li>
            <li>
              des conséquences de l'absence de réponse du voyageur dans le délai
              fixé ;&nbsp;
            </li>
            <li>
              s'il y a lieu, de l'autre prestation proposée, ainsi que de son
              prix.
            </li>
          </ul>
          <br />
          Lorsque les modifications du Contrat ou la prestation de substitution
          entraînent une baisse de qualité du voyage ou du séjour ou de son
          coût, le Client a droit à une réduction de prix adéquate. <br />
          Si le contrat est résilié et que le Client n'accepte pas d'autre
          Prestation, Maiden Agency remboursera tous les paiements effectués par
          celui-ci ou en son nom dans les meilleurs délais, et au plus tard
          quatorze jours après la résiliation du contrat. <br /> <br />
          <h3>
            <i>Article 8.2 Modification à l’initiative du Client </i>
          </h3>
          <br />
          En cas de diminution du nombre initial de Participant énoncé dans le
          devis Maiden Agency ne procèdera à aucun remboursement ou diminution
          du prix du séjour. <br />
          <br />
          Si l’Organisateur souhaite ajouter un ou plusieurs Participants au
          séjour, il est tenu d’en faire la demande à Maiden Agency par écrit.
          En cas d’acceptation par Maiden Agency, le prix du séjour sera
          recalculé. Un avenant au contrat initial sera transmis à
          l’Organisateur pour acceptation. <br />
          <br />
          Tout séjour abrégé ou non consommé du fait du Client, ou commencé en
          retard du fait du Client ne donnera droit à aucun remboursement.&nbsp;
          <br />
          <br />
          Maiden Agency s’engage vis-à-vis du Client uniquement sur les
          prestations vendues. <br />
          <br />
          Ne sauraient engager la responsabilité de Maiden Agency : <br />
          <br />
          <ul>
            <li>
              toute prestation souscrite par le Client en dehors de celle
              facturée par Maiden Agency ;&nbsp;
            </li>
            <li>
              toute modification des prestations à l’initiative du Client.
            </li>
          </ul>
          <br />
          <h2>Article 9 – Résiliation du contrat</h2>
          <br />
          <h3>
            <i>Article 9.1. Résiliation du contrat par le Client</i>
          </h3>
          &nbsp;
          <br />
          Le Client a la possibilité de résilier le contrat à tout moment, avant
          le début de la prestation. Pour que cette résiliation soit valable, il
          doit en informer Maiden Agency par email ou par courrier. <br />
          &nbsp;
          <br />
          Maiden Agency demandera en ce cas au Client de payer des frais de
          résiliation et pourra les retenir tout ou partie des acomptes ou du
          solde déjà versés, selon l’échéancier d’annulation correspondant à la
          Prestation : <br />
          <br />
          <ul>
            <li>
              Pour les séjours comprenant la location d’un meublé de tourisme
              (villas ou appartement) :
            </li>
          </ul>
          <br />
          Annulation entre 90 et 60 jours avant le début du séjour : Retenue de
          50 % du montant global du séjour, <br />
          Annulation entre 60 et 30 jours avant le début du séjour : Retenue de
          75 % du montant global du séjour, <br />
          Annulation à moins de 30 jours avant le début du séjour : Retenue de
          100 % du montant global du séjour. <br />
          <br />
          <ul>
            <li>
              Pour les évènements ne comprenant pas de location de meublé de
              tourisme :&nbsp;
            </li>
          </ul>
          <br />
          Annulation entre 30 et 14 jours avant le début des prestations :
          Retenue de 30 % du montant global des prestations, <br />
          Annulation entre 14 et 7 jours avant le début des prestations :
          Retenue de 50 % du montant global des prestations, <br />
          Annulation à moins de 7 jours avant le début des prestations : Retenue
          de 100 % du montant global des prestations. <br />
          <br />
          Ces frais ne seront pas dus si le contrat est résilié à la suite de
          circonstances exceptionnelles et inévitables, survenant au lieu de
          destination ou à proximité immédiate de celui-ci et ayant des
          conséquences importantes sur l'exécution du contrat. Dans ce cas,
          Maiden Agency procèdera au remboursement intégral des paiements
          effectués, sans toutefois entraîner de dédommagement
          supplémentaire.&nbsp;
          <br />
          <br />
          La date d’annulation est la date de réception par Maiden Agency de la
          demande du Client, qui n’a pas à être motivée. <br />
          <br />
          <b>
            <u>
              Dispositions particulières relatives à la crise sanitaire du
              Covid-19 :
            </u>
          </b>
          <br />
          <br />
          En cas d’impossibilité de voyager en raison de mesures
          gouvernementales visant à lutter contre la pandémie de Covid en France
          ou dans le pays de destination du séjour (annulation de vol, fermeture
          des frontières), Maiden Agency se réserve le droit de proposer à
          l’Organisateur le remboursement ou le report du séjour en fonction des
          conditions d’annulations appliquées par certains des prestataires
          sollicités. Ces conditions seront transmises à l’Organisateur avant la
          conclusion du contrat. <br />
          <br />
          <h3>
            <i>Article 9.2. Résiliation du contrat par Maiden Agency</i>
          </h3>
          <br />
          Maiden Agency a la possibilité de résilier le contrat à tout moment,
          avant le début de la prestation. <br />
          <br />
          Le Client aura droit à une indemnisation supplémentaire, qui
          correspond à celle qu’il aurait dû payer si la résiliation du contrat
          était intervenue de son fait, dans le cadre de l’article 9.1 des
          présentes conditions générales de vente. <br />
          <br />
          Toutefois, Maiden Agency ne sera redevable d’aucune indemnisation
          supplémentaire, si la résiliation du contrat intervenant dans les deux
          cas suivants : <br />
          <br />
          1) Le nombre de personnes inscrites pour le voyage ou le séjour est
          inférieur au nombre minimal indiqué dans le contrat. Dans ce cas,
          Maiden Agency notifie par courriel ou par courrier la résiliation du
          contrat au Client dans le délai fixé par le contrat, selon le
          calendrier suivant <br /> <br />
          <ul>
            <li>
              vingt jours avant le début du voyage ou du séjour dans le cas de
              voyages dont la durée dépasse six jours ;
            </li>
            <li>
              sept jours avant le début du voyage ou du séjour dans le cas de
              voyages dont la durée est de deux à six jours ;
            </li>
            <li>
              quarante-huit heures avant le début du voyage ou du séjour dans le
              cas de voyages ne durant pas plus de deux jours ;
            </li>
          </ul>
          <br />
          2) Maiden Agency est empêché d'exécuter le contrat en raison de
          circonstances exceptionnelles et inévitables. Dans ce cas, Maiden
          Agency notifie par courriel ou par tout écrit la résiliation du
          contrat au voyageur dans les meilleurs délais avant le début du voyage
          ou du séjour.
          <br />
          <br />
          <h2>Article 10 – Cession du contrat</h2>
          <br />
          Le Client a la possibilité de céder son contrat à un cessionnaire qui
          remplit les mêmes conditions que lui pour bénéficier des prestations,
          tant que ce contrat n'a produit aucun effet. <br />
          <br />
          Le Client ne peut céder son contrat qu’à la condition d'informer
          Maiden Agency de sa décision par tout moyen permettant d'en obtenir un
          accusé de réception au plus tard sept jours avant le début du voyage.
          Cette cession n'est soumise, en aucun cas, à une autorisation
          préalable de Maiden Agency. <br />
          <br />
          Le Client cédant et le cessionnaire sont solidairement responsables du
          paiement du solde du prix et des frais supplémentaires éventuels que
          la cession pourrait engendrer. <br />
          <br />
          <h2>Article 11 – Garantie légale de conformité</h2>
          <br />
          Le Client doit communiquer à Maiden Agency les vices et/ou défauts de
          conformité dans les meilleurs délais à compter de la fourniture des
          services, conformément à l’article L. 211-16 II du code du tourisme.
          Cette communication doit se faire, pièces justificatives à l’appui, de
          préférence dans un délai de 7 jours suivant la fin des Prestations,
          afin que Maiden Agency puisse enquêter sur le trouble et apprécier la
          réalité des défauts allégués de façon efficace et dans l’intérêt des
          deux parties. <br />
          <br />
          Les défauts et/ou vices constatés donneront lieu à rectification,
          substitution, réduction de prix ou remboursement dans les meilleurs
          délais, compte tenu de l'importance de la non-conformité et de la
          valeur des services de voyage concernés. <br />
          <br />
          En cas de proposition de Maiden Agency d’une prestation de
          remplacement ou d’une réduction de prix, le voyageur ne peut refuser
          les autres prestations proposées que si elles ne sont pas comparables
          à ce qui avait été prévu dans le contrat ou si la réduction de prix
          octroyée n'est pas appropriée. <br />
          <br />
          La garantie de Maiden Agency est limitée au remboursement des services
          effectivement payés par le Client et Maiden Agency ne pourra être
          considéré comme responsable ni défaillant pour tout retard ou
          inexécution consécutif à la survenance de circonstances
          exceptionnelles ou inévitables. <br />
          <br />
          Conformément à l’article R 211-6, 4° du code du tourisme, le Client
          peut contacter rapidement Maiden Agency aux coordonnées figurant à
          l’article 1.1. « Désignation du vendeur » des présentes conditions
          générales de vente, afin de communiquer avec lui de manière efficace,
          demander une aide si le Client est en difficulté ou se plaindre de
          toute non-conformité constatée lors de l’exécution du voyage ou du
          séjour. <br />
          <br />
          <h2>Article 12 – Propriété intellectuelle</h2>
          <br />
          Le site internet de Maiden Agency, tous les éléments le constituant et
          tous les contenus du site (internet et réseaux sociaux), qu’ils soient
          visuels ou sonores, y compris la technologie sous-jacente, sont
          protégés par le droit d’auteur, des marques, des dessins et modèles ou
          des brevets. Le site et le compte Instagram de Maiden Agency ainsi que
          tous les éléments qu’ils contiennent (notamment, sans que cette liste
          soit limitative, les textes, logiciels, photographies, logos, données)
          sont la propriété exclusive de Maiden Agency, qui est titulaire de
          l’intégralité des droits de propriété intellectuelle y afférents.
          Toute représentation, reproduction ou commercialisation de ces
          contenus pour un usage autre que privé, exploitation, diffusion ou
          adaptation du Site ou de ses éléments est interdite. <br />
          <br />
          L’utilisation de tout logiciel téléchargé sur le site permettant
          d’accéder à certaines Prestations ou fonctionnalités est régie par les
          termes de la licence l’accompagnant. Le Client s’engage à ne pas
          installer, copier ou utiliser ce logiciel avant d’avoir préalablement
          acquiescé aux termes de ladite licence. Pour tout logiciel non
          accompagné d’une licence, il est conféré au Client un droit d’usage
          temporaire, privé, personnel, non transmissible et non exclusif sur ce
          logiciel afin de pouvoir, à l’exclusion de tout autre usage, accéder
          aux Prestations et fonctionnalités qui rendent l’utilisation de ce
          logiciel nécessaire. En installant, ou utilisant le logiciel, le
          Client s’engage à respecter ces conditions. <br />
          <br />
          Tout utilisateur qui dispose d’un site Internet à titre personnel et
          qui désire placer, pour un usage personnel, sur son site un lien
          simple renvoyant directement à la plage d’accueil du site de Maiden
          Agency, doit obligatoirement en demander l’autorisation à Maiden
          Agency. Il ne s’agira pas dans ce cas d’une convention implicite
          d’affiliation. <br />
          <br />
          Dans tous les cas, tout lien, même tacitement autorisé, devra être
          retiré sur simple demande de Maiden Agency. Toute utilisation sans
          droit qui serait faite de ces contenus serait constitutive de
          contrefaçon et susceptible de poursuites civiles ou pénales. <br />
          <br />
          <h2>Article 12 – Protection des données à caractère personnel</h2>
          <br />
          <h3>
            <i>Article 12.1. Données collectées</i>
          </h3>
          <br />
          Dans le cadre de son activité de vente de séjours et Prestations,
          Maiden Agency met en œuvre et exploite des traitements de données à
          caractère personnel relatifs aux Clients. <br />
          <br />
          A ce titre, Maiden Agency collecte les données à caractère personnel
          de l’Organisateur et des Participants telle que : prénom, nom,
          civilité, adresse postale, adresse courriel, numéro de téléphone,
          composition du groupe, particularités notées au contrat, modalités de
          paiement. <br />
          <br />
          <h3>
            <i>Article 12.2. But poursuivi</i>
          </h3>
          <br />
          La collecte de ces données personnelles est indispensable à
          l’exécution contractuelle et en cas de refus dus communiquer, le
          Client s’expose à des difficultés d’exécution de la prestation qui ne
          pourront donner lieu à l’engagement de la responsabilité de Maiden
          Agency. <br />
          <br />
          Ces données à caractère personnel sont collectées dans le but exclusif
          d’assurer la gestion de la clientèle de Maiden Agency dans le cadre de
          la conclusion du contrat et de son exécution, sur la base du
          consentement du Client. Elles ne sont utilisées que pour les finalités
          auxquelles le Client a consenti. <br />
          <br />
          Plus précisément, les finalités sont les suivantes : <br />
          <br />
          <ul>
            <li>
              Identification des personnes utilisant et/ou réservant les
              prestations
            </li>
            <li>Formalisation de la relation contractuelle</li>
            <li>
              Réalisation des prestations réservées auprès de Maiden Agency
            </li>
            <li>
              Gestion des contrats et réservation (notamment répartition des
              chambres, gestion des déplacements)
            </li>
            <li>
              Communication aux partenaires en vue de la réalisation des
              prestations par les partenaires concernés
            </li>
            <li>
              Comptabilité notamment gestion des comptes Clients et suivi de la
              relation Client
            </li>
            <li>Traitement des opérations relatives à la gestion Clients</li>
            <li>Communications commerciales et prospection, animation.</li>
          </ul>
          <br />
          <h3>
            <i>Article 12.3. Personnes autorisées à accéder aux données</i>
          </h3>
          <br />
          Les personnes autorisées à accéder aux données collectées au sein de
          Maiden Agency sont les suivantes : les salariés de Maiden Agency et
          ses partenaires intervenant sur les prestations sollicitées par le
          Client, et le cas échéant, les prestataires sous-traitants de Maiden
          Agency participant à la réalisation et/ou l'administration des
          prestations et étant amené à intervenir à ce titre sur les
          traitements, étant alors précisé qu'en pareille hypothèse, qu’il
          s’agisse de partenaires ou de sous-traitant, cela est effectué dans le
          respect de la réglementation en vigueur.
          <br />
          <br />
          <h3>
            <i>Article 12.4. Conservation des données</i>
          </h3>
          <br />
          Ces données à caractère personnel collectées sont conservées pendant
          la durée de conservation légale relative à la finalité du traitement
          et au plus pendant 5 ans. <br />
          <br />
          Les données à caractère personnel relatives à la carte bancaire du
          Client sont conservées exclusivement dans le délai nécessaire pour la
          réalisation de la transaction. <br />
          <br />
          Les données à caractère personnel relatives à un prospect qui ne
          conclurait pas de contrat de réservation avec Maiden Agency sont
          conservées pendant une durée de 3 ans à compter de leur
          collecte.&nbsp;
          <br />
          <br />
          Maiden Agency met en œuvre des mesures organisationnelles, techniques,
          logicielles et physiques en matière de sécurité du numérique pour
          protéger les données personnelles contre les altérations, destructions
          et accès non autorisés. Toutefois, il est à signaler qu'Internet n'est
          pas un environnement complètement sécurisé et Maiden Agency ne peut
          pas garantir la sécurité de la transmission ou du stockage des
          informations sur Internet. <br />
          <br />
          Maiden Agency a formalisé les droits et les obligations des Clients au
          regard du traitement de leurs données à caractère personnel au sein
          d’un document appelé Politique de confidentialité sur son site
          internet. <br />
          <br />
          <h3>
            <i>Article 12.5. Droits du titulaire des données collectées</i>
          </h3>
          <br />
          En application de la règlementation applicable en matière de données à
          caractère personnel, chaque utilisateur dispose d’un droit
          d’interrogation, d’accès, de modification, d’opposition et de
          rectification, pour des motifs légitimes, à la collecte et au
          traitement de ses données à caractère personnel. Il est possible de
          demander à ce que ces données soient rectifiées, complétées,
          clarifiées, mises à jour ou effacées. <br />
          <br />
          Ces droits peuvent être exercés en écrivant un courrier signé au
          responsable de traitement des données, à nom et courriel du
          responsable de traitement en joignant à votre demande une copie de
          votre pièce d’identité. <br />
          <br />
          Maiden Agency a désigné un Délégué à la Protection des Données (DPO) :
          Louise SAX (
          <a href="mailto:contact@maiden-agency.com">
            contact@maiden-agency.com
          </a>
          ). <br />À tout moment, le Client peut introduire une réclamation
          auprès de la CNIL selon les modalités indiquées sur son site Internet
          (
          <a href="https://www.cnil/fr" target="_blank" rel="noreferrer">
            https://www.cnil/fr
          </a>
          ). <br />
          <br />
          <h2>Article 14 – Assurances</h2>
          <br />
          L’assureur de Maiden Agency la garantie civile professionnelle
          garantit contre les conséquences pécuniaires de la responsabilité
          civile professionnelle telle qu’elle est exposée aux articles L.
          211-16 et L. 211-17 du code du tourisme. <br />
          <br />
          La garantie prend également en charge les dommages causés à des
          voyageurs, à des prestataires de services ou à des tiers par suite de
          fautes, erreurs de fait ou de droit, omissions ou négligences commises
          à l'occasion de l'offre, de l'organisation et de la vente de nos
          prestations tant de notre fait que du fait de nos préposés, salariés
          et non-salariés. <br />
          <br />
          Le Client s’engage à détenir et être à jour de son assurance de
          responsabilité civile pour couvrir les dommages qu’il pourrait causer
          et doit pouvoir la fournir à tout moment sur simple demande de Maiden
          Agency. <br />
          <br />
          <h2>Article 16 – Responsabilité de Maiden Agency</h2>
          <br />
          <h3>
            <i>Article 16.1 – Responsabilité de plein droit</i>
          </h3>
          <br />
          Maiden Agency est responsable de plein droit des Prestations
          touristiques contractées dans le cadre des présentes conditions
          générales de vente. <br />
          <br />
          Maiden Agency peut toutefois s’exonérer de tout ou partie de sa
          responsabilité en apportant la preuve que le dommage est imputable
          soit au Client, soit à un tiers étranger à la fourniture des services
          de voyage compris dans le contrat et revêt un caractère imprévisible
          ou inévitable, soit à des circonstances exceptionnelles et
          inévitables. <br />
          <br />
          Maiden Agency ainsi que le détaillant sont responsables de la bonne
          exécution de tous les services de voyage compris dans le contrat
          conformément à l'article L. 211-16. <br />
          <br />
          <h3>
            <i>Article 16.2. Limitation de la responsabilité</i>
          </h3>
          &nbsp;
          <br />
          Conformément à l’article L 211-17, IV du code du tourisme, le montant
          des éventuels dommages-intérêts que Maiden Agency serait condamné à
          verser au Client pour quelque cause que ce soit, sera limité à trois
          fois le prix total hors taxes des prestations, à l’exception des
          préjudices corporels et des dommages causés intentionnellement ou par
          négligence. <br />
          <br />
          <h2>Article 17 – Circonstances exceptionnelles et inévitables </h2>
          <br />
          Tout événement qui crée une situation échappant au contrôle de Maiden
          Agency comme du Client et dont les conséquences n’auraient pas pu être
          évitées même si toutes les mesures avaient été prises empêchant ainsi
          l'exécution dans des conditions normales de leurs obligations, sont
          considérées comme des causes d'exonération des obligations des parties
          et entraînent leur suspension. <br />
          <br />
          La partie qui invoque les circonstances visées ci-dessus doit avertir
          immédiatement l'autre partie de leur survenance, ainsi que de leur
          disparition. <br />
          <br />
          Les parties se rapprocheront pour examiner l'incidence de l'événement
          et convenir des conditions dans lesquelles l'exécution du contrat sera
          poursuivie. Si les circonstances exceptionnelles et inévitables ont
          une durée supérieure à trois mois, les présentes conditions générales
          pourront être résiliées par la partie lésée. <br /> <br />
          <h2>Article 18 – Aide au voyageur</h2>
          <br />
          Maiden Agency est responsable de la bonne exécution des Prestations
          prévues au contrat. Dans ce cadre, si le Client est confronté à des
          difficultés, Maiden Agency apportera dans les meilleurs délais une
          aide appropriée, eu égard aux circonstances de l’espèce. <br />
          <br />
          Maiden Agency sera en droit de facturer un prix raisonnable pour cette
          aide si cette difficulté est causée de façon intentionnelle par le
          Client ou par sa négligence. Le prix facturé ne dépassera pas les
          coûts réels supportés par Maiden Agency. <br />
          <br />
          <h2>Article 20 – Règlement des litiges</h2>
          <br />
          Les présentes conditions générales sont soumises à l'application du
          droit français. Il en est ainsi pour les règles de fond comme pour les
          règles de forme. <br />
          <br />
          Le Client peut recourir à une médiation conventionnelle, notamment
          auprès de la Commission de la médiation de la consommation ou auprès
          des instances de médiation sectorielles existantes, ou à tout mode
          alternatif de règlement des différends (conciliation, par exemple) en
          cas de contestation. <br />
          <br />
          Le Client peut ainsi saisir le Médiateur du Tourisme et du Voyage sur
          le site suivant :&nbsp;
          <a href="https://www.mtv.travel/" target="_blank" rel="noreferrer">
            https://www.mtv.travel/
          </a>
          &nbsp; ou à MTV Médiation tourisme voyage, BP 80 303 - 75 823 Paris
          Cedex 17 dans le cas où la réponse apportée par Maiden Agency au
          Client sur sa réclamation soit jugée insuffisante ou restée sans
          réponse au bout de 60 jours. <br />
          <br />
          Dans le cas où le service aurait été acheté en ligne par le Client, ce
          dernier est informé qu’il a la faculté, conformément à l’article 14.1
          du règlement (UE) n°524/2013 du parlement européen et du conseil du 21
          mai 2013, d’introduire une réclamation et de sélectionner un organisme
          de règlement des litiges sur le site internet suivant :&nbsp;
          <a
            href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR"
            target="_blank"
            rel="noreferrer"
          >
            https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR
          </a>
          . <br />
          <br />
          <h2>Article 21 – Prestations de voyage liées</h2>
          <br />
          Si, après avoir choisi un service de voyage et l'avoir payé, le Client
          réserve des services de voyage supplémentaires pour le séjour par
          l'intermédiaire de Maiden Agency, il NE bénéficiera PAS des droits
          applicables aux forfaits au titre de la directive (UE) 2015/2302 et de
          l'article L.211-2 du code du tourisme. <br />
          <br />
          Toutefois, si le Client réserve des services de voyage supplémentaires
          au cours de la même visite ou du même contact avec Maiden Agency, les
          services de voyage feront partie d'une prestation de voyage
          liée.&nbsp;
          <br />
          <br />
          Dans ce cas Maiden Agency dispose, comme l'exige le droit de l'Union
          européenne, d'une protection afin de rembourser les sommes que le
          Client lui a versées pour des services qui n'ont pas été exécutés en
          raison de son insolvabilité. <br />
          <br />
          Maiden Agency a souscrit une protection contre l'insolvabilité auprès
          de l’APST, 15 avenue Carnot, 750177 - Paris Le Client peut prendre
          contact avec cette entité si les services de voyage leur sont refusés
          en raison de l'insolvabilité de Maiden Agency. <br />
          <br />
          Remarque : cette protection contre l'insolvabilité ne s'applique pas
          aux contrats conclus avec des parties autres que Maiden Agency qui
          peuvent être exécutés en dépit de l'insolvabilité de Maiden
          Agency.&nbsp;
          <br />
          <br />
          [Site internet sur lequel on peut consulter la directive (UE)
          2015/2302 transposée en droit national :&nbsp;
          <a
            href="https://www.legifrance.gouv.fr/affichCodeArticle.do;jsessionid=B6B56671A51841699A8FB7B4B5EB08A2.tplgfr21s_1?idArticle=LEGIARTI000036242695&cidTexte=LEGITEXT000006074073&categorieLien=id&dateTexte=20180701"
            target="_blank"
            rel="noreferrer"
          >
            lien
          </a>
          . <br />
          <br />
          <h2>
            Article 22 – Informations administratives et sanitaires – séjour à
            l’étranger
          </h2>
          <br />
          Quels que soient la destination et les pays visités, l’Organisateur
          s’engage à vérifier que chacun des Participants (y compris les enfants
          et bébés) est en possession d’un passeport biométrique avec puce
          intégrée et photo numérique, valable au moins 6 mois après la date de
          retour du voyage ainsi que tout autre(s) document(s) (visa,
          autorisation ESTA etc.) nécessaires et conformes aux exigences
          requises pour transiter et/ou entrer dans le(s) pays de
          l’évènement.&nbsp;
          <br />
          <br />
          Attention, Maiden Agency délivre ces informations pour tous les
          ressortissants de nationalité française. Il appartient à chaque
          participant de vérifier que ses documents, notamment administratifs et
          sanitaires, requis en vue de l'accomplissement du séjour, sont en
          conformité avec les informations fournies par Maiden Agency, étant
          précisé qu’il incombe à la personne qui a conclu le contrat de voyage
          de relayer les informations relatives aux formalités à chacun des
          participants inscrits au séjour. Maiden Agency ne pourra, en aucun
          cas, être tenue responsable des conséquences de l'inobservation par
          chaque voyageur des règlements policiers, douaniers&nbsp;
          <b>
            <u>ou sanitaires.</u>
          </b>
          &nbsp;
          <br />
          <br />
          Les ressortissants étrangers sont invités à consulter leur autorité
          consulaire. <br />
          <br />
          Pour les formalités et informations du ou des pays du séjour, Maiden
          Agency vous conseille de consulter les fiches des pays visités durant
          le séjour, disponibles sur le site du Ministère de l’Europe et des
          affaires étrangères (MEAE) www.diplomatie.gouv.fr, rubrique “Conseils
          aux Voyageurs/Conseils par pays”. Maiden Agency attire l’attention sur
          le fait que les informations peuvent évoluer jusqu'à la date du départ
          et conseille aux Participants de les consulter régulièrement. Maiden
          Agency peut être amené, pour certaines destinations, à faire signer la
          fiche MEAE du/des pays visité(s) ou traversé(s), au titre de son
          obligation d’information. Cette demande ne constitue pas une décharge
          de responsabilité. <br />
          <br />
          Maiden Agency délivre les informations sanitaires disponibles au
          moment de la conclusion du contrat. Il est recommandé de consulter
          régulièrement les informations diffusées par les autorités compétentes
          sur les risques sanitaires du/des pays de votre voyage et à suivre les
          recommandations et mesures sanitaires pour lutter contre ces risques
          accessibles sur les sites&nbsp;
          <a
            href="http://solidarites-sante.gouv.fr"
            target="_blank"
            rel="noreferrer"
          >
            http://solidarites-sante.gouv.fr
          </a>
          &nbsp;(Ministère français des Solidarités et de la Santé) ou&nbsp;
          <a href="www.who.int/fr" target="_blank" rel="noreferrer">
            www.who.int/fr
          </a>
          (Organisation Mondiale de la Santé). <br />
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <b>***</b>
            <b>CONDITIONS PARTICULIERES DE VENTE</b>
            <b>
              <i>Boutique</i>
            </b>
          </div>
          <br />
          <br />
          Les présentes conditions particulières de vente s’appliquent à la
          vente d’accessoires dans la boutique en ligne de Maiden Agency
          accessible sur son site internet&nbsp;
          <a href="www.maiden-agency.com" target="_blank" rel="noreferrer">
            www.maiden-agency.com
          </a>
          . Elles priment sur les conditions générales de vente pour les points
          abordés. <br />
          <br />
          Il est précisé que tout achat sur la boutique en ligne n’est pas
          soumis aux dispositions du Code du tourisme. <br />
          <br />
          <h2>Article 1 – Prix</h2>
          <br />
          Les prix affichés sont en euros TTC. Ces prix ne comprennent pas les
          frais d’expédition (emballage, frais postaux). <br />
          <br />
          <h2>Article 2 – Commande </h2>
          <br />
          La commande de produits proposés sur la boutique en ligne peut être
          fait en complément de la réservation du séjour ou seule. <br />
          <br />
          Le Client sélectionne le produit qu’il souhaite acheter et la quantité
          souhaitée et clique sur commander. <br />
          <br />
          La commande est transmise aux équipes de Maiden Agency. Un mail de
          confirmation de commande contenant un devis, les présentes conditions
          générales et particulières de vente et le lien de paiement et un RIB
          sera alors envoyé sur l’adresse mail que le Client a communiqué
          préalablement à Maiden Agency. <br />
          <br />
          <h2>Article 3 – Paiement</h2>
          Le Client peut effectuer son paiement via le lien de paiement ou par
          virement bancaire. Toutes les informations relatives au paiement sont
          indiquées dans le mail de confirmation de commande. <br />
          <br />
          <h2>Article 4 – Disponibilité des produits de la boutique</h2>
          <br />
          Les produits de la boutique sont proposés dans la limite des stocks
          disponibles. Pour les produits non stockés, les offres sont valables
          sous réserve de disponibilité chez nos fournisseurs et
          prestataires.&nbsp;
          <br />
          <br />
          En cas d’indisponibilité de produit après passation de la commande par
          le Client, Maiden Agency en informera le Client par courrier
          électronique ou téléphone. Le Client pourra alors demander un échange
          ou l’annulation de sa commande par courrier électronique adressé à
          Maiden Agency à l’adresse suivante:&nbsp;
          <a href="mailto:contact@maiden-agency.com">
            contact@maiden-agency.com
          </a>
          . <br />
          <br />
          <h2>Article 5 – Livraison de la commande et frais d’expédition</h2>
          &nbsp;
          <br />
          <h3>
            <i>5.1. Mode d’expédition</i>
          </h3>
          <br />
          Les produits peuvent être remis en main propre à Paris sur demande par
          mail du Client lors de la passation de la commande. <br />
          <br />
          Sinon les produits seront livrés à l’adresse de livraison que vous
          indiquée au cours du processus de commande. Les délais de livraison
          sont donnés à titre indicatif et ne sont pas contractuels. Les colis
          sont généralement expédiés en 72h, jours ouvrés, après réception du
          paiement. Le mode d’expédition standard est le colis via&nbsp;
          <span className="cgv-highlight">Mondial Relay</span>, remis avec
          signature. Le point relais le plus proche du domicile du Client sera
          choisi par Maiden Agency par défaut. Les délais de livraison sont ceux
          du prestataire et tout dépassement de ces délais ne pourra donner lieu
          au paiement d’indemnités, dommages ou à l’annulation de la
          commande.&nbsp;
          <br />
          <br />
          Les frais d’expédition comprennent l’emballage, la manutention et les
          frais postaux. Ils peuvent contenir une partie fixe et une partie
          variable en fonction du prix ou du poids de votre commande. Nous vous
          conseillons de regrouper vos achats en une unique commande. Nous ne
          pouvons pas regrouper deux commandes réglées de façon distincte et
          vous devrez vous acquitter des frais de port pour chacune d’entre
          elles. Votre colis est expédié à vos propres risques, un soin
          particulier est apporté au colis contenant des articles
          fragiles.&nbsp;
          <br />
          <br />
          <h3>
            <i>5.2. Droits de douane</i>
          </h3>
          <br />
          En cas de commande vers un pays autre que la France métropolitaine, le
          Client est l’importateur des produits concernés. Des droits de douanes
          ou autres taxes locales ou droits d’importation ou taxe d’Etat sont
          susceptibles d’être exigibles. Ces droits et sommes ne relèvent pas du
          ressort de Maiden Agency. Ils seront à la charge unique du Client et
          relèvent de sa responsabilité tant en termes de déclarations que de
          paiements aux autorités et/ou organismes compétents du pays du Client.
          Le Client doit se renseigner auprès de ses autorités locales. <br />
          <br />
          <h2>Article 5 – Paiement </h2>
          <br />
          Pour tous les achats contractés sur notre boutique en ligne, le
          règlement est effectué au choix du Client : par carte bancaire ou par
          virement. Pour toute commande et paiement en provenance de l’étranger,
          les frais éventuels seront à la charge du Client. <br />
          <br />
          <h2>Article 6 – Délai de rétractation</h2>
          <br />
          Le Client dispose conformément aux dispositions de l’article L. 221-18
          du Code de la consommation, d’un délai de rétractation de 14 jours à
          compter de la réception du (ou des) produit(s) de la boutique, sans
          avoir à justifier de motifs, ni à payer de pénalités, à l’exception
          des frais de retour qui resteront à la charge du Client. <br />
          <br />
          Le Client informe Maiden Agency de sa décision de rétractation en lui
          adressant, avant l’expiration du délai prévu à l’article L.121-18 du
          Code de la consommation, une déclaration de rétractation, dénuée
          d’ambiguïté, exprimant sa volonté de se rétracter. <br />
          <br />
          Tout retour effectué conformément aux conditions du présent article
          donnera lieu à un remboursement dans les 14 jours à compter de la
          réception du produit en parfait état. <br />
          <br />
          Maiden Agency n’est pas tenu de rembourser les frais supplémentaires
          si le Client a expressément choisi un mode de livraison plus coûteux
          que le mode de livraison standard proposé par Maiden Agency. <br />
          <br />
          <b>
            Aucun droit de rétractation n’est possible pour les biens
            susceptibles de se détériorer ou de se périmer rapidement, ou qui ne
            peuvent être renvoyés pour des raisons d’hygiène ou de protection de
            la santé, ou les produits audio, vidéo ou logiciel ayant été
            descellés par le Client.
          </b>
          &nbsp;
          <br />
          <br />
          <h2>Article 7 – Retour du produit </h2>
          <br />
          En cas de livraison d’article reconnu comme défectueux ou non conforme
          à la commande, le Client doit formuler sa réclamation dans un délai de
          7 jours. La responsabilité de Maiden Agency se limite au prix payé
          pour la commande. <br />
          <br />
          Maiden Agency est tenu de reprendre un produit en cas de livraison non
          conforme à la commande ou défectueuse. Une preuve de l'objet abîmé
          (photo) devra être fournie à Maiden Agency pour valider le
          remboursement ou l'échange. Les remboursements se feront uniquement
          par virement après envoi d'un RIB. <br />
          <br />
          Il pourra alors être procédé à un remboursement, frais de retour
          inclus, ou un échange selon le souhait du Client. <br />
          <br />
          En cas de commande vers un pays autre que l’union européenne, les
          droits de douanes et taxes seront à la charge du Client et relèvent de
          son entière responsabilité en termes de déclaration et paiement aux
          autorités. Les articles proposés sont conformes à la législation
          française en vigueur. La responsabilité de Maiden Agency ne saurait
          être engagée en cas de non-respect de la législation du pays où les
          produits sont livrés. Il vous appartient de vérifier auprès des
          autorités locales les possibilités d’importation ou d’utilisation des
          produits ou services que vous envisagez de commander. <br />
          <br />
          <h2>Article 8 – Responsabilité</h2>
          <br />
          Maiden Agency qui offre à un Client des prestations, est l’unique
          interlocuteur de ce Client et répond devant lui de l’exécution des
          obligations découlant des présentes conditions de vente. Maiden Agency
          ne saurait être tenu responsable de l’inexécution d’une commande en
          cas de rupture de stock ou indisponibilité d’articles, de force
          majeure, de perturbation des transports, ou grève totale ou partielle
          et notamment des services postaux pour quelques raisons que ce soit,
          d’incendies ou inondations. <br />
          <br />
          Toute réclamation relative à l’inexécution ou à la mauvaise exécution
          du contrat doit être adressée par écrit à Maiden Agency de préférence
          sous 8 jours. <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default Cgv;
