import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { filterVilla } from "../../../actions/villaActions";
import SearchBar from "../../SearchBar";
import HERO from "../../../assets/hero/home_page.jpg";

import "./HomeHero.css";

const HomeHero = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dataRequest } = useSelector((state) => state.request);
  const [isSuggestions, setIsSuggestions] = useState(false);
  const { filters } = useSelector((state) => state.villaFilter);
  const [values, setValues] = useState({
    where: "",
    when: "",
    until: "",
    addGuest: "",
  });

  const handleSearch = (e) => {
    e.preventDefault();
    if (filters.invites > 1) {
      dispatch(filterVilla(filters));
    }
    history.push(`/destinations/${values.where}`);
  };

  const handleInputValue = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.name === "budget"
          ? e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
          : e.target.value,
    });

    setIsSuggestions(false);
    if (e.target.name === "where") {
      setIsSuggestions(true);
    }
  };

  const handleRemoveValue = (type) => {
    setValues({
      ...values,
      [type]: "",
    });
  };

  useEffect(() => {
    document.onclick = (e) => {
      if (e.target.closest(`where-${Date.now()}`) === null) {
        setIsSuggestions(false);
      }
    };
  }, []);

  return (
    <section className="homeHero">
      <div className="homeHero__imgContainer">
        <img src={HERO} alt="" />
      </div>
      <div className="homeHero__content">
        <h1 className="homeHero__title">
          Votre EVJF, <strong>en mieux.</strong>
        </h1>
        <SearchBar
          values={values}
          handleInputValue={handleInputValue}
          handleRemoveValue={handleRemoveValue}
          handleSearch={handleSearch}
          dataRequest={dataRequest}
          isSuggestions={isSuggestions}
        />
        <a href="#explore">
          <svg
            className="homeHero__scrollButtom"
            width="28"
            height="17"
            viewBox="0 0 28 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.121 2.5L13.9354 14.6856L1.74987 2.5"
              stroke="#FCFCFC"
              strokeWidth="3.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
    </section>
  );
};

export default HomeHero;
