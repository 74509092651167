import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { blogReducers } from "./reducers/blogReducers";
import moment from "moment";
import {
  destinationSuggestionReducers,
  destinationReducers,
} from "./reducers/destinationReducers";
import { villaFilterReducers, villaReducers } from "./reducers/villaReducers";
import { activityReducers } from "./reducers/activityReducers";
import thunk from "redux-thunk";
import {
  coffretGoodiesReducer,
  coffretPaniersReducer,
  coffretReducers,
  selectedCoffretReducer,
} from "./reducers/coffretReducers";
import {
  requestReducers,
  makeRequestReducer,
} from "./reducers/requestReducers";
import { quizzAnswerReducers, quizzReducers } from "./reducers/quizzReducers";
import {
  messageRoomsReducers,
  messageRoomReducers,
} from "./reducers/messageReducers";
import { faqReducer } from "./reducers/faqReducers";
import { updateQuoteReducer } from "./reducers/quoteReducers";

let filterVillas = {
  invites: 1,
  chambers: 1,
  bathroom: 1,
  arounds: [],
  equipments: [],
  healties: [],
  properties: [],
  destinationId: "",
};

const initalState = {
  request: {
    dataRequest: localStorage.getItem("maiden_data")
      ? JSON.parse(localStorage.getItem("maiden_data"))
      : {
          date_from: moment()._d,
          date_to: moment().add(1, "day")._d,
          guests: 1,
          destination_id: null,
          property: null,
          activities: [],
          decoration: {},
          products: [],
          bundles: [],
          delivery: "",
          tag: "original",
          offer_id: null,
        },
  },
  createRequest: {
    user: localStorage.getItem("maiden_user")
      ? JSON.parse(localStorage.getItem("maiden_user"))
      : null,
  },
  villaFilter: {
    filters: localStorage.getItem("maiden_filter")
      ? {
          ...filterVillas,
          destinationId: JSON.parse(localStorage.getItem("maiden_filter"))
            .destinationId,
        }
      : filterVillas,
  },
};

const reducer = combineReducers({
  blog: blogReducers,
  destination: destinationReducers,
  villa: villaReducers,
  villaFilter: villaFilterReducers,
  activity: activityReducers,
  coffretGoodies: coffretGoodiesReducer,
  coffretPaniers: coffretPaniersReducer,
  coffret: coffretReducers,
  request: requestReducers,
  createRequest: makeRequestReducer,
  quizz: quizzReducers,
  quizzAnswer: quizzAnswerReducers,
  rooms: messageRoomsReducers,
  room: messageRoomReducers,
  faq: faqReducer,
  suggestion: destinationSuggestionReducers,
  updateQuote: updateQuoteReducer,
  selectedCoffret: selectedCoffretReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initalState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
