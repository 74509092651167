import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setRequest } from "../../../actions/requestActions";
import InputLabelFloat from "../../InputLabelFloat";
import { selectBoxes, selectServices } from "../../../utils/selectBox";
import InfoIcon from "../../../assets/info_icon.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./DecorationsServices.css";

const DecorationsServices = ({ dataRequest, destination }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [decoration, setDecoration] = useState("");
  const [decorationBox, setDecorationBox] = useState("");
  const [countPiece, setCountPiece] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [exteriorDecoration, setExteriorDecoration] = useState(false);
  const [values, setValues] = useState({
    budget: "",
    details: "",
    theme: "",
  });

  const [decorationServices, setDecorationServices] = useState({
    ...values,
    type: decoration,
    exterior_decoration: exteriorDecoration,
    piece: countPiece,
  });

  const addCount = () => {
    let newCount = countPiece + 1;
    setCountPiece(newCount);
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,

        decoration: { ...dataRequest.decoration, piece: Number(newCount) },
      })
    );
  };
  const minusCount = () => {
    let newCount = countPiece <= 1 ? 1 : countPiece - 1;
    setCountPiece(newCount);
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,

        decoration: { ...dataRequest.decoration, piece: Number(newCount) },
      })
    );
  };

  const handleInputValue = (e) => {
    let newValue =
      e.target.name === "budget"
        ? e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
        : e.target.value;
    setValues({
      ...values,
      [e.target.name]: newValue,
    });
    setDecorationServices({
      ...decorationServices,
      [e.target.name]: newValue,
    });
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,

        decoration: { ...dataRequest.decoration, [e.target.name]: newValue },
      })
    );
  };

  const handleDecoration = (dec) => {
    setDecoration(dec);
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,

        decoration:
          dec === "Box de décoration"
            ? { type: dec, details: decorationBox, theme: values.theme }
            : { ...decorationServices, type: dec },
      })
    );
  };

  const handleDecorationBox = (dec, type) => {
    setDecorationBox(dec);
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,

        decoration: { type, details: dec },
      })
    );
  };

  const handleExteriorDecoration = () => {
    setExteriorDecoration(!exteriorDecoration);
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,

        decoration: {
          ...dataRequest.decoration,
          exterior_decoration: !exteriorDecoration,
        },
      })
    );
  };

  const handleClick = () => {
    window.scrollTo({ top: 0 });
    history.push("/coffrets");
  };

  const handleBudget = (value) => {
    setValues({ ...values, budget: value });
    setOpenDropdown(false);
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,

        decoration: { ...dataRequest.decoration, budget: value },
      })
    );
  };

  const handleRemoveValue = (type) => {
    setValues({
      ...values,
      [type]: "",
    });
    setDecorationServices({
      ...decorationServices,
      [type]: "",
    });
  };

  return (
    <div className="decorationsServices">
      <div className="decorationsServices__dest">
        <InputLabelFloat
          name="when"
          label="Quand ?"
          placeholder="Quand ?"
          value={values.when}
          type={{
            type: "date",
            minDate: dataRequest.date_from,
            selected: dataRequest.date_from,
          }}
          handleInputDate={(date) => {
            if (date > dataRequest.date_to) {
              dispatch(
                setRequest({
                  ...dataRequest,
                  tag: "original",
                  offer_id: null,

                  date_from: date.setDate(date.getDate()),
                  date_to: date.setDate(date.getDate() + 1),
                })
              );
            } else {
              dispatch(
                setRequest({
                  ...dataRequest,
                  tag: "original",
                  offer_id: null,
                  date_from: date,
                })
              );
            }
          }}
        />
        <InputLabelFloat
          name="until"
          label="Jusqu’au ?"
          placeholder="Jusqu’au ?"
          type={{
            type: "date",
            minDate: dataRequest.date_to,
            selected: dataRequest.date_to,
          }}
          value={values.until}
          handleInputDate={(date) =>
            dispatch(
              setRequest({
                ...dataRequest,
                tag: "original",
                offer_id: null,
                date_to: date,
              })
            )
          }
        />
        <InputLabelFloat
          name="where"
          label="Où"
          placeholder="Où"
          readOnly={true}
          isEditAble={true}
          handleClickEdit={() => history.push("/destinations")}
          value={`${destination ? `${destination.city}` : "All"}`}
        />
      </div>
      <div className="decorationsServices__container">
        <div className="decorationServices__selections">
          <h4 className="decorationsServices__title">Type de prestation</h4>
          <div className="decorationsServies__radioInputContainer">
            {selectServices.map((selectService) => (
              <label
                htmlFor={selectService.title}
                className="radio-container"
                key={selectService.id}
              >
                <input
                  type="radio"
                  id={selectService.title}
                  name="decoration"
                  checked={decoration === selectService.title}
                  onChange={() => handleDecoration(selectService.title)}
                  className="radio-input"
                />
                <span
                  className={`radio radio-services ${
                    decoration !== "" && "selected"
                  }`}
                ></span>
                {selectService.title}
                <div className="decorationsServices__servicesMoreInfo">
                  <img src={InfoIcon} alt="" />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: selectService.description,
                    }}
                  />
                </div>
              </label>
            ))}
          </div>
          {decoration === "" && (
            <small className="decorationsServices__attention">
              Attention ! vous devez choisir votre prestation
            </small>
          )}
        </div>
        {decoration === "Service de décoration" ? (
          <>
            <div>
              <h4 className="decorationsServices__title">Nombre de pièces</h4>
              <div className="decorationsServices__counterContainer">
                <div className="decorationsServices__counter">
                  <button
                    className="btn-minus"
                    onClick={() => minusCount()}
                  ></button>
                  <span>{countPiece}</span>
                  <button
                    className="btn-add"
                    onClick={() => addCount()}
                  ></button>
                </div>
              </div>
            </div>
            <div>
              <h4 className="decorationsServices__title">
                Décorations extérieures
              </h4>
              <div className="toggle-main-container">
                <input
                  type="checkbox"
                  className="toggle-input"
                  id="decoration-exterior"
                  onChange={handleExteriorDecoration}
                  checked={exteriorDecoration}
                />
                <label
                  htmlFor="decoration-exterior"
                  className="toggle-container"
                >
                  <span className="toggle"></span>
                </label>
              </div>
            </div>
            <div>
              <h4 className="decorationsServices__title">Votre budget</h4>
              <div className="decorationsServices__inputContainer read-only">
                <input
                  type="text"
                  placeholder="Écrivez-ici"
                  onChange={handleInputValue}
                  value={values.budget}
                  name="budget"
                  readOnly="true"
                  onClick={() =>
                    setOpenDropdown((openDropdown) => !openDropdown)
                  }
                  className="decorationsServices__input"
                />
                {openDropdown && (
                  <ul className="decorationsServices__dropdownItems">
                    <li onClick={() => handleBudget("Entre 650 et 1 150")}>
                      Entre 650€ et 1 150€
                    </li>
                    <li onClick={() => handleBudget("Entre 1 150 et 1 650")}>
                      Entre 1 150€ et 1 650€
                    </li>
                    <li onClick={() => handleBudget("Entre 1 650 et 2 150")}>
                      Entre 1 650€ et 2 150€
                    </li>
                    <li onClick={() => handleBudget("Plus de 2 150")}>
                      Plus de 2 150€
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div>
              <h4 className="decorationsServices__title">
                Précisions <span>facultatif</span>
              </h4>
              <div className="decorationsServices__inputContainer">
                <input
                  type="text"
                  placeholder="Écrivez-ici"
                  name="details"
                  onChange={handleInputValue}
                  value={values.details}
                  className="decorationsServices__input"
                />
                <svg
                  className="decorationsServices__inputClose"
                  viewBox="0 0 14 14"
                  onClick={() => handleRemoveValue("details")}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L13 13"
                    stroke="#6E7191"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 13L13 1.00002"
                    stroke="#6E7191"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </>
        ) : (
          decoration !== "" && (
            <>
              <div className="decorationsServices__servicesBox">
                <h4 className="decorationsServices__title">
                  Selectionnez votre box
                </h4>
                <div>
                  {selectBoxes.map((selectBox) => (
                    <label
                      htmlFor={selectBox.title}
                      className="radio-container"
                      key={selectBox.id}
                    >
                      <input
                        type="radio"
                        id={selectBox.title}
                        name="decorationBox"
                        checked={decorationBox === selectBox.title}
                        onChange={() =>
                          handleDecorationBox(
                            selectBox.title,
                            "Box de décoration"
                          )
                        }
                        className="radio-input"
                      />
                      <span
                        className={`radio ${decoration !== "" && "selected"}`}
                      ></span>
                      {selectBox.title}
                      <div className="decorationsServices__servicesBoxMoreInfo">
                        <img src={InfoIcon} alt="" />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: selectBox.description,
                          }}
                        />
                      </div>
                    </label>
                  ))}
                </div>
              </div>
              <div>
                <h4 className="decorationsServices__title">
                  Avez-vous un thème <span>facultatif</span>
                </h4>
                <div className="decorationsServices__inputContainer">
                  <input
                    type="text"
                    placeholder="Écrivez-ici"
                    name="theme"
                    onChange={handleInputValue}
                    value={values.theme}
                    className="decorationsServices__input"
                  />
                  <svg
                    className="decorationsServices__inputClose"
                    viewBox="0 0 14 14"
                    onClick={() => handleRemoveValue("details")}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L13 13"
                      stroke="#6E7191"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 13L13 1.00002"
                      stroke="#6E7191"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </>
          )
        )}
      </div>
      {decoration !== "" && (
        <>
          <button className="decorations__btn btn" onClick={handleClick}>
            Valider mes informations
            <svg
              className="arrow"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.52182 3.33331L13.9999 7.99998L9.52182 12.6666"
                stroke="#FCFCFC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <line
                x1="12.8219"
                y1="8.35449"
                x2="2.99989"
                y2="8.35449"
                stroke="#FCFCFC"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          <small className="decorations__note">* Champ obligatoire</small>
        </>
      )}
    </div>
  );
};

export default DecorationsServices;
