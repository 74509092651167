import { useDispatch, useSelector } from "react-redux";
// import { useState } from "react/cjs/react.development";
import { VILLAS_ADD_FILTER } from "../../../../constants/villaConstants";
import  bathroom  from "../../../../assets/filterIcons/salle de bain maiden.svg";
import  bedroom  from "../../../../assets/filterIcons/chambre maiden.svg";
import  invites  from "../../../../assets/filterIcons/invites maiden.svg";
import "./FilterPopupServices.css";

const FilterPopupServices = () => {
  const { filters } = useSelector((state) => state.villaFilter);
  const dispatch = useDispatch();

  const addCount = (category) => {
    dispatch({
      type: VILLAS_ADD_FILTER,
      payload: { ...filters, [category]: filters[category] + 1 },
    });
  };
  const minusCount = (category) => {
    dispatch({
      type: VILLAS_ADD_FILTER,
      payload: {
        ...filters,
        [category]: filters[category] <= 1 ? 1 : filters[category] - 1,
      },
    });
  };

  return (
    <div className="filterPopupServices">
      {/* <h3 className="filterPopup__categoryTitle">Lorem Ipsum</h3> */}
      <ul className="filterPopupServices__services">
        <li className="filterPopupServices__service">
          <div className="filterPopupServices__serviceNameContainer">
            <img
              src={bathroom}
              alt="bathroom icon"
              className="filterPopupServices__serviceIcon"
            />
            <span className="filterPopupServices__serviceName">
              Salles de bain
            </span>
          </div>
          <div className="filterPopupServices__counter">
            <button
              className="btn-minus"
              onClick={() => minusCount("bathroom")}
            ></button>
            <span>{filters.bathroom}</span>
            <button
              className="btn-add"
              onClick={() => addCount("bathroom")}
            ></button>
          </div>
        </li>
        <li className="filterPopupServices__service">
          <div className="filterPopupServices__serviceNameContainer">
            <img
              src={bedroom}
              alt="chambers icon"
              className="filterPopupServices__serviceIcon"
            />
            <span className="filterPopupServices__serviceName">Chambres</span>
          </div>
          <div className="filterPopupServices__counter">
            <button
              className="btn-minus"
              onClick={() => minusCount("chambers")}
            ></button>
            <span>{filters.chambers}</span>
            <button
              className="btn-add"
              onClick={() => addCount("chambers")}
            ></button>
          </div>
        </li>
        <li className="filterPopupServices__service">
          <div className="filterPopupServices__serviceNameContainer">
            <img
              src={invites}
              alt="invites icon"
              className="filterPopupServices__serviceIcon"
            />
            <span className="filterPopupServices__serviceName"> Invites</span>
          </div>
          <div className="filterPopupServices__counter">
            <button
              className="btn-minus"
              onClick={() => minusCount("invites")}
            ></button>
            <span>{filters.invites}</span>
            <button
              className="btn-add"
              onClick={() => addCount("invites")}
            ></button>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default FilterPopupServices;
