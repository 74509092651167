import image from "./../../../assets/banner/corporateBanner.jpeg";

const CorporateEventBanner = () => {
  return (
    <div
      className="relative w-full h-[300px] flex items-center justify-center bg-cover bg-center bg-no-repeat md:px-0 px-2"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="w-full h-full absolute  bg-opacity-60 z-0 "></div>
      <h1 className="text-4xl text-center text-white font-bold relative z-10">
        Événement d'entreprise
      </h1>
    </div>
  );
};

export default CorporateEventBanner;
