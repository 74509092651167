import axios from "axios";
import {
  VILLAS_REQUEST,
  VILLAS_SUCCESS,
  VILLAS_FAIL,
  VILLAS_DESTINATION_REQUEST,
  VILLAS_DESTINATION_SUCCESS,
  VILLAS_DESTINATION_FAIL,
  VILLA_REQUEST,
  VILLA_SUCCESS,
  VILLA_FAIL,
} from "../constants/villaConstants";

export const getVillas = () => async (dispatch) => {
  dispatch({ type: VILLAS_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/properties`
    );
    dispatch({ type: VILLAS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: VILLAS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getDestinationVillas = (id) => async (dispatch) => {
  dispatch({ type: VILLAS_DESTINATION_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/properties`
    );
    dispatch({
      type: VILLAS_DESTINATION_SUCCESS,
      payload: id
        ? data.filter((d) =>
            Number(id)
              ? d.destination_id === Number(id)
              : d.destination.country?.toLowerCase().trim() === id ||
                d.destination.region?.toLowerCase().trim() === id
          )
        : data,
    });
  } catch (err) {
    dispatch({
      type: VILLAS_DESTINATION_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const getVilla = (id) => async (dispatch) => {
  dispatch({ type: VILLA_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/properties`
    );
    const villa = data.filter((v) => v.id === id);
    dispatch({ type: VILLA_SUCCESS, payload: villa[0] });
  } catch (err) {
    dispatch({
      type: VILLA_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const filterVilla = (filters) => async (dispatch) => {
  dispatch({ type: VILLAS_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/properties?${
        filters["chambers"] !== 1 ? `bedrooms=${filters["chambers"]}&` : ""
      }${filters["bathroom"] !== 1 ? `bathrooms=${filters["bathroom"]}&` : ""}${
        filters["invites"] !== 1 ? `guests=${filters["invites"]}&` : ""
      }${
        filters["properties"].length > 0
          ? `kind[]=${filters["properties"].join("&kind[]=")}&`
          : ""
      }${
        filters["healties"].length > 0
          ? `services[]=${filters["healties"].join("&services[]=")}&`
          : ""
      }${
        filters["arounds"].length > 0
          ? `vicinities[]=${filters["arounds"].join("&vicinities[]=")}&`
          : ""
      }${
        filters["equipments"].length > 0
          ? `equipments[]=${filters["equipments"].join("&equipments[]=")}&`
          : ""
      }`
    );
    // let villas = [];
    // if (destinationId) {
    //   villas = data.filter((v) =>
    //     Number(destinationId)
    //       ? v.destination_id === Number(destinationId)
    //       : v.destination.country?.toLowerCase().trim() === destinationId ||
    //         v.destination.region?.toLowerCase().trim() === destinationId
    //   );
    // } else {
    //   villas = data;
    // }
    dispatch({ type: VILLAS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: VILLA_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
