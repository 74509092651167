import GoodiesCard from "../GoodiesComponents/GoodiesCard";
import goodiesSkeleton from "../../../utils/goodies.json";
import "./CoffretGoodiesPopup.css";
import SmallCardSkeleton from "../../Skeleton/SmallCardSkeleton";
import { useSelector } from "react-redux";

const CoffretGoodiesPopup = ({ show, closePopup, type }) => {
  const { goodies, loading: loadingGoodies } = useSelector(
    (state) => state.coffretGoodies
  );
  // const { dataRequest } = useSelector((state) => state.request);
  // const coffretData = dataRequest.products.find(
  //   (product) => product.id === type?.id
  // );

  return (
    <div className={`coffretGoodiesPopup ${show && "show"}`}>
      <div className="coffretGoodiesPopup__container">
        <button className="coffretGoodiesPopup__close" onClick={closePopup}>
          <i className="cross-mark"></i>
        </button>
        <h2 className="coffretGoodiesPopup__title">Choississez vos Goodies</h2>
        <div className="coffretGoodiesPopup__goodies">
          {!goodies.length && loadingGoodies
            ? goodiesSkeleton.map((_, idx) => idx < 6 && <SmallCardSkeleton />)
            : goodies.length
            ? goodies.map((goods) => (
                <GoodiesCard goods={goods} key={goods.id} type={type} />
              ))
            : goodies.map(
                (goods, idx) =>
                  idx < 6 && (
                    <GoodiesCard goods={goods} key={goods.id} type={type} />
                  )
              )}
        </div>
        <button className="coffretGoodiesPopup__buttonConfirm" onClick={closePopup}>
          {/* {coffretData && coffretData?.goodies.length ? (
            <span className="coffretsCard__optionSelected">
              ({coffretData?.goodies.reduce((a, b) => a + b.qty, 0)}&nbsp;
              goodies sélectionnés)
            </span>
          ) : (
            <span className="coffretsCard__optionSelected">
              0 goodies sélectionnés
            </span>
          )} */}
          Confimer
        </button>
      </div>
    </div>
  );
};

export default CoffretGoodiesPopup;
