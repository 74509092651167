import { testimonials } from "../../../utils/testimonial";
import "./HomeExperience.css";

const HomeExperience = () => {
  return (
    <section className="homeExperience container">
      <h2>Vos expériences Maiden</h2>
      <div className="homeExperience__container">
        {testimonials.map((testimonial) => (
          <div className="homeExperience__card" key={testimonial.id}>
            <svg
              className="homeExperience__quoteIcon"
              viewBox="0 0 56 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M24.086 25.8604C24.086 23.0504 23.2163 20.6418 21.4767 18.6346C19.7372 16.4936 17.5293 15.0886 14.853 14.4196C15.1207 12.68 15.7897 10.8736 16.8602 9.0002C18.0645 6.99304 19.3357 5.3873 20.6738 4.183L16.6595 0.770813C5.55317 8.13043 0 16.4936 0 25.8604C0 29.3395 1.1374 32.2834 3.41219 34.692C5.82079 36.9667 8.69773 38.1041 12.043 38.1041C15.3883 38.1041 18.1983 36.8998 20.4731 34.4912C22.8817 32.0826 24.086 29.2057 24.086 25.8604ZM56 25.8604C56 23.0504 55.1302 20.6418 53.3907 18.6346C51.6511 16.4936 49.4433 15.0886 46.767 14.4196C47.1685 12.5462 47.9044 10.6728 48.9749 8.79949C50.1792 6.92613 51.4504 5.3873 52.7885 4.183L48.5735 0.770813C37.3333 8.39805 31.7133 16.7613 31.7133 25.8604C31.7133 29.2057 32.9176 32.0826 35.3262 34.4912C37.7348 36.8998 40.6117 38.1041 43.957 38.1041C47.1685 38.1041 49.9785 36.8998 52.3871 34.4912C54.7957 32.0826 56 29.2057 56 25.8604Z" />
            </svg>
            <div className="homeExperience__cardText">
              <p>{testimonial.text}</p>
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="homeExperience__img"
              />
            </div>
            <div className="homeExperience__userInfo">
              <h3>{testimonial.name}</h3>
              {/* <span>Surf Instructor</span> */}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HomeExperience;
