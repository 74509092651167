// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quizzCard {
  padding: 1.25em;
  background: var(--clr-light);
  border-radius: 2em;
  box-shadow: 0px 0.25rem 0.4rem rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: 0.2s;
}

.quizzCard.selected {
  background: var(--clr-primary);
}

.quizzCard.selected h3 {
  color: #fff;
}

.quizzCard.quizz-result {
  width: 25rem;
}

.quizzCard__img {
  aspect-ratio: 3 / 1;
  object-fit: cover;
  width: 100%;
  border-radius: 1em;
}

.quizzCard h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.25rem;
}

.quizzCard__details {
  display: grid;
  gap: 0.5rem;
  margin-top: 1rem;
  grid-template-columns: repeat(2, minmax(7rem, 1fr));
}

.quizzCard__detail {
  display: flex;
  color: var(--clr-gray800);
  font-size: 0.8rem;
  font-weight: bold;
  align-items: center;
}
.quizzCard__detail img {
  width: 1.15rem;
  filter: invert(0.5);
  margin-right: 0.25rem;
}

@media (min-width: 768px) {
  .quizzCard h3 {
    font-size: 1.6rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/QuizzComponents/QuizzCard/QuizzCard.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,4BAA4B;EAC5B,kBAAkB;EAClB,kDAAkD;EAClD,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".quizzCard {\n  padding: 1.25em;\n  background: var(--clr-light);\n  border-radius: 2em;\n  box-shadow: 0px 0.25rem 0.4rem rgba(0, 0, 0, 0.04);\n  cursor: pointer;\n  transition: 0.2s;\n}\n\n.quizzCard.selected {\n  background: var(--clr-primary);\n}\n\n.quizzCard.selected h3 {\n  color: #fff;\n}\n\n.quizzCard.quizz-result {\n  width: 25rem;\n}\n\n.quizzCard__img {\n  aspect-ratio: 3 / 1;\n  object-fit: cover;\n  width: 100%;\n  border-radius: 1em;\n}\n\n.quizzCard h3 {\n  font-size: 1rem;\n  font-weight: 700;\n  margin-top: 0.25rem;\n}\n\n.quizzCard__details {\n  display: grid;\n  gap: 0.5rem;\n  margin-top: 1rem;\n  grid-template-columns: repeat(2, minmax(7rem, 1fr));\n}\n\n.quizzCard__detail {\n  display: flex;\n  color: var(--clr-gray800);\n  font-size: 0.8rem;\n  font-weight: bold;\n  align-items: center;\n}\n.quizzCard__detail img {\n  width: 1.15rem;\n  filter: invert(0.5);\n  margin-right: 0.25rem;\n}\n\n@media (min-width: 768px) {\n  .quizzCard h3 {\n    font-size: 1.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
