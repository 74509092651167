export const COFFRET_GOODIES_REQUEST = "COFFRET_GOODIES_REQUEST";
export const COFFRET_GOODIES_SUCCESS = "COFFRET_GOODIES_SUCCESS";
export const COFFRET_GOODIES_FAIL = "COFFRET_GOODIES_FAIL";

export const COFFRET_REQUEST = "COFFRET_REQUEST";
export const COFFRET_SUCCESS = "COFFRET_SUCCESS";
export const COFFRET_FAIL = "COFFRET_FAIL";

export const COFFRET_PANIERS_REQUEST = "COFFRET_PANIERS_REQUEST";
export const COFFRET_PANIERS_SUCCESS = "COFFRET_PANIERS_SUCCESS";
export const COFFRET_PANIERS_FAIL = "COFFRET_PANIERS_FAIL";

export const COFFRET_SELECTED_BUNDLES = "COFFRET_SELECTED_BUNDLES"
export const COFFRET_SELECTED = "COFFRET_SELECTED"
