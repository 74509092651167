import { useDispatch, useSelector } from "react-redux";
import { setRequest } from "../../../actions/requestActions";
import { currency } from "../../../utils/format";
import { truncate } from "../../../utils/truncate";
import ActivityDetails from "../ActivityDetails";
import "./ActivityCard.css";

const ActivityCard = ({ activity }) => {
  const dispatch = useDispatch();
  const { dataRequest } = useSelector((state) => state.request);
  const activityData = dataRequest.activities.find((a) => a.id === activity.id);

  const handleAddActivity = (activity) => {
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        activities: [...dataRequest.activities, { ...activity, qty: 1 }],
      })
    );
  };

  const handleRemoveActivity = (activity) => {
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        activities: dataRequest.activities.filter(
          (data) => data.id !== activity.id
        ),
      })
    );
  };

  const addCount = () => {
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        activities: dataRequest.activities.map((data) =>
          data.id === activity.id ? { ...data, qty: data.qty + 1 } : data
        ),
      })
    );
  };

  const minusCount = () => {
    if (activityData.qty > 1) {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          activities: dataRequest.activities.map((data) =>
            data.id === activity.id ? { ...data, qty: data.qty - 1 } : data
          ),
        })
      );
    } else {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          activities: dataRequest.activities.filter(
            (data) => data.id !== activity.id
          ),
        })
      );
    }
  };

  const handleShowDetails = () => {
    const activities = document.querySelectorAll(".activityDetails");
    activities.forEach((a) => {
      if (
        a.classList.contains("show") &&
        Number(a.dataset.id) !== activity.id
      ) {
        a.classList.remove("show");
      } else {
        if (Number(a.dataset.id) === activity.id) {
          a.classList.add("show");
        }
      }
    });
  };

  const handleCloseDetails = () => {
    const activities = document.querySelectorAll(".activityDetails");
    activities.forEach((v) => {
      if (v.classList.contains("show")) {
        v.classList.remove("show");
      }
    });
  };

  return (
    <div className="activityCard" key={activity.id}>
      <img
        src={activity.icon || "/images/icon/sailboat.png"}
        alt={activity.title}
        onClick={handleShowDetails}
        className="activityCard__icon"
      />
      <div className="activityaCard__text">
        <h3 onClick={handleShowDetails}>{activity.title}</h3>
        <span
          dangerouslySetInnerHTML={{
            __html: truncate(activity.description, 20),
          }}
        />
        <h4 className="activityCard__textPrice">
          À partir de <br />
          <span>{currency(activity?.price?.toFixed(0)) || 0}€</span>
          <small>{activity.countable && "/pers"}</small>
        </h4>
      </div>

      {activityData ? (
        !activity.countable ? (
          <button
            className="activityCard__btn btn selected"
            onClick={() => handleRemoveActivity(activity)}
            type="button"
          >
            <i className="tick-mark"></i>
          </button>
        ) : (
          <div className="filterBar__counter">
            <button className="btn-minus" onClick={minusCount}></button>
            <span>{activityData.qty}</span>
            <button className="btn-add" onClick={addCount}></button>
          </div>
        )
      ) : (
        <button
          className="activityCard__btn btn"
          onClick={() => handleAddActivity(activity)}
          type="button"
        >
          <svg
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.365128 20.2169C0.365128 9.17122 9.31943 0.216919 20.3651 0.216919C31.4108 0.216919 40.3651 9.17122 40.3651 20.2169C40.3651 31.2626 31.4108 40.2169 20.3651 40.2169C9.31943 40.2169 0.365128 31.2626 0.365128 20.2169Z"
              fill="#A94E4D"
            />
            <path
              d="M20.365 14.2169V26.2169"
              stroke="#FCFCFC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.365 20.2169H26.365"
              stroke="#FCFCFC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
      <ActivityDetails
        title={activity.title}
        id={activity.id}
        isCountable={activity.countable}
        price={activity.price}
        images={{ multiple: false }}
        icon={activity.icon || "/images/icon/sailboat.png"}
        selected={activityData}
        closeDetails={handleCloseDetails}
        description={activity.description}
        handleAddActivity={() => handleAddActivity(activity)}
        handleRemoveActivity={() => handleRemoveActivity(activity)}
      />
    </div>
  );
};

export default ActivityCard;
