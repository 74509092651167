import "./SmallCardSkeleton.css";

const SmallCardSkeleton = () => {
  return (
    <div className="smallCardSkeleton">
      <div className="smallCardSkeleton__iconContainer skeleton-card"></div>
      <div className="smallCardSkeleton__text">
        <div className="skeleton-card"></div>
        <div className="skeleton-card"></div>
      </div>
    </div>
  );
};

export default SmallCardSkeleton;
