import { UPDATE_QUOTE_STATUS } from "../constants/quoteConstants";

export const updateQuoteReducer = (
  state = {
    quoteRequest: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_QUOTE_STATUS:
      return {
        quoteRequest: action.payload,
      };

    default:
      return state;
  }
};
