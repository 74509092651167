import axios from "axios";
import {
  BLOG_REQUEST,
  BLOG_SUCCESS,
  BLOG_FAIL,
} from "../constants/blogConstants";

export const getBlogPosts = () => async (dispatch) => {
  dispatch({ type: BLOG_REQUEST });
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/public/posts`);
    dispatch({ type: BLOG_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: BLOG_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
