export const VILLAS_REQUEST = "VILLAS_REQUEST";
export const VILLAS_SUCCESS = "VILLAS_SUCCESS";
export const VILLAS_FAIL = "VILLAS_FAIL";

export const VILLA_REQUEST = "VILLA_REQUEST";
export const VILLA_SUCCESS = "VILLA_SUCCESS";
export const VILLA_FAIL = "VILLA_FAIL";

export const VILLAS_DESTINATION_REQUEST = "VILLAS_DESTINATION_REQUEST";
export const VILLAS_DESTINATION_SUCCESS = "VILLAS_DESTINATION_SUCCESS";
export const VILLAS_DESTINATION_FAIL = "VILLAS_DESTINATION_FAIL";

export const VILLAS_ADD_FILTER = "VILLAS_ADD_FILTER";
export const VILLAS_RESET_FILTER = "VILLAS_RESET_FILTER";
