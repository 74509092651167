import Contact from "../../../pages/Contact";

const AskDevis = ({ withoutText = false }) => {
  return (
    <div className="w-full   py-6 md:py-0 my-16 ">
      <div className="h-full w-full md:px-0 px-2 text-black justify-center items-center flex flex-col gap-y-6 md:gap-y-3">
        {withoutText ? (
          <></>
        ) : (
          <p className="text-center text-md px-4 md:px-[20%] font-light  mb-3">
            
          </p>
        )}

        <div className="w-full">
          <Contact withoutBanner={true} withoutText={true} />
        </div>
      </div>
    </div>
  );
};

export default AskDevis;
