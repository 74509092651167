import {
  COFFRET_GOODIES_REQUEST,
  COFFRET_GOODIES_SUCCESS,
  COFFRET_GOODIES_FAIL,
  COFFRET_REQUEST,
  COFFRET_SUCCESS,
  COFFRET_FAIL,
  COFFRET_PANIERS_REQUEST,
  COFFRET_PANIERS_SUCCESS,
  COFFRET_PANIERS_FAIL,
  COFFRET_SELECTED,
  COFFRET_SELECTED_BUNDLES,
} from "../constants/coffretConstants";

export const coffretGoodiesReducer = (state = { goodies: [] }, action) => {
  switch (action.type) {
    case COFFRET_GOODIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COFFRET_GOODIES_SUCCESS:
      return {
        ...state,
        loading: false,
        goodies: action.payload,
      };
    case COFFRET_GOODIES_FAIL:
      return {
        ...state,

        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const coffretReducers = (state = { coffrets: [] }, action) => {
  switch (action.type) {
    case COFFRET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COFFRET_SUCCESS:
      return {
        ...state,
        loading: false,
        coffrets: action.payload,
      };
    case COFFRET_FAIL:
      return {
        ...state,

        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const coffretPaniersReducer = (state = { paniers: [] }, action) => {
  switch (action.type) {
    case COFFRET_PANIERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COFFRET_PANIERS_SUCCESS:
      return {
        ...state,
        loading: false,
        paniers: action.payload,
      };
    case COFFRET_PANIERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const selectedCoffretReducer = (
  state = { selectedCoffret: null, selectedCoffretBundles: null },
  action
) => {
  switch (action.type) {
    case COFFRET_SELECTED:
      return {
        ...state,
        selectedCoffret: action.payload,
      };
    case COFFRET_SELECTED_BUNDLES:
      return {
        ...state,
        selectedCoffretBundles: action.payload,
      };

    default:
      return state;
  }
};
