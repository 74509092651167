import React, { useEffect, useRef, useState } from "react";
import "./MessageConversation.css";
import avatar from "../../../assets/avatar.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { createConsumer } from "@rails/actioncable";
import MessageReservationDetails from "../MessageReservationDetails";
import Loading from "../../Loading";
import PopupImagesGallery from "../../PopupImagesGallery";
import { formatNameFile } from "../../../utils/format";
import { getMessageRooms } from "../../../actions/messageActions";

export const handleDownloadFile = (url) => {
  axios({
    url,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", formatNameFile(url));
    link.click();
  });
};

const MessageConversation = ({
  setAllImages,
  setAllFiles,
  setTextCity,
  textCity,
  getImages,
  getFiles,
}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const { room, loading: loadingRoom } = useSelector((state) => state.room);
  const { rooms } = useSelector((state) => state.rooms);
  const { user } = useSelector((state) => state.createRequest);
  const [messages, setMessages] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [showFullImage, setShowFullImage] = useState(null);
  const [selectedImgShowIndex, setSelectedImgShowIndex] = useState(0);
  const inputText = useRef(null);
  const { destinations, loading: loadingDestinations } = useSelector(
    (state) => state.destination
  );
  const { villas, loading: loadingVillas } = useSelector(
    (state) => state.villa
  );
  const messageConversationContainer = useRef(null);
  const [orders, setOrders] = useState([]);

  const handleClickBrowse = (e) => {
    const userFiles = [...e.currentTarget.files];
    let validExtensionsImg = ["image/png", "image/jpg", "image/jpeg"];
    let validExtensionsFile = ["application/pdf"];
    userFiles.forEach((file) => {
      if (validExtensionsImg?.includes(file.type)) {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          let fileURL = fileReader.result;
          setImages((images) => [...images, fileURL]);
          setImageFiles((imageFiles) => [...imageFiles, file]);
        };
        fileReader.readAsDataURL(file);
      }
      if (validExtensionsFile?.includes(file.type)) {
        setFiles((files) => [...files, file]);
      }
    });
  };

  const handleCancelImage = (img, index) => {
    setImages((images) => images.filter((image) => image !== img));
    setImageFiles((imageFiles) => imageFiles.filter((_, i) => i !== index));
  };

  const handleCancelFile = (f) => {
    setFiles(files.filter((file) => f.name !== file.name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!message.trim() && !imageFiles.length && !files.length) {
      return;
    }
    setLoading(true);

    const formData = new FormData();

    formData.append("message[content]", message);
    formData.append("message[room_id]", room.id);
    formData.append("message[sender_id]", user?.user_id);
    if (imageFiles.length) {
      imageFiles.forEach((imgFile) => {
        formData.append("message[sender_files][]", imgFile);
      });
    }
    if (files.length) {
      files.forEach((file) => {
        formData.append("message[sender_files][]", file);
      });
    }

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/messages`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.token,
        },
      }
    );

    if (res.status === 204) {
      scrollToBottom();
      setMessage("");
      setImages([]);
      setImageFiles([]);
      setFiles([]);
    }
    setLoading(false);
  };

  const scrollToBottom = () => {
    if (messageConversationContainer.current) {
      messageConversationContainer.current.scrollTop =
        messageConversationContainer.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (room.id) {
      let arrayOfIndetifiers = createConsumer(
        `${process.env.REACT_APP_WS_URL}?token=${user.token}`
      ).subscriptions.subscriptions.map((s) => s);

      let isSubscribed = false;
      for (const identifier of arrayOfIndetifiers) {
        if (identifier.includes(room.id)) {
          isSubscribed = true;
          break;
        }
      }

      (async function () {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/orders`,
            {
              headers: {
                Authorization: user.token,
              },
            }
          );

          setOrders(res.data);
        } catch (err) {
          return err;
        }
      })();

      if (!isSubscribed) {
        createConsumer(
          `${process.env.REACT_APP_WS_URL}?token=${user.token}`
        ).subscriptions.create(
          { channel: "RoomChannel", id: room.id },
          {
            received(data) {
              setMessages((messages) => [...messages, data]);
              if (data.sender_files) {
                data.sender_files.forEach((d) => {
                  if (d.url?.includes("pdf")) {
                    setAllFiles((allFiles) => [...allFiles, d]);
                  } else {
                    setAllImages((allImages) => [...allImages, d]);
                  }
                });
              }
              dispatch(getMessageRooms());
              scrollToBottom();
            },
          }
        );
      }

      setMessages(room.messages);
      setAllFiles([]);
      setAllImages([]);
      room.messages
        .filter((msg) => msg.sender_files)
        .forEach((msg) => {
          msg.sender_files.forEach((img) => {
            if (img.url?.includes("pdf")) {
              setAllFiles((allFiles) => [...allFiles, img]);
            } else {
              setAllImages((allImages) => [...allImages, img]);
            }
          });
        });

      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  }, [dispatch, room, user, setAllImages, setAllFiles]);

  return (
    <div className="messageConversation">
      {rooms.filter((room) => room?.request?.client_id === user?.user_id)
        .length ? (
        Object.keys(room).length ? (
          <>
            <div className="messageConversation__header">
              <MessageReservationDetails
                deviceType="desktop"
                detail={room.request.payload}
                destinations={destinations}
                loadingDestinations={loadingDestinations}
                villas={villas}
                loadingVillas={loadingVillas}
                quoteId={room.quote_id}
                setTextCity={setTextCity}
                orders={orders}
                initialPrice={room?.request?.total_price}
              />

              <div className="messageConversation__personImgContainer">
                <img
                  src={avatar}
                  alt=""
                  className="messageConversation__personImg"
                />
              </div>
              <div className="messageConversation__details">
                <div>
                  <h3 className="messageConversation__personName">
                    {room.title}
                  </h3>
                  <span
                    className={`${
                      window.innerWidth < 1440
                        ? "messageConversation__expertise"
                        : "messageConversation__destination"
                    }`}
                  >
                    {textCity}
                  </span>
                </div>

                <div className="three-dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="messageConversation__proposal">
              <MessageReservationDetails
                detail={room.request.payload}
                destinations={destinations}
                loadingDestinations={loadingDestinations}
                villas={villas}
                quoteId={room.quote_id}
                loadingVillas={loadingVillas}
                setTextCity={setTextCity}
                initialPrice={room?.request?.total_price}
                orders={orders}
              />
            </div>
            <div
              className="messageConversation__chatContainer"
              ref={messageConversationContainer}
            >
              {!loadingRoom ? (
                messages?.length ? (
                  messages
                    ?.sort((a, b) => a.id - b.id)
                    .map((message) => (
                      <div
                        className={`messageConversation__chatBubble ${
                          user?.user_id === message.sender_id && "right"
                        }`}
                        key={message.id}
                      >
                        <img
                          src={avatar}
                          alt="person"
                          className="messageConversation__chatBubbleAvatar"
                        />
                        <div className="messageConversation__chatBubbleContent">
                          <span>
                            {user?.user_id === message.sender_id
                              ? message?.sender?.first_name ||
                                message?.sender_first_name
                              : "Maiden Agency"}
                          </span>
                          <div className="messageConversation__chatBubbleContentMessage">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: message.content,
                              }}
                            />
                            {message?.sender_files?.length ? (
                              <div
                                className={`messageConversation__chatBubbleImageContainer ${
                                  message.sender_files.filter((file) =>
                                    file.url?.includes("pdf")
                                  ).length && "file"
                                }`}
                              >
                                {message.sender_files
                                  .filter((img) => !img.url?.includes("pdf"))
                                  .map((img, index) => (
                                    <img
                                      key={img.url}
                                      onClick={() => {
                                        setSelectedImgShowIndex(index);
                                        setShowFullImage(message?.sender_files);
                                      }}
                                      src={img.url}
                                      alt={img.name}
                                      className="messageConversation__chatBubbleImage"
                                    />
                                  ))}
                                {message.sender_files
                                  .filter((file) => file.url?.includes("pdf"))
                                  .map((file) => (
                                    <div
                                      className="messageConversation__chatBubbleImgFile"
                                      key={file.url}
                                    >
                                      <svg
                                        class="bi bi-filetype-pdf"
                                        fill="currentColor"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        width="16"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                                          fill-rule="evenodd"
                                        />
                                      </svg>
                                      <a
                                        href={file.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() =>
                                          handleDownloadFile(file.url)
                                        }
                                      >
                                        {formatNameFile(file.url)}
                                      </a>
                                    </div>
                                  ))}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="loading-container-text h-full text-center">
                    Vous n'avez pas encore entamé de conversation
                  </div>
                )
              ) : (
                ""
              )}
            </div>
            <form
              className="messageConversation__inputContainer"
              onSubmit={handleSubmit}
            >
              <div>
                {images.length ? (
                  <div className="messageConversation__chatImgContainer">
                    {images.map((img, index) => (
                      <div className="messageConversation__chatImg" key={index}>
                        <svg
                          onClick={() => handleCancelImage(img, index)}
                          className="messageConversation__closeIcon"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19 36.4167C28.619 36.4167 36.4167 28.619 36.4167 19C36.4167 9.38108 28.619 1.58337 19 1.58337C9.38104 1.58337 1.58333 9.38108 1.58333 19C1.58333 28.619 9.38104 36.4167 19 36.4167ZM13.1304 13.1304C13.7487 12.5121 14.7513 12.5121 15.3696 13.1304L19 16.7608L22.6304 13.1304C23.2487 12.5121 24.2513 12.5121 24.8696 13.1304C25.4879 13.7487 25.4879 14.7513 24.8696 15.3696L21.2392 19L24.8696 22.6304C25.4879 23.2487 25.4879 24.2513 24.8696 24.8696C24.2513 25.4879 23.2487 25.4879 22.6304 24.8696L19 21.2392L15.3696 24.8696C14.7513 25.4879 13.7487 25.4879 13.1304 24.8696C12.5121 24.2513 12.5121 23.2487 13.1304 22.6304L16.7608 19L13.1304 15.3696C12.5121 14.7513 12.5121 13.7487 13.1304 13.1304Z"
                            fill="#FCFCFC"
                          />
                        </svg>
                        <img src={img} alt="" />
                      </div>
                    ))}
                  </div>
                ) : null}

                {files.length ? (
                  <div className="messageConversation__chatFileContainer">
                    {files.map((file, index) => (
                      <div className="messageConversation__chatImg" key={index}>
                        <svg
                          onClick={() => handleCancelFile(file)}
                          className="messageConversation__closeIcon"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19 36.4167C28.619 36.4167 36.4167 28.619 36.4167 19C36.4167 9.38108 28.619 1.58337 19 1.58337C9.38104 1.58337 1.58333 9.38108 1.58333 19C1.58333 28.619 9.38104 36.4167 19 36.4167ZM13.1304 13.1304C13.7487 12.5121 14.7513 12.5121 15.3696 13.1304L19 16.7608L22.6304 13.1304C23.2487 12.5121 24.2513 12.5121 24.8696 13.1304C25.4879 13.7487 25.4879 14.7513 24.8696 15.3696L21.2392 19L24.8696 22.6304C25.4879 23.2487 25.4879 24.2513 24.8696 24.8696C24.2513 25.4879 23.2487 25.4879 22.6304 24.8696L19 21.2392L15.3696 24.8696C14.7513 25.4879 13.7487 25.4879 13.1304 24.8696C12.5121 24.2513 12.5121 23.2487 13.1304 22.6304L16.7608 19L13.1304 15.3696C12.5121 14.7513 12.5121 13.7487 13.1304 13.1304Z"
                            fill="#FCFCFC"
                          />
                        </svg>
                        <div className="messageConversation__chatImgFile">
                          <svg
                            class="bi bi-filetype-pdf"
                            fill="currentColor"
                            height="16"
                            viewBox="0 0 16 16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                              fill-rule="evenodd"
                            />
                          </svg>
                          {file.name}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}

                <textarea
                  type="text"
                  ref={inputText}
                  placeholder="Envoyer un message ..."
                  value={message}
                  onChange={(e) => {
                    e.target.style.height = "3.125rem";
                    setMessage(e.target.value);
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                />
              </div>
              <button
                className="messageConversation__uploadImage btn"
                disabled={loading}
              >
                <input
                  type="file"
                  id="imageFiles"
                  accept="image/jpg, image/jpeg, image/png, application/pdf"
                  multiple
                  onChange={handleClickBrowse}
                />
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 15h2V9h3l-4-5-4 5h3z" />
                  <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z" />
                </svg>
              </button>
              <button
                className="messageConversation__sendBtn btn"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <Loading />
                ) : (
                  <svg
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.43041 8.83682C1.60267 8.51846 1.56822 7.3601 2.37559 6.99311L13.3323 2.01277C14.1735 1.63042 15.0389 2.49576 14.6565 3.33694L9.67616 14.2937C9.30918 15.1011 8.15081 15.0666 7.83245 14.2389L6.49143 10.7522C6.38986 10.4881 6.18116 10.2794 5.91706 10.1778L2.43041 8.83682Z"
                      stroke="#FCFCFC"
                      strokeWidth="2"
                    />
                  </svg>
                )}
              </button>
            </form>
          </>
        ) : (
          <div className="loading-container-text h-full">
            Choisir la conversation
          </div>
        )
      ) : (
        <div className="loading-container-text h-full">
          Vous n'avez pas fait de réservation
        </div>
      )}
      {showFullImage && (
        <PopupImagesGallery
          closePopup={() => setShowFullImage(null)}
          images={showFullImage}
          selectedShowImageIndex={selectedImgShowIndex}
        />
      )}
    </div>
  );
};

export default MessageConversation;
