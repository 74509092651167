export const currency = (number) => {
  if (!number) return;
  let reverse = number?.toString().split("").reverse().join(""),
    newFormat = reverse.match(/\d{1,3}/g);
  newFormat = newFormat.join(" ").split("").reverse().join("");

  return newFormat;
};

export const formatNameFile = (fileUrl) => {
  let getFileName = fileUrl.split("%3D%22")[1].split("%22%3B%20")[0];

  if (getFileName) {
    return getFileName
      .split("%20")
      .join(" ")
      .split("%255B")
      .join("[")
      .split("%255D")
      .join("]")
      .split("%2528")
      .join("(")
      .split("%2529")
      .join(")");
  } else {
    return "";
  }
};
