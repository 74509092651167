import "./CorporatePartner.css";
import celio from "./../../assets/corporate-company-logos/celio.png";
import chanel from "./../../assets/corporate-company-logos/chanel.png";
import cristal from "./../../assets/corporate-company-logos/cristal.webp";
import gallieni from "./../../assets/corporate-company-logos/gallieni.svg";
import manufacture from "./../../assets/corporate-company-logos/manufacture.png";
import parisSociety from "./../../assets/corporate-company-logos/paris-society.webp";
import printemps from "./../../assets/corporate-company-logos/printemps.png";
import servier from "./../../assets/corporate-company-logos/servier.png";
import publicis from "./../../assets/corporate-company-logos/publicis.png";


const CorporatePartner = (whiteBackground = false) => {
  return (
    <section
      className={`homePartner w-full ${whiteBackground ? "" : "bg-brownBg"}`}
    >
      <h2 className="homePartner__title">Nos partenaires</h2>
      <div className="homePartner__container">
      <a
        href="https://amp-madame.lefigaro.fr/business/maiden-agency-donner-vie-a-des-evjf-memorables-uniques-et-haut-de-gamme-20220224"
        rel="noreferrer"
        target="_blank"
      >
        <img src={publicis} alt="" />
      </a>
      <a
          href="https://amp-madame.lefigaro.fr/business/maiden-agency-donner-vie-a-des-evjf-memorables-uniques-et-haut-de-gamme-20220224"
          rel="noreferrer"
          target="_blank"
        >
          <img src={celio} alt="" />
        </a>
        <a
          href="https://www.moncarnet-gala.fr/articles/view/MAIDEN-AGENCY"
          rel="noreferrer"
          target="_blank"
        >
          <img src={cristal} alt="" />
        </a>
        <a
          href="https://www.grazia.fr/lifestyle/insolite/evjf-les-meilleures-activites-pour-une-celebration-au-sommet-76799.html/amp"
          rel="noreferrer"
          target="_blank"
        >
          <img src={chanel} alt="" />
        </a>
        <img src={gallieni} alt="" />
        <img src={manufacture} alt="" />
        <img src={parisSociety} alt="" />
        <img src={printemps} alt="" />
        <img src={servier} alt="" />
      </div>
    </section>
  );
};

export default CorporatePartner;
