import Hero from "../../components/Hero";
import heroImg from "../../assets/evenement/BAPTEMES .png";

import "./Evenement.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HomePartner from "../../components/HomeComponents/HomePartner";
import HomeExperience from "../../components/HomeComponents/HomeExperience";
import CorporatePartner from "../../components/CorporatePartner";
import Contact from "../Contact";

export const EvenmentImageSection = ({ images, title, contId, imgId }) => {
  const handleSlideImage = (containerId, imageId) => {
    let currentImage = 0;
    // getALlImage
    const getImagesContainer = document.getElementById(containerId);
    const getImages = document.querySelectorAll(`#${imageId}`);
    // let firstImage = getImages[currentImage++].cloneNode(true);
    getImagesContainer.removeChild(getImages[currentImage]);
    getImagesContainer.appendChild(getImages[currentImage]);
    currentImage++;
  };
  return (
    <div className="evenement__imageSectionContainer">
      <h2>{title}</h2>
      <div className="evenement__imageSection">
        <div className="evenement__imgContainer" id={contId}>
          {images.map((image) => (
            <img
              key={image.id}
              src={image.img}
              alt=""
              id={imgId}
              className="evenement__img"
            />
          ))}
        </div>
        {images.length > 1 && (
          <div
            className="evenement__imgContainerArrow"
            onClick={() => handleSlideImage(contId, imgId)}
          >
            <svg
              viewBox="0 0 78 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.5 16.25L55.1303 38.8803L32.5 61.5106"
                stroke="#14142B"
                strokeWidth="6.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

const Evenement = () => {
  const history = useHistory();
  return (
    <div className="evenement">
      <Hero
        title=" "
        heroImg={heroImg}
        model="Model 2"
        height="medium"
      />
      <div className="container evenement__container">
        <div className="evenement__description">
          <h1>
            Notre équipe vous accompagne pour créer et coordonner les plus beaux
            évènements de votre vie.
          </h1>
          <p>
            Fortes de notre expérience, après avoir passé 5 ans à organiser les
            événements privés du Pavillon Dauphine (16ème arrondissement de
            Paris) pour les Maisons Saint Clair et Potel & Chabot, nous serions
            ravies de vous accompagner dans l’organisation de votre réception
            privée ou professionnelle. Nous vous proposerons des idées
            originales et des concepts sur-mesure en adéquation avec votre
            budget et vos envies.
            <br />
            <br />
            Organiser vos événements est notre passion, confiez-nous le projet
            qui vous tient à coeur, ou le plus beau jour de votre vie.
            <br />
            <br />
            Un party manager dédié vous accompagnera dans la coordination et la
            réalisation de ce moment unique.
          </p>
        </div>
      </div>
      <HomePartner />

      <CorporatePartner whiteBackground={true} /> 
      <h2 className="my-3 px-4 md:px-0">Des questions ? Contactez nous !</h2>
      <Contact withoutBanner={true} />
    </div>
  );
};

export default Evenement;
