import axios from "axios";
import {
  QUIZZES_REQUEST,
  QUIZZES_SUCCESS,
  QUIZZES_FAIL,
  QUIZZES_ANSWER_CREATE,
} from "../constants/quizzConstants";

export const getQuizzes = () => async (dispatch) => {
  dispatch({ type: QUIZZES_REQUEST });
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/public/quizz`);
    dispatch({ type: QUIZZES_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: QUIZZES_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createQuizzAnswers = (answers) => async (dispatch) => {
  dispatch({ type: QUIZZES_ANSWER_CREATE, payload: answers });
};
