import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  destinationSearch,
  getDestinations,
} from "../../actions/destinationActions";
import { VILLAS_ADD_FILTER } from "../../constants/villaConstants";
import Loading from "../Loading";
import "./Country.css";

const Country = ({ query }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { destinations, loading: loadingDestination } = useSelector(
    (state) => state.destination
  );
  const [breakPoints, setBreakPoints] = useState(3);
  const { filters } = useSelector((state) => state.villaFilter);

  const handleDestination = (destination) => {
    window.scrollTo({ top: 0 });
    dispatch({
      type: VILLAS_ADD_FILTER,
      payload: {
        ...filters,
        destinationId: destination.id,
      },
    });
    history.push(`/villas`);
  };

  const countrySection = () => {
    let allCountry = [];
    let defColl = new Intl.Collator("fi");
    const newDestinations = destinations.sort(
      (a, b) =>
        defColl.compare(
          a.country?.toLowerCase().trim(),
          b.country?.toLowerCase().trim()
        ) ||
        defColl.compare(
          a.region?.toLowerCase().trim(),
          b.region?.toLowerCase().trim()
        ) ||
        defColl.compare(
          a.city?.toLowerCase().trim(),
          b.city?.toLowerCase().trim()
        )
    );

    allCountry.push(
      newDestinations.map((destination, index) => {
        if (newDestinations.length >= 1) {
          return (
            <>
              {newDestinations[index - 1]?.country !== destination.country && (
                <>
                  <li
                    key={index}
                    className={`country__label country__list ${
                      !newDestinations[index + 2]?.city && "last-line"
                    }`}
                  >
                    <br />

                    {destination.country}
                  </li>
                  <br className="line-country" />
                </>
              )}
              {newDestinations[index - 1]?.region?.toLowerCase().trim() !==
                destination.region?.toLowerCase().trim() &&
                destination.region && (
                  <li
                    className="country__regionLabel country__list"
                    key={index}
                  >
                    {destination.region}
                  </li>
                )}
              {destination.city &&
                newDestinations[index - 1]?.city?.toLowerCase().trim() !==
                  destination.city?.toLowerCase().trim() && (
                  <li
                    key={index}
                    className="country__cityLabel country__list"
                    onClick={() => handleDestination(destination)}
                  >
                    {destination.city}
                  </li>
                )}
            </>
          );
        } else {
          return null;
        }
      })
    );

    return allCountry;
  };

  useEffect(() => {
    if (query) dispatch(destinationSearch(query));
    else dispatch(getDestinations());

    function gridBreakPoinLayout() {
      if (window.innerWidth < 550) {
        setBreakPoints(1);
      } else if (window.innerWidth < 900) {
        setBreakPoints(2);
      } else {
        setBreakPoints(3);
      }
    }

    gridBreakPoinLayout();

    window.onresize = () => {
      gridBreakPoinLayout();
    };
  }, [dispatch, query]);

  return (
    <div className="country">
      {loadingDestination ? (
        <div className="loading-container-text h-full">
          <Loading />
          S'il vous plaît, attendez...
        </div>
      ) : (
        <ul
          style={{
            "--i":
              Math.floor(destinations.length / breakPoints) +
              (breakPoints < 3 ? 50 : 5),
          }}
          className="country__links container"
        >
          {countrySection()}
        </ul>
      )}
    </div>
  );
};

export default Country;
