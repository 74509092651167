import {
  QUIZZES_REQUEST,
  QUIZZES_SUCCESS,
  QUIZZES_FAIL,
  QUIZZES_ANSWER_CREATE,
} from "../constants/quizzConstants";

export const quizzReducers = (state = { quizzes: [] }, action) => {
  switch (action.type) {
    case QUIZZES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case QUIZZES_SUCCESS:
      return {
        ...state,
        loading: false,
        quizzes: action.payload,
      };
    case QUIZZES_FAIL:
      return {
        ...state,

        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const quizzAnswerReducers = (state = { answers: [] }, action) => {
  switch (action.type) {
    case QUIZZES_ANSWER_CREATE:
      return {
        ...state,
        answers: action.payload,
      };

    default:
      return state;
  }
};
