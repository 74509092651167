import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react/cjs/react.development";
import { VILLAS_ADD_FILTER } from "../../../../constants/villaConstants";
import "./FilterPopupMoreCategory.css";

const FilterPopupMoreCategory = ({ filters, categroyTitle, catergoryName }) => {
  const dispatch = useDispatch();
  const [filtersCateory, setFiltersCategory] = useState(filters);
  const { filters: filtersVilla } = useSelector((state) => state.villaFilter);
  // for mobile version add more
  const [perItem, setPerItem] = useState(4);

  const handleAddMore = () => {
    setPerItem((perItem) => perItem + 4);
    setFiltersCategory(filters.slice(0, perItem));
  };

  const handleAddFilter = (name) => {
    const isExists = filtersVilla[catergoryName].find((d) => d === name);
    if (!isExists) {
      dispatch({
        type: VILLAS_ADD_FILTER,
        payload: {
          ...filtersVilla,
          [catergoryName]: [...filtersVilla[catergoryName], name],
        },
      });
    } else {
      dispatch({
        type: VILLAS_ADD_FILTER,
        payload: {
          ...filtersVilla,
          [catergoryName]: filtersVilla[catergoryName].filter(
            (d) => d !== name
          ),
        },
      });
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setFiltersCategory(filters.slice(0, perItem));
    }
  }, [filters, perItem]);

  return (
    <div className="filterPopupMoreCategory">
      <h3 className="filterPopup__categoryTitle">
        {categroyTitle}
        {filters.length > 4 && (
          <span onClick={handleAddMore}>
            Voir Plus ({filters.length - filtersCateory.length})
          </span>
        )}
      </h3>
      <ul className="filterPopupMoreCategory__locations">
        {filtersCateory.map((filter) => (
          <li key={filter.id} className="filterPopupMoreCategory__location">
            <label
              htmlFor={`location-${filter.name}-${filter.id}`}
              className="checkbox-container"
            >
              <input
                type="checkbox"
                id={`location-${filter.name}-${filter.id}`}
                onChange={() => handleAddFilter(filter.name)}
                checked={filtersVilla[catergoryName]?.includes(filter.name)}
              />
              <span className="checkbox-button">
                <i className="tick-mark"></i>
              </span>
              <span className="checkbox-name">{filter.name}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilterPopupMoreCategory;
