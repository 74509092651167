import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getBlogPosts } from "../../actions/blogActions";
import moment from "moment";
import "./Article.css";
import Loading from "../../components/Loading";
import "moment/locale/fr";
moment.locale("fr");

const Article = () => {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { posts, error, loading } = useSelector((state) => state.blog);
  const article = !loading && posts.find((post) => post.id === Number(id));
  const currentArticleIndex =
    !loading && posts.findIndex((post) => post.id === Number(id));

  const handleNextArticle = () => {
    history.push(`/blog/${posts[currentArticleIndex + 1].id}`);
    window.scrollTo(0, 0);
  };
  const handlePreviousArticle = () => {
    history.push(`/blog/${posts[currentArticleIndex - 1].id}`);
    window.scrollTo(0, 0);
  };

  function changeString() {
    if (article) {
      let check = new RegExp('"/rails', "g"),
        match,
        newArticle = article?.description;
      while ((match = check.exec(newArticle))) {
        newArticle =
          newArticle?.slice(0, match.index) +
          process.env.REACT_APP_API_URL +
          newArticle?.slice(match.index + 1, newArticle?.length);
      }

      return newArticle || article?.description;
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(getBlogPosts());
  }, [dispatch]);

  return (
    <div className="article container">
      {loading ? (
        <div className="loading-container-text h-full">
          <Loading />
          S'il vous plaît, attendez...
        </div>
      ) : error ? (
        <div className="loading-container-text h-full">Il y a une erreur</div>
      ) : (
        <>
          <div className="article__head">
            <img
              src={article?.image?.url}
              alt={article?.title}
              className="article__mainImg"
            />
            <div className="article__contentHeader">
              <h1>{article?.title}</h1>
              <div className="article__author">
                <div>
                  <span> {moment(article?.created_at).format("LLL")}</span>
                </div>
              </div>
            </div>
          </div>

          <section className="article__content">
            <div
              dangerouslySetInnerHTML={{
                __html: changeString(),
              }}
            />
            <div className="article__movePage">
              {currentArticleIndex > 0 && (
                <button
                  className="btn article__prevBtn"
                  onClick={handlePreviousArticle}
                >
                  <svg
                    viewBox="0 0 18 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 25L4.5 14L15.5 3"
                      stroke="white"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                  </svg>
                  Artcile précédent
                </button>
              )}

              {!loading && currentArticleIndex < posts.length - 1 && (
                <button className="btn" onClick={handleNextArticle}>
                  Artcile suivant
                  <svg
                    viewBox="0 0 18 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 3L13.5 14L2.5 25"
                      stroke="white"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default Article;
