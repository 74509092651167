// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBar {
  width: 100%;
  display: flex;
  /* gap: 1.5rem; */
  margin-top: 1rem;
  align-items: center;
  flex-direction: column;
  border: 2px solid transparent;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(19, 19, 42, 0.25);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(2em);
  padding: 0.5em 1.5em;
  border-radius: 1em;
  /* grid-template-columns: repeat(5, minmax(100%, 1fr)); */
}

.searchBar > div {
  margin: 1rem 1rem 1rem 0;
}

@media (min-width: 768px) {
  .searchBar > div {
    flex: 1 1 30%;
  }

  .searchBar {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) {
  .searchBar > div {
    flex: 1 1 20%;
  }

  .searchBar {
    flex-wrap: nowrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,6BAA6B;EAC7B,mCAAmC;EACnC,4BAA4B;EAC5B,kCAAkC;EAClC,kCAAkC;EAClC,0BAA0B;EAC1B,kCAAkC;EAClC,oBAAoB;EACpB,kBAAkB;EAClB,yDAAyD;AAC3D;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".searchBar {\n  width: 100%;\n  display: flex;\n  /* gap: 1.5rem; */\n  margin-top: 1rem;\n  align-items: center;\n  flex-direction: column;\n  border: 2px solid transparent;\n  -webkit-font-smoothing: antialiased;\n  background-clip: padding-box;\n  -moz-osx-font-smoothing: grayscale;\n  background: rgba(19, 19, 42, 0.25);\n  backdrop-filter: blur(5px);\n  -webkit-backdrop-filter: blur(2em);\n  padding: 0.5em 1.5em;\n  border-radius: 1em;\n  /* grid-template-columns: repeat(5, minmax(100%, 1fr)); */\n}\n\n.searchBar > div {\n  margin: 1rem 1rem 1rem 0;\n}\n\n@media (min-width: 768px) {\n  .searchBar > div {\n    flex: 1 1 30%;\n  }\n\n  .searchBar {\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n}\n\n@media (min-width: 1200px) {\n  .searchBar > div {\n    flex: 1 1 20%;\n  }\n\n  .searchBar {\n    flex-wrap: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
