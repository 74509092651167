import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./BadgeRequest.css";

const BadgeRequest = ({ room }) => {
  const [status, setStatus] = useState("pending");
  const { user } = useSelector((state) => state.createRequest);
  const { quoteRequest } = useSelector((state) => state.updateQuote);
  const [orderStatus, setOrderStatus] = useState("");

  useEffect(() => {
    if (room.quote_id) {
      if (quoteRequest) {
        if (quoteRequest.quoteId === room.quote_id) {
          setStatus(quoteRequest.status);
          return;
        }
      }
      (async function () {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/orders`,
            {
              headers: {
                Authorization: user.token,
              },
            }
          );

          let order = res.data.find((d) => d.quote_id === room.quote_id);
          if (order) {
            setOrderStatus(order.status);
          } else {
            setOrderStatus("");
          }
        } catch (err) {
          return err;
        }
      })();
      (async function () {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/quotes/${room.quote_id}`,
          {
            headers: {
              Authorization: user.token,
            },
          }
        );
        if (res.status === 200) {
          let quoteStatus = res?.data?.aasm_state?.toLowerCase();
          if (quoteStatus === "accepted") {
            setStatus("accepted");
          }
          if (quoteStatus === "created" || quoteStatus === "sent") {
            setStatus("pending");
          }
          if (quoteStatus === "declined" || quoteStatus === "expired") {
            setStatus("error");
          }
        }
      })();
    }

    return () => {
      setOrderStatus("");
      setStatus("pending")
    };
  }, [user, room, quoteRequest]);

  return (
    <div className={`badgeRequest ${status} ${orderStatus}`}>
      <svg
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="badgeRequest__successIcon"
      >
        <path
          d="M2.33334 7.18286L5.63318 10.4827L12.2328 3.88303"
          stroke="#00994D"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      <svg
        className="badgeRequest__errorIcon"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.5 3.5L10.9516 10.9516"
          stroke="#98014C"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 10.9517L10.9516 3.50012"
          stroke="#98014C"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        className="badgeRequest__pendingIcon"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="7.00001"
          cy="7"
          r="6.41667"
          stroke="#6A5300"
          strokeWidth="1.16667"
        />
        <path
          d="M7 4.08333V7.58333L8.45833 9.04166"
          stroke="#6A5300"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {orderStatus === "paid"
        ? "Devis validé"
        : orderStatus === "half_paid"
        ? "Vient de payer 50%"
        : status === "accepted"
        ? "En attente de paiement"
        : status === "error"
        ? "Devis refusé"
        : "Devis en attente"}
    </div>
  );
};

export default BadgeRequest;
