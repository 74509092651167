// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  padding-bottom: 7em;
}

.home__quizz {
  background: var(--clr-secondary);
}

.home__quizz > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 0;
}

.home__quizz h2 {
  font-size: 1.5rem;
  color: var(--clr-primary);
}

.home__quizz p {
  text-align: center;
  margin: 2rem 0;
}

.home__quizz button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__quizz button svg {
  margin-left: 0.5rem;
  margin-bottom: -0.15rem;
}

@media (min-width: 375px) {
  .home__quizz h2 {
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) {
  .home__quizz h2 {
    font-size: 2rem;
  }

  .home__quizz p {
    font-size: 1.1rem;
  }
}

@media (min-width: 992px) {
  .home__quizz p {
    font-size: 1.2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".home {\n  padding-bottom: 7em;\n}\n\n.home__quizz {\n  background: var(--clr-secondary);\n}\n\n.home__quizz > div {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 4em 0;\n}\n\n.home__quizz h2 {\n  font-size: 1.5rem;\n  color: var(--clr-primary);\n}\n\n.home__quizz p {\n  text-align: center;\n  margin: 2rem 0;\n}\n\n.home__quizz button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.home__quizz button svg {\n  margin-left: 0.5rem;\n  margin-bottom: -0.15rem;\n}\n\n@media (min-width: 375px) {\n  .home__quizz h2 {\n    font-size: 1.8rem;\n  }\n}\n@media (min-width: 768px) {\n  .home__quizz h2 {\n    font-size: 2rem;\n  }\n\n  .home__quizz p {\n    font-size: 1.1rem;\n  }\n}\n\n@media (min-width: 992px) {\n  .home__quizz p {\n    font-size: 1.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
