import axios from "axios";
import {
  ACTIVITIES_REQUEST,
  ACTIVITIES_SUCCESS,
  ACTIVITIES_FAIL,
} from "../constants/activityConstants";

export const getActivities =
  (query = "") =>
  async (dispatch) => {
    dispatch({ type: ACTIVITIES_REQUEST });
    try {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/public/activities${`?destination=${query}`}`
      );
      dispatch({ type: ACTIVITIES_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ACTIVITIES_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };
