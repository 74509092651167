import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardSkeleton from "../../Skeleton/CardSkeleton";
import moment from "moment";
import VillasDetail from "../../VillasComponents/VillasDetails";
import "./ReservationCard.css";
import axios from "axios";
import Loading from "../../Loading";

const ReservationCard = ({
  loadingVillas,
  loadingDestinations,
  destinations,
  villas,
  room,
  tab,
  isQuiz,
  orderId,
  handleClick = () => {},
  results,
}) => {
  const { user } = useSelector((state) => state.createRequest);
  const [date, setDate] = useState({
    from: null,
    to: null,
  });
  const [activities, setActivities] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [products, setProducts] = useState([]);
  const [decoration, setDecoration] = useState("");
  const [guests, setGuests] = useState(0);
  const [villa, setVilla] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stripeLink, setStripeLink] = useState(null);
  const [loadingStripeLink, setLoadingStripeLink] = useState(false);
  const [destination, setDestination] = useState(null);
  const [decorationDetails, setDecorationDetails] = useState(null);
  const [offerId, setOfferId] = useState(null);
  const [name, setName] = useState("");
  const [offerPrice, setOfferPrice] = useState(0);

  const handleShowVillas = (e) => {
    e.stopPropagation();

    const villas = document.querySelectorAll(".villasDetails");
    villas.forEach((v) => {
      if (
        v.classList.contains("show") &&
        v.dataset.id !== villa.id + "quizz" + room.id
      ) {
        v.classList.remove("show");
      } else {
        if (v.dataset.id === villa.id + "quizz" + room.id) {
          v.classList.add("show");
        }
      }
    });
  };

  const handleCloseVillas = (e) => {
    e.stopPropagation();
    const villas = document.querySelectorAll(".villasDetails");
    villas.forEach((v) => {
      if (v.classList.contains("show")) {
        v.classList.remove("show");
      }
    });
  };

  useEffect(() => {
    if (tab === "payment-prosses") {
      (async function () {
        setLoadingStripeLink(true);
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/get_stripe_link_to_complete_payment`,
            {
              headers: {
                Authorization: user.token,
              },
            }
          );
          if (res.status === 200) {
            setStripeLink(res.data.stripe_link_to_complete_payment);
          } else {
            setStripeLink(null);
          }
          setLoadingStripeLink(false);
        } catch (err) {
          setLoadingStripeLink(false);
          return err;
        }
      })();
    }
    if (results?.length) {
      setLoading(true);
      let data = results.find((d) => d.id === room.id);
      if (data) {
        if (data.to) setDate((date) => ({ ...date, to: data.to }));
        if (data.from) setDate((date) => ({ ...date, from: data.from }));
        if (data.activities) setActivities(data.activities);
        if (data.total_guests) setGuests(data.total_guests);
        if (data.bundles) setBundles(data.bundles);
        if (data.products) setProducts(data.products);
        if (data.kind_of_decoration) setDecoration(data.kind_of_decoration);
        if (data.name) setName(data.name);
        if (data.price) setOfferPrice(data.price);
        if (isQuiz) {
          setDecorationDetails({
            details:
              data.kind_of_decoration === "Pas de décoration"
                ? ""
                : data.decoration_box_details || "",
            budget:
              data.kind_of_decoration === "Pas de décoration"
                ? ""
                : data.decoration_box_details || "",
            exterior_decoration:
              data.kind_of_decoration === "Pas de décoration"
                ? false
                : data.exterior_decoration || false,
            piece: data.kind_of_decoration === "Pas de décoration" ? 0 : 1,
            theme: "",
            type: data.kind_of_decoration || "",
          });
          setOfferId(data.id);
        }
        if (data.property_id) {
          const v = villas.find((villa) => villa.id === data.property_id);
          setVilla(v);
          if (v) {
            setDestination(
              destinations.find(
                (destination) => destination.id === v.destination_id
              )
            );
          }
        }
      }
      setLoading(false);
    }
  }, [user, room, villas, destinations, tab, orderId, isQuiz, results]);

  return loadingVillas || loadingDestinations || loading ? (
    <CardSkeleton />
  ) : (
    <>
      <div
        className={`reservationCard ${tab} ${isQuiz && "quiz"}`}
        onClick={isQuiz && handleShowVillas}
      >
        {!isQuiz && tab === "payment-prosses" && (
          <>
            <button
              className="reservationCard__payRest"
              disabled={loadingStripeLink}
            >
              {loadingStripeLink ? (
                <a href="!#">
                  <Loading />
                </a>
              ) : stripeLink ? (
                <a href={stripeLink}>€ Régler le solde de ma réservation</a>
              ) : (
                <span className="reservationCard__errorPayment">
                  il n'y a pas de lien de paiement, veuillez contacter à cet
                  e-mail &nbsp;
                  <a href="mailto:contact@maiden-agency.com">
                    contact@maiden-agency.com
                  </a>
                </span>
              )}
            </button>
          </>
        )}
        <img
          src={
            villa?.images?.length
              ? villa.images?.sort((a, b) =>
                  a.position < b.position ? -1 : 1
                )[0].url
              : "/images/villas/image1.png"
          }
          alt=""
          className="reservationCard__img"
        />
        <h3>{isQuiz ? name : villa?.title ? villa?.title : room.title}</h3>
        <ul className="reservationCard__details">
          <li className="reservationCard__detail">
            <img src="/images/icon/airplane.svg" alt="" />
            {destination
              ? `${destination?.country} — ${destination?.city}`
              : "Il n'y en a pas"}
          </li>
          {!isQuiz && (
            <li className="reservationCard__detail">
              <img src="/images/icon/calendar.svg" alt="" />
              {date?.from && date?.to
                ? `${moment(date.from).format("DD/MM/YYYY")} — 
              ${moment(date.to).format("DD/MM/YYYY")}`
                : "Il n'y en a pas"}
            </li>
          )}
          {!isQuiz && (
            <li className="reservationCard__detail">
              <img src="/images/icon/profile.svg" alt="" />
              Vous, et {guests || 1} personnes
            </li>
          )}
          <li className="reservationCard__detail">
            <img src="/images/icon/home.svg" alt="" />
            {villa?.title ? villa?.title : "Il n'y en a pas"}
          </li>
          {activities?.length ? (
            <li className="reservationCard__detail">
              <img src="/images/icon/gauge.svg" alt="" />
              {activities?.length || 0} activités incluses
            </li>
          ) : (
            ""
          )}
          {decoration !== "Pas de décoration" && (
            <li className="reservationCard__detail">
              <img src="/images/icon/pallete.svg" alt="" />
              {decoration === "Pas de décoration"
                ? decoration
                : (
                    <>
                      {decoration}&nbsp;
                      {decoration?.includes("Box")
                        ? decorationDetails?.details?.includes("300")
                          ? "300€"
                          : "150€"
                        : ""}
                    </>
                  ) || "Pas de décoration"}
            </li>
          )}

          {bundles?.length ? (
            <li className="reservationCard__detail">
              <img src="/images/icon/gift.svg" alt="" />
              {bundles?.length || 0} coffrets inclus
            </li>
          ) : (
            ""
          )}
        </ul>
        {isQuiz && (
          <VillasDetail
            details={villa}
            offerActivities={activities}
            offerBundles={bundles}
            offerProducts={products}
            closeDetails={handleCloseVillas}
            isQuiz={isQuiz}
            id={villa?.id + "quizz" + room.id}
            offerPrice={offerPrice}
            handleClickQuizz={() =>
              handleClick({
                date_from: moment()._d,
                date_to: moment().add(1, "day")._d,
                guests: 1,
                destination_id: destination.id,
                property: villa.id,
                activities: activities || [],
                decoration: decorationDetails || {},
                products: products || [],
                bundles: bundles || [],
                delivery: "",
                offer_id: offerId,
              })
            }
          />
        )}
      </div>
    </>
  );
};

export default ReservationCard;
