import axios from "axios";
import {
  MESSAGE_ROOMS_FAIL,
  MESSAGE_ROOMS_REQUEST,
  MESSAGE_ROOMS_SUCCESS,
  MESSAGE_ROOM_FAIL,
  MESSAGE_ROOM_REQUEST,
  MESSAGE_ROOM_SUCCESS,
} from "../constants/messageConstants";

export const getMessageRooms = () => async (dispatch, getState) => {
  dispatch({ type: MESSAGE_ROOMS_REQUEST });

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/rooms`,
      {
        headers: {
          Authorization: getState().createRequest.user.token,
        },
      }
    );
    dispatch({ type: MESSAGE_ROOMS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: MESSAGE_ROOMS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getMessageRoom = (id) => async (dispatch, getState) => {
  dispatch({ type: MESSAGE_ROOM_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/rooms/${id}`,
      {
        headers: {
          Authorization: getState().createRequest.user.token,
        },
      }
    );
    dispatch({ type: MESSAGE_ROOM_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: MESSAGE_ROOM_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// export const sendMessages
