import {
  VILLAS_REQUEST,
  VILLAS_SUCCESS,
  VILLAS_FAIL,
  VILLAS_DESTINATION_REQUEST,
  VILLAS_DESTINATION_SUCCESS,
  VILLAS_DESTINATION_FAIL,
  VILLA_REQUEST,
  VILLA_SUCCESS,
  VILLA_FAIL,
  VILLAS_ADD_FILTER,
  VILLAS_RESET_FILTER,
} from "../constants/villaConstants";

export const villaReducers = (state = { villas: [], villa: {} }, action) => {
  switch (action.type) {
    case VILLAS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VILLAS_SUCCESS:
      return {
        ...state,
        loading: false,
        villas: action.payload,
      };
    case VILLAS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case VILLA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VILLA_SUCCESS:
      return {
        ...state,
        loading: false,
        villa: action.payload,
      };
    case VILLA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // get villa by destination
    case VILLAS_DESTINATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VILLAS_DESTINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        villas: action.payload,
      };
    case VILLAS_DESTINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const villaFilterReducers = (
  state = {
    filters: {
      invites: 1,
      chambers: 1,
      bathroom: 1,
      arounds: [],
      equipments: [],
      healties: [],
      properties: [],
      destinationId: "",
    },
  },
  action
) => {
  switch (action.type) {
    case VILLAS_ADD_FILTER:
      localStorage.setItem("maiden_filter", JSON.stringify(action.payload));
      return {
        ...state,
        filters: action.payload,
      };
    case VILLAS_RESET_FILTER:
      localStorage.removeItem("maiden_filter");
      return {
        filters: {
          invites: 1,
          chambers: 1,
          bathroom: 1,
          arounds: [],
          equipments: [],
          healties: [],
          destinationId: "",
          properties: [],
        },
      };
    default:
      return state;
  }
};
