import PaymentInformation from "../../components/PaymentInformation";
import AskDevis from "../../components/PrivateEventComponent/AskDevis";
import PrivateEventBanner from "../../components/PrivateEventComponent/Banner";
import PrivateEventSection from "../../components/PrivateEventComponent/Section/PrivateEventSection";
import Contact from "../Contact";
import Payment from "../Payment";
import mariage1 from "./../../assets/event/mariage/mariage-1.jpg";
import mariage2 from "./../../assets/event/mariage/mariage-2.jpg";
import mariage3 from "./../../assets/event/mariage/mariage-3.jpg";
import mariage4 from "./../../assets/event/mariage/mariage-4.jpg";
import mariage5 from "./../../assets/event/mariage/mariage-5.jpeg";
import mariage6 from "./../../assets/event/mariage/mariage-6.jpg";
import mariage7 from "./../../assets/event/mariage/mariage-7.jpg";
import babyShower1 from "./../../assets/event/baby-shower/baby-shower-1.jpeg";
import babyShower2 from "./../../assets/event/baby-shower/baby-shower-2.jpg";
import babyShower3 from "./../../assets/event/baby-shower/baby-shower-3.jpg";
import babyShower4 from "./../../assets/event/baby-shower/baby-shower-4.jpg";
import feteReligieuse1 from "./../../assets/event/fete-religieuse/fete-religieuse-1.jpg";
import feteReligieuse2 from "./../../assets/event/fete-religieuse/fete-religieuse-2.jpg";
import feteReligieuse3 from "./../../assets/event/fete-religieuse/fete-religieuse-3.jpg";
import birthday1 from "./../../assets/event/birthday/birthday-1.jpg";
import birthday2 from "./../../assets/event/birthday/birthday-2.jpg";
import birthday3 from "./../../assets/event/birthday/birthday-3.jpg";
import birthday4 from "./../../assets/event/birthday/birthday-4.jpg";
import evjf1 from "./../../assets/event/evjf/evjf-1.jpg";
import evjf2 from "./../../assets/event/evjf/evjf-2.png";





import mariage from "./../../assets/event/mariage.jpeg";
import evjf from "./../../assets/event/evjf.jpg";
import babyShower from "./../../assets/event/baby-shower.jpg";
import baptism from "./../../assets/event/baptism.jpg";
import birthday from "./../../assets/event/birthday.jpg";
import "./PrivateEvent.css";

const PrivateEvent = () => {
  return (
    <>
      <PrivateEventBanner />
      <PrivateEventSection
        anchor="mariage"
        title="Mariage"
        subtitle="LA DÉCORATION DE VOTRE MARIAGE POUR UNE FÊTE ÉTINCELANT"
        content={[
          "Les années passent et l’amour grandit, votre moitié s’est lancée dans cette folle aventure, elle vous a tendu la bague et vous avez dit oui !",
          <br key="1" />,
          <br key="2" />,
          "Mais par où commencer ?",
          <br key="3" />,
          <br key="4" />,
          "Chez Maiden Agency chaque mariage est une œuvre d’art exquise, conçue sur mesure pour capturer l’essence de votre amour et refléter votre style personnel.",
          <br key="5" />,
          "Nous sommes là pour transformer vos rêves en réalité.",
          <br key="6" />,
          "Ce qui nous distingue ? Notre engagement inébranlable pour l’excellence, notre souci méticuleux du détail, et notre passion ardente pour créer des expériences uniques et mémorables.",
          <br key="7" />,
          <br key="8" />,
          "Chaque mariage que nous planifions est une histoire d’amour.",
          <br key="9" />,
          "Il est soigneusement orchestré pour créer une symphonie harmonieuse de beauté et d’émotion.",
          <br key="10" />,
          <br key="11" />,
          "Laissez-nous être les partenaires qui vous guideront à travers chaque étape de ce voyage extraordinaire vers le bonheur.",
        ]}
        linkLabel="Contactez nous"
        linkHref="/contact"
        imgText="Mariage"
        imagesCarousel={[
          mariage7,
          mariage1,
          mariage2,
          mariage3,
          mariage4,
          mariage5,
          mariage6,
        ]}
        reverse={false}
      />

      <PrivateEventSection
        anchor="babyShower"
        title="Baby shower"
        imgText="Baby shower"
        subtitle="LA DÉCORATION DE VOTRE MARIAGE POUR UNE FÊTE ÉTINCELANT"
        content={[
          "Chez Maiden Agency, nous sommes là pour vous aider à célébrer l’arrivée imminente de votre petit trésor d’une manière aussi spéciale et unique que vous l’êtes.",
          <br key="1" />,
          <br key="2" />,
          "Que vous souhaitiez une baby shower intime et douce ou une fête joyeuse et extravagante, notre équipe dévouée est là pour donner vie à vos rêves.",
          <br key="3" />,
          "Des décorations charmantes aux jeux divertissants en passant par les délices sucrés et salés, nous nous occupons de chaque aspect pour que vous puissiez profiter pleinement de ce moment précieux avec vos proches.",
          <br key="3" />,
          <br key="4" />,
          "Grâce à notre expertise dans l’événementiel, et à notre sens aigu du détail, nous créons des baby shower sur mesure qui capturent l’essence de votre personnalité et célèbrent la joie à l’arrivée de votre bébé.",
          <br key="3" />,
          <br key="4" />,
          "Contactez-nous dès aujourd’hui pour commencer à planifier votre baby shower parfaite et unique.",
        ]}
        linkLabel="Contactez nous"
        linkHref="/contact"
        imagesCarousel={[babyShower2, babyShower4, babyShower1, babyShower3]}
        reverse={true}
        bgWhite={true}

      />

      <PrivateEventSection
        anchor="baptism"
        imgText="Fête religieuse"
        title="Fête religieuse"
        subtitle="EVJF, EVG, UNE DÉCORATION PERSONNALISÉE POUR UNE CÉLÉBRATION
            INOUBLIABLE"
        content={[
          "Nous sommes votre partenaire de confiance pour l’organisation de ces moments significatifs et pleins d’émotions.",
          <br key="1" />,
          <br key="2" />,
          "Nous sommes là pour vous accompagner dans la planification et la réalisation d’une cérémonie qui célèbre l’entrée de votre enfant dans la communauté et dans la vie spirituelle.",
          "Que vous envisagiez une cérémonie intime et traditionnelle ou une célébration joyeuse et festive, notre équipe dévouée est là pour donner vie à vos idées et rendre ce moment inoubliable pour vous et vos proches.",
          <br key="5" />,
          "Des invitations élégantes, à la décoration du lieu de la cérémonie, en passant par le choix du traiteur, nous nous occupons de chaque détail pour que vous puissiez vous concentrer sur l’essentiel.",
          <br key="6" />,
          <br key="7" />,

          "Grâce à notre expertise événementiel et à notre sens du respect des traditions, nous créons des célébrations religieuses personnalisées qui reflètent vos croyances et vos valeurs, tout en créant des souvenirs précieux.",
        ]}
        linkHref="/evjf"
        imagesCarousel={[feteReligieuse2, feteReligieuse3]}
        reverse={false}
        linkLabel="Contactez nous"
      />

      <PrivateEventSection
        anchor="birthday"
        imgText={"Anniversaire"}
        title="Anniversaire"
        subtitle="LA DÉCORATION DE VOTRE MARIAGE POUR UNE FÊTE ÉTINCELANT"
        centerImage={true}
        content={[
          "Chez Maiden Agency nous sommes là pour vous aider à célébrer chaque année de votre vie de manière spéciale et mémorable.",
          <br key="1" />,
          <br key="2" />,
          "Faites-nous confiance pour vous offrir une expérience heureuse, où chaque moment est soigneusement planifié pour vous permettre de savourer chaque instant de votre journée.",
        ]}
        linkLabel="Contactez nous"
        linkHref="/contact"
        imagesCarousel={[birthday1, birthday3, birthday4]}
        reverse={true}
        bgWhite={true}
      />

      <PrivateEventSection
        anchor="evjf"
        imgText={"EVJF"}
        title="EVJF"
        subtitle="Votre EVJF, en mieux"
        centerImage={true}
        content={[
"Au sein de notre agence, nous comprenons que l'enterrement de vie de jeune fille est un moment exceptionnel, marquant la transition vers une nouvelle étape de vie.",

"C’est pourquoi nous nous dédions à créer des expériences uniques et mémorables qui reflètent parfaitement la personnalité et les souhaits de la future mariée.",
<br key="9" />,
<br key="10" />,
"Pourquoi nous choisir pour votre EVJF ?",
<br key="9" />,<br key="9" />,
"Expertise Spécialisée : Forts de plusieurs années d'expérience dans l'organisation d'événements, nous avons développé une expertise particulière dans les EVJF. Chaque membre de notre équipe est formé pour offrir une attention sur mesure et une créativité sans limite.",
<br key="11" />,
<br key="12" />,
"Personnalisation Totale : Nous savons que chaque future mariée est unique. Nos planificateurs d'événements travaillent de près avec vous pour personnaliser chaque détail, du thème, aux activités, en passant par les destinations inoubliables.",
"Service Complet : De la conception à l’exécution, nous nous occupons de tout. Hébergement, activités, restauration, accessoires, planning… reposez-vous sur nous pour que chaque aspect de l'EVJF que vous organisez soit impeccable.",
<br key="13" />,
<br key="14" />,
"Relations de Qualité : Nous avons établi des partenariats avec les meilleurs prestataires locaux et internationaux, assurant ainsi des services de qualité et des expériences exclusives.",
<br key="15" />,
<br key="16" />,
"Souvenirs Mémorables : Notre objectif est de faire de cet événement non seulement une journée ou un week-end de plaisir, mais une expérience qui restera gravée dans les mémoires de toutes les participantes.",
<br key="17" />,
<br key="18" />,
"Invitez la joie, choisissez l’excellence.",
<br key="19" />,
<br key="20" />,
"Avec nous votre EVJF sera plus qu'une fête, ce sera une célébration inoubliable de l'amitié et de l'amour.", 
<br key="21" />,
<br key="22" />,
"Contactez-nous aujourd'hui pour commencer à planifier le jour parfait!",
<br key="23" />,
          <br key="21" />,
          "Faites-nous confiance pour vous offrir une expérience heureuse, où chaque moment est soigneusement planifié pour vous permettre de savourer chaque instant de votre journée.",
        ]}
        linkLabel="Découvrir"
        linkHref="/evjf"
        imagesCarousel={[evjf1, evjf2]}
        reverse={false}
      />

      <AskDevis withoutText={true} />
    </>
  );
};

export default PrivateEvent;
