export const arrowRight = (
  contentContainer,
  mainContainer,
  navShow,
  setNavShow,
  maxRight,
  maxLeft
) => {
  if (contentContainer) {
    let { scrollWidth, clientWidth } = contentContainer.current;
    setNavShow({ ...navShow, left: true });
    mainContainer.current.classList.add(maxRight);
    if (clientWidth + contentContainer.current.scrollLeft >= scrollWidth) {
      setNavShow({ ...navShow, right: false });
      mainContainer.current.classList.remove(maxLeft);
    }
    contentContainer.current.scrollLeft += 500;
  }
};
export const arrowLeft = (
  contentContainer,
  mainContainer,
  navShow,
  setNavShow,
  maxRight,
  maxLeft
) => {
  if (contentContainer) {
    contentContainer.current.scrollLeft -= 500;
    setNavShow({ ...navShow, right: true });
    mainContainer.current.classList.add(maxLeft);
    if (contentContainer.current.scrollLeft === 0) {
      setNavShow({ ...navShow, left: false });
      mainContainer.current.classList.remove(maxRight);
    }
  }
};
export const handleScroll = (
  contentContainer,
  mainContainer,
  setNavShow,
  maxRight,
  maxLeft
) => {
  if (contentContainer) {
    let { scrollWidth, clientWidth } = contentContainer.current;
    if (contentContainer.current.scrollLeft === 0) {
      mainContainer.current.classList.remove(maxRight);
    } else {
      mainContainer.current.classList.add(maxRight);
    }
    if (clientWidth + contentContainer.current.scrollLeft >= scrollWidth) {
      mainContainer.current.classList.remove(maxLeft);
    } else {
      mainContainer.current.classList.add(maxLeft);
    }
    setNavShow({
      left: contentContainer.current.scrollLeft === 0 ? false : true,
      right:
        clientWidth + contentContainer.current.scrollLeft >= scrollWidth
          ? false
          : true,
    });
  }
};
