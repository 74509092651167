// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog {
  padding: 2em 0 4em 0;
}

.blog__title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

/* .blog__container {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
} */

@media (min-width: 992px) {
  .blog {
    padding: 3em 0 4em 0;
  }

  .blog__title {
    margin-bottom: 2rem;
    font-size: 3.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Blog/Blog.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;;;;GAIG;;AAEH;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;EACnB;AACF","sourcesContent":[".blog {\n  padding: 2em 0 4em 0;\n}\n\n.blog__title {\n  font-size: 1.8rem;\n  margin-bottom: 1rem;\n}\n\n/* .blog__container {\n  display: grid;\n  gap: 3rem;\n  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));\n} */\n\n@media (min-width: 992px) {\n  .blog {\n    padding: 3em 0 4em 0;\n  }\n\n  .blog__title {\n    margin-bottom: 2rem;\n    font-size: 3.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
