import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import avatar from "../../../assets/avatar.svg";
import "./MessageProfile.css";
import axios from "axios";
import PopupImagesGallery from "../../PopupImagesGallery";
import { formatNameFile } from "../../../utils/format";
import { handleDownloadFile } from "../MessageConversation";

const MessageProfile = ({ images, files, textCity }) => {
  const { room } = useSelector((state) => state.room);
  const { user } = useSelector((state) => state.createRequest);
  const { destinations } = useSelector((state) => state.destination);
  const { villas } = useSelector((state) => state.villa);
  const [destination, setDestination] = useState(null);
  const [showImage, setShowImage] = useState(null);

  // const images = room

  const [expand, setExpand] = useState({
    informations: false,
    images: false,
    files: false,
    proposals: false,
  });

  // handle expand the list
  const handleExpand = (type) => {
    setExpand({ ...expand, [type]: !expand[type] });
  };

  useEffect(() => {
    if (room.quote_id) {
      (async function () {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/quotes/${room.quote_id}`,
            {
              headers: {
                Authorization: user.token,
              },
            }
          );
          if (res.status === 200) {
            if (res.data.property_id) {
              const v = villas.find(
                (villa) => villa.id === res.data.property_id
              );
              if (v) {
                setDestination(
                  destinations.find(
                    (destination) => destination.id === v.destination_id
                  )
                );
              }
            }
          }
        } catch (err) {
          return err;
        }
      })();
    } else {
      if (room?.request?.payload.destination_id)
        setDestination(
          destinations.find(
            (destination) =>
              destination.id === room?.request?.payload.destination_id
          )
        );
    }
  }, [user, room, destinations, villas]);

  return Object.keys(room).length ? (
    <div className="messageProfile">
      <div className="messageProfile__person">
        <div className="messageProfile__personImg">
          <img src={avatar} alt="" />
        </div>
        <h3>{room.title}</h3>
        <span>{textCity}</span>
      </div>
      <ul className="messageProfile__menuLists">
        <li className={`messageProfile__menuList ${expand.images && "expand"}`}>
          <div onClick={() => handleExpand("images")}>
            Images ({images.length})
            <svg
              className="messageProfile__chevronIcon"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9263 10L11.9631 16.9632L4.99993 10"
                stroke="#14142B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <ul className="messageProfile__menuExpand">
            {images.map((img, index) => (
              <li
                className="messageProfile__menuListExpand"
                key={img.url + index}
                onClick={() => setShowImage(img)}
              >
                <div>
                  <svg
                    viewBox="0 0 512 512"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M368 224C394.5 224 416 202.5 416 176C416 149.5 394.5 128 368 128C341.5 128 320 149.5 320 176C320 202.5 341.5 224 368 224Z"
                      fill="#14142B"
                    />
                    <path
                      d="M452 64H60C44.4 64 32 76.7 32 92.3V419.7C32 435.3 44.4 448 60 448H452C467.6 448 480 435.3 480 419.7V92.3C480 76.7 467.6 64 452 64ZM348.9 261.7C345.9 258.2 341.3 255.5 336.1 255.5C331 255.5 327.4 257.9 323.3 261.2L304.6 277C300.7 279.8 297.6 281.7 293.1 281.7C288.8 281.7 284.9 280.1 282.1 277.6C281.1 276.7 279.3 275 277.8 273.5L224 215.3C220 210.7 214 207.8 207.3 207.8C200.6 207.8 194.4 211.1 190.5 215.6L64 368.2V107.7C65 100.9 70.3 96 77.1 96H434.8C441.7 96 447.3 101.1 447.7 108L448 368.4L348.9 261.7Z"
                      fill="#14142B"
                    />
                  </svg>
                  <span>{formatNameFile(img.url)}</span>
                </div>
              </li>
            ))}
          </ul>
        </li>
        <li className={`messageProfile__menuList ${expand.files && "expand"}`}>
          <div onClick={() => handleExpand("files")}>
            Fichiers ({files.length})
            <svg
              className="messageProfile__chevronIcon"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9263 10L11.9631 16.9632L4.99993 10"
                stroke="#14142B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <ul className="messageProfile__menuExpand">
            {files.map((file) => (
              <li className="messageProfile__menuListExpand">
                <div>
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 12C2 10.5 2 9 2 7.95636C2 4.47818 5.11447 1 8.95636 1C11.5312 1 13.7793 2.39892 14.9821 4.47818H15.9127C18.7941 4.47818 21.5 6.5 21.5 9C21.5 10 21.5 11 21 12"
                      stroke="#14142B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.41666 18.3316L11.5 22.2499L15.5833 18.3316"
                      stroke="#14142B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.5 21.2193L11.5 10.125"
                      stroke="#14142B"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleDownloadFile(file.url)}
                  >
                    {formatNameFile(file.url)}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </li>
      </ul>
      {showImage && (
        <PopupImagesGallery
          closePopup={() => setShowImage(null)}
          images={[showImage]}
        />
      )}
    </div>
  ) : (
    ""
  );
};

export default MessageProfile;
