import { useHistory } from "react-router";
import Loading from "../Loading";
import "./StepBar.css";

const StepBar = ({
  completedStepOne,
  completedStepTwo,
  completedStepThree,
  completedStepFour,
  completedStepFive,
  completedStepSix,
  loading,
}) => {
  const history = useHistory();

  const handleNext = () => {
    // when we in villas page and when clicked the button send to activity page
    if (completedStepOne) {
      history.push("/activities");
    }

    // when we in activities page and when clicked the button send to decorations page
    if (completedStepTwo) {
      history.push("/decorations");
    }

    // when we in decorations page and when clicked the button send to coffrets page
    if (completedStepThree) {
      history.push("/coffrets");
    }

    // when we in coffrets page and when clicked the button send to payment page
    if (completedStepFour) {
      history.push("/payment");
    }
  };

  return (
    <div className="stepBar">
      <ul className="stepBar__steps container">
        <li
          className={`stepBar__step ${
            completedStepOne ? "completed" : loading && "loading"
          }`}
          onClick={() => history.push("/destinations")}
        >
          {!completedStepOne && loading ? (
            <Loading />
          ) : completedStepOne ? (
            <i className="tick-mark"></i>
          ) : (
            <img
              src="/images/icon/clock.svg"
              alt="clock icon"
              className="stepBar__clockIcon"
            />
          )}
          <span>Destination</span>
        </li>
        <li
          className={`stepBar__step ${
            completedStepTwo
              ? "completed"
              : completedStepOne && loading && "loading"
          }`}
          onClick={() => history.push("/villas")}
        >
          {completedStepOne && !completedStepTwo && loading ? (
            <Loading />
          ) : completedStepTwo ? (
            <i className="tick-mark"></i>
          ) : (
            <img
              src="/images/icon/clock.svg"
              alt="clock icon"
              className="stepBar__clockIcon"
            />
          )}
          <span>Villa</span>
        </li>
        <li
          className={`stepBar__step ${
            completedStepThree
              ? "completed"
              : completedStepTwo && loading && "loading"
          }`}
          onClick={() => history.push("/activities")}
        >
          {completedStepTwo && !completedStepThree && loading ? (
            <Loading />
          ) : completedStepThree ? (
            <i className="tick-mark"></i>
          ) : (
            <img
              src="/images/icon/clock.svg"
              alt="clock icon"
              className="stepBar__clockIcon"
            />
          )}
          <span>Activités</span>
        </li>
        <li
          className={`stepBar__step ${
            completedStepFour
              ? "completed"
              : completedStepThree && loading && "loading"
          }`}
          onClick={() => history.push("/decorations")}
        >
          {completedStepThree && !completedStepFour && loading ? (
            <Loading />
          ) : completedStepFour ? (
            <i className="tick-mark"></i>
          ) : (
            <img
              src="/images/icon/clock.svg"
              alt="clock icon"
              className="stepBar__clockIcon"
            />
          )}
          <span>Décoration</span>
        </li>
        <li
          className={`stepBar__step ${
            completedStepFive
              ? "completed"
              : completedStepFour && loading && "loading"
          }`}
          onClick={() => history.push("/coffrets")}
        >
          {completedStepFour && !completedStepFive && loading ? (
            <Loading />
          ) : completedStepFive ? (
            <i className="tick-mark"></i>
          ) : (
            <img
              src="/images/icon/clock.svg"
              alt="clock icon"
              className="stepBar__clockIcon"
            />
          )}
          <span>Accessoires</span>
        </li>
        <li
          className={`stepBar__step ${
            completedStepSix
              ? "completed"
              : completedStepFive && loading && "loading"
          }`}
          onClick={() => history.push("/payment")}
        >
          {completedStepFive && !completedStepSix && loading ? (
            <Loading />
          ) : completedStepSix ? (
            <i className="tick-mark"></i>
          ) : (
            <img
              src="/images/icon/clock.svg"
              alt="clock icon"
              className="stepBar__clockIcon"
            />
          )}
          <span>Finalisation</span>
        </li>
      </ul>
      {!completedStepFive && (
        <button className="activitiesCard__btnNext" onClick={handleNext}>
          Passer l’étape
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2827 5L20.9998 12L14.2827 19"
              stroke="#A94E4D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1="19.7329"
              y1="12.0317"
              x2="3.99985"
              y2="12.0317"
              stroke="#A94E4D"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default StepBar;
