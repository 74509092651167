import CorporateEventBanner from "../../components/CorporateEventComponent/CorporateEventBanner";
import CorporatePartner from "../../components/CorporatePartner";
import EvenementGrid from "../../components/EvenementGrid";
import AskDevis from "../../components/PrivateEventComponent/AskDevis";

const CorporateEvent = () => {
  return (
    <>
      <CorporateEventBanner />
      <div className="container evenement__container">
        <div className="evenement__description">
          <p>
            Chez Maiden Agency, nous sommes spécialisés dans la création et la
            réalisation d’événements corporate qui laissent une impression
            durable.
            <br />
            Que ce soit pour un événement en interne visant à renforcer la
            cohésion d’équipe, un lancement de produit spectaculaire pour
            marquer les esprits, ou toute autre occasion spéciale, notre épique
            expérimentée est là pour vous aider à transformer votre vision en
            réalité.
            <br />
            <br />
            Grâce à notre expertise et notre créativité, nous concevons des
            événements sur mesure qui reflètent parfaitement l’identité et les
            objectifs de votre entreprise.
            <br />
            De la planification minutieuse à la gestion le jour J, nous assurons
            chaque détail pour garantir le succès de votre événement.
            <br />
            <br />
            En faisant appel à notre agence, vous bénéficiez d’un service
            personnalisé et professionnel, d’une attention méticuleuse aux
            détails, et d’une exécution sans faille.
            <br />
            Laissez-nous prendre en charge la logistique, afin que vous puissiez
            vous concentrer sur ce qui compte vraiment : Créer des moments
            inoubliables pour votre entreprise et vos invités.
            <br />
            <br />
            Sachez que nous pouvons si vous le souhaitez intervenir en marque
            blanche.
          </p>
        </div>
      </div>

      <CorporatePartner />
      <EvenementGrid />
      <AskDevis />
    </>
  );
};

export default CorporateEvent;
