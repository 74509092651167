const DecorationsDescription = ({ handleNext }) => {
  return (
    <div>
      <p className="decorations__description">
        A l'écoute de vos rêves et de vos désirs, attentives à chaque détail, nous imaginons, et orchestrons vos évènements. Maiden Agency met à votre service son expertise, son savoir-faire et sa créativité pour vous conseiller et réaliser ensemble votre événement haut de gamme tout en intégrant des thèmes, des mises en page de design, des styles d'événement et un décor dans le but ultime de faire de votre événement une expérience élégante, unique et mémorable.
      </p>
      <button className="decorations__btn btn" onClick={handleNext}>
        <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.84809 2.51481C1.27284 4.09006 1.27284 6.64405 2.8481 8.2193L8.95799 14.3292L8.99998 14.2872L9.04203 14.3292L15.1519 8.21935C16.7272 6.6441 16.7272 4.09011 15.1519 2.51486C13.5767 0.939613 11.0227 0.939614 9.44744 2.51486L9.35359 2.60871C9.15833 2.80398 8.84174 2.80397 8.64648 2.60871L8.55258 2.51481C6.97733 0.939561 4.42334 0.939562 2.84809 2.51481Z"
            stroke="#FCFCFC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Oui, je veux profiter de l'expertise Maiden
      </button>
    </div>
  );
};

export default DecorationsDescription;
