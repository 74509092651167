const Loading = () => {
  return (
    <div className="loading-animation">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Loading;
