// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardSkeleton {
  display: flex;
  /* justify-content: space-between; */
  max-width: 20rem;
  flex-direction: column;
  background: var(--clr-light);
  /* padding: 2em; */
  padding: 1.25em 1em;
  /* gap: 2rem; */
  width: 100%;
  flex-shrink: 0;
  gap: 1rem;
  min-height: 5rem;
  align-items: center;
}

.cardSkeleton__text {
  font-size: 0.85rem;
  /* max-width: 9rem; */
  flex: 1 1 50%;
  width: 100%;
  line-height: 1.8;
  color: var(--clr-gray800);
}

.cardSkeleton__text > div:first-of-type {
  margin-bottom: 0.25rem;
}

.cardSkeleton__iconContainer {
  overflow: hidden;
  border-radius: 1em;
  width: 100%;
  height: 6rem;
}

.cardSkeleton .skeleton-card {
  background: #efefef;
}

@media (min-width: 375px) {
  .cardSkeleton {
    /* max-width: 20rem; */
    padding: 1.5em 1.25em;
    gap: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Skeleton/CardSkeleton/CardSkeleton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;EACpC,gBAAgB;EAChB,sBAAsB;EACtB,4BAA4B;EAC5B,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,cAAc;EACd,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,qBAAqB;IACrB,SAAS;EACX;AACF","sourcesContent":[".cardSkeleton {\n  display: flex;\n  /* justify-content: space-between; */\n  max-width: 20rem;\n  flex-direction: column;\n  background: var(--clr-light);\n  /* padding: 2em; */\n  padding: 1.25em 1em;\n  /* gap: 2rem; */\n  width: 100%;\n  flex-shrink: 0;\n  gap: 1rem;\n  min-height: 5rem;\n  align-items: center;\n}\n\n.cardSkeleton__text {\n  font-size: 0.85rem;\n  /* max-width: 9rem; */\n  flex: 1 1 50%;\n  width: 100%;\n  line-height: 1.8;\n  color: var(--clr-gray800);\n}\n\n.cardSkeleton__text > div:first-of-type {\n  margin-bottom: 0.25rem;\n}\n\n.cardSkeleton__iconContainer {\n  overflow: hidden;\n  border-radius: 1em;\n  width: 100%;\n  height: 6rem;\n}\n\n.cardSkeleton .skeleton-card {\n  background: #efefef;\n}\n\n@media (min-width: 375px) {\n  .cardSkeleton {\n    /* max-width: 20rem; */\n    padding: 1.5em 1.25em;\n    gap: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
