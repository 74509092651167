import axios from "axios";
import { FAQ_FAIL, FAQ_REQUEST, FAQ_SUCCESS } from "../constants/faqConstants";

export const getFaq = () => async (dispatch) => {
  dispatch({ type: FAQ_REQUEST });
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/public/faq_questions`);
    dispatch({ type: FAQ_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: FAQ_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};