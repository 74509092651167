// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reservationSection {
  width: 100%;
}

.reservationSection .cardSkeleton {
  background: var(--clr-secondary);
  max-width: unset;
  border-radius: 2em;
  height: 22rem;
}
.reservationSection .cardSkeleton__iconContainer {
  height: 10rem;
}

.reservationSection .skeleton-card {
  background: #fff;
}

.reservationSection .skeleton-card::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(229 229 229 / 74%),
    transparent
  );
}

.reservationSection h2 {
  font-weight: bold;
  color: var(--clir-black);
}

.reservationSection__items {
  padding: 3em 0;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

@media (min-width: 768px) {
  .reservationSection h2 {
    font-size: 1.75rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ReservationComponents/ReservationSection/ReservationSection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;;;;;GAKC;AACH;;AAEA;EACE,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,aAAa;EACb,WAAW;EACX,4DAA4D;AAC9D;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".reservationSection {\n  width: 100%;\n}\n\n.reservationSection .cardSkeleton {\n  background: var(--clr-secondary);\n  max-width: unset;\n  border-radius: 2em;\n  height: 22rem;\n}\n.reservationSection .cardSkeleton__iconContainer {\n  height: 10rem;\n}\n\n.reservationSection .skeleton-card {\n  background: #fff;\n}\n\n.reservationSection .skeleton-card::after {\n  background: linear-gradient(\n    90deg,\n    transparent,\n    rgb(229 229 229 / 74%),\n    transparent\n  );\n}\n\n.reservationSection h2 {\n  font-weight: bold;\n  color: var(--clir-black);\n}\n\n.reservationSection__items {\n  padding: 3em 0;\n  display: grid;\n  gap: 1.5rem;\n  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));\n}\n\n@media (min-width: 768px) {\n  .reservationSection h2 {\n    font-size: 1.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
