import Hero from "../../components/Hero";
import StepBar from "../../components/StepBar";
import DecorationsDescription from "../../components/DecorationComponents/DecorationsDescription";
import { useState } from "react";
import "./Decorations.css";
import DecorationsServices from "../../components/DecorationComponents/DecorationsServices";
import { useSelector } from "react-redux";
import { images } from "../../utils/decorations";
import HERO from "../../assets/hero/decoration_page.jpeg";

const Decorations = () => {
  const [next, setNext] = useState(false);
  const { destination } = useSelector((state) => state.destination);
  const { dataRequest } = useSelector((state) => state.request);

  const handleSlideImage = () => {
    let currentImage = 0;
    // getALlImage
    const getImagesContainer = document.querySelector(
      ".decorations__imgContainer"
    );
    const getImages = document.querySelectorAll(".decorations__img");
    // let firstImage = getImages[currentImage++].cloneNode(true);
    getImagesContainer.removeChild(getImages[currentImage]);
    getImagesContainer.appendChild(getImages[currentImage]);
    currentImage++;
  };

  return (
    <div className="decorations">
      <Hero title="Votre service décoration" heroImg={HERO} isDestination={false} />

      <section className="decorations__container container">
        <div className="decorations__content">
          <h2 className="decorations__title">
            Découvrez le Service Décoration <em>by Maiden Agency</em>
          </h2>
          {next ? (
            <DecorationsServices
              dataRequest={dataRequest}
              destination={destination}
            />
          ) : (
            <DecorationsDescription handleNext={() => setNext(true)} />
          )}
        </div>

        <div className="decorations__imageSection">
          <div className="decorations__imgContainer">
            {images.map((image) => (
              <img
                key={image.id}
                src={image.img}
                alt=""
                className="decorations__img"
              />
            ))}
          </div>
          <div
            className="decorations__imgContainerArrow"
            onClick={handleSlideImage}
          >
            <svg
              viewBox="0 0 78 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.5 16.25L55.1303 38.8803L32.5 61.5106"
                stroke="#14142B"
                strokeWidth="6.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </section>
      <StepBar completedStepOne completedStepTwo completedStepThree loading />
    </div>
  );
};

export default Decorations;
