import {
  MESSAGE_ROOMS_FAIL,
  MESSAGE_ROOMS_REQUEST,
  MESSAGE_ROOMS_SUCCESS,
  MESSAGE_ROOM_FAIL,
  MESSAGE_ROOM_REQUEST,
  MESSAGE_ROOM_RESET,
  MESSAGE_ROOM_SUCCESS,
} from "../constants/messageConstants";

export const messageRoomsReducers = (state = { rooms: [] }, action) => {
  switch (action.type) {
    case MESSAGE_ROOMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_ROOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        rooms: action.payload,
      };
    case MESSAGE_ROOMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const messageRoomReducers = (state = { room: {} }, action) => {
  switch (action.type) {
    case MESSAGE_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        room: action.payload,
      };
    case MESSAGE_ROOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MESSAGE_ROOM_RESET:
      return {
        ...state,
        loading: false,
        room: {},
      };

    default:
      return state;
  }
};
