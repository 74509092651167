// import GoodiesCard from "../GoodiesComponents/GoodiesCard";
import goodiesSkeleton from "../../../utils/goodies.json";
import "./Goodies.css";
import { useEffect, useState } from "react";
// import SmallCardSkeleton from "../../Skeleton/SmallCardSkeleton";
import CoffretsCard from "../CoffretsCard";
import CardSkeleton from "../../Skeleton/CardSkeleton";

const Goodies = ({ goodies, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [goodiesPerPage, setPerGoodiesPerPage] = useState(4);
  // const goodiesProgressBars = useRef(null);

  const indexOfLastGoods = currentPage * goodiesPerPage;
  const indexOfFirstGoods = indexOfLastGoods - goodiesPerPage;
  const currentGoodies =
    !loading && goodies.slice(indexOfFirstGoods, indexOfLastGoods);
  const nextGoodsSection = () => {
    setCurrentPage((currentPage) => currentPage + 1);
    // goodiesProgressBars.current.style.width = `${
    //   (((currentPage + 1) * goodiesPerPage) / goodies.length) * 100
    // }%`;
  };

  const prevGoodsSection = () => {
    setCurrentPage((currentPage) => currentPage - 1);
    // goodiesProgressBars.current.style.width = `${
    //   (((currentPage - 1) * goodiesPerPage) / goodies.length) * 100
    // }%`;
  };

  const moreGoods = () => {
    setPerGoodiesPerPage((goodiesPerPage) => goodiesPerPage + 6);
  };

  useEffect(() => {
    // if (goodiesProgressBars.current) {
    //   goodiesProgressBars.current.style.width = `${
    //     ((currentPage * goodiesPerPage) / goodies.length) * 100
    //   }%`;
    // }

    window.onresize = () => {
      if (window.innerWidth > 992) {
        setPerGoodiesPerPage(4);
      }
    };

    // get goodies
  }, []);

  return (
    <section className="goodies">
      <h2 className="coffrets__title">Accessoires</h2>
      {currentPage > 1 && (
        <svg
          onClick={prevGoodsSection}
          className="goodies__btnNav left"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.3333 16.6667L56.5439 39.8773L33.3333 63.0878"
            stroke="#14142B"
            strokeWidth="6.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      <div className="goodies__container" id="goodies-section">
        {loading ? (
          goodiesSkeleton.map((_, idx) => idx < 4 && <CardSkeleton key={idx} />)
        ) : !goodies.length ? (
          <div className="loading-container-text h-full">
            Désolé, une erreur s'est produite
          </div>
        ) : currentGoodies.length ? (
          currentGoodies.map((goods) => (
            <CoffretsCard coffret={goods} key={goods.id} type="product" />
          ))
        ) : (
          goodies.map(
            (goods, idx) =>
              idx < 4 && (
                <CoffretsCard type="product" coffret={goods} key={goods.id} />
              )
          )
        )}
      </div>
      {Math.ceil(goodies.length / goodiesPerPage) !== 1 && (
        <div className="goodies__seeMore" onClick={moreGoods}>
          Voir Plus
        </div>
      )}

      {/* {Math.ceil(goodies.length / goodiesPerPage) !== 1 && (
        <div className="goodies__pagination">
          <div className="goodies__paginationProgressContainer">
            <span
              ref={goodiesProgressBars}
              className="goodies__paginationProgressBar"
            ></span>
          </div>
        </div>
      )} */}
      {currentPage < Math.ceil(goodies.length / goodiesPerPage) && (
        <svg
          onClick={nextGoodsSection}
          className="goodies__btnNav right"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.3333 16.6667L56.5439 39.8773L33.3333 63.0878"
            stroke="#14142B"
            strokeWidth="6.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </section>
  );
};

export default Goodies;
