import Footer from "./components/Footer";
import Header from "./components/Header";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Destinations from "./pages/Destinations";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Activities from "./pages/Activities";
import Villas from "./pages/Villas";
import PrivateEvent from "./pages/PrivateEvent";
import Decorations from "./pages/Decorations";
import Coffrets from "./pages/Coffrets";
import Article from "./pages/Article";
import Payment from "./pages/Payment";
import Message from "./pages/Message";
import Quizz from "./pages/Quizz";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Auth from "./pages/Auth";
import Cgv from "./pages/Cgv";
import MentionLegal from "./pages/MentionLegal";
import ForgotPassword from "./pages/ForgotPassword";
import Evenement from "./pages/Evenement";
import { useEffect } from "react";
import CorporateEvent from "./pages/CorporateEvent";

function App() {
  useEffect(() => {
    window.onbeforeunload = function () {
      localStorage.removeItem("maiden_data");
    };
  }, []);

  return (
    <Router>
      <Header />
      <div>
        <Route component={Evenement} exact path="/" />
        <Route component={Blog} exact path="/blog" />
        <Route component={Article} exact path="/blog/:id" />
        <Route component={PrivateEvent} exact path="/privateEvent" />
        <Route component={CorporateEvent} exact path="/corporateEvent" />
        <Route component={Destinations} exact path="/destinations" />
        <Route component={Destinations} exact path="/destinations/:query" />
        <Route component={Activities} exact path="/activities" />
        <Route component={Villas} exact path="/villas" />
        <Route component={Villas} exact path="/villas/:id" />
        <Route component={Decorations} exact path="/decorations" />
        <Route component={Coffrets} exact path="/coffrets" />
        <Route component={Payment} exact path="/payment" />
        <Route component={Message} exact path="/message" />
        <Route component={Message} exact path="/reservation" />
        <Route component={Message} exact path="/reservation/:reservationTab" />
        <Route component={Quizz} exact path="/quizz" />
        <Route component={Faq} exact path="/faq" />
        <Route component={Contact} exact path="/contact" />
        <Route component={Auth} exact path="/auth" />
        <Route component={Auth} exact path="/auth/:authType" />
        <Route component={Cgv} exact path="/cgv" />
        <Route component={MentionLegal} exact path="/mention-legales" />
        <Route component={ForgotPassword} exact path="/users/password/edit" />
        <Route component={Home} exact path="/evjf" />
      </div>
      <Footer />
    </Router>
  );
}

export default App;
