import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./MessageMenu.css";

const MessageMenu = () => {
  const history = useHistory();
  const { rooms } = useSelector((state) => state.rooms);
  const { user } = useSelector((state) => state.createRequest);
  const tab = window.location.pathname;

  const handleChangeTab = (tab) => {
    history.push(`/${tab}`);
  };

  return (
    <div className="messageMenu">
      <div className="messageMenu__nav">
        <h4 className="messageMenu__menuTitle">tableau de bord</h4>
        <ul className="messageMenu__menuItems">
          <li
            className={`messageMenu__menuItem ${
              tab?.includes("message") && "active"
            }`}
            onClick={() => handleChangeTab("message")}
          >
            <div>
              <svg
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00098 2C3.68727 2 1.00098 4.68629 1.00098 8V12C1.00098 12.2403 1.01511 12.4774 1.04259 12.7104C1.01489 12.802 1 12.8993 1 13V21.8258C1 22.6801 2.00212 23.141 2.65079 22.585L7.43827 18.4815C7.80075 18.1708 8.26243 18 8.73985 18H19.001C22.3147 18 25.001 15.3137 25.001 12V8C25.001 4.68629 22.3147 2 19.001 2H7.00098Z"
                  strokeWidth="2"
                />
              </svg>
              <span>Message</span>
            </div>
            {rooms.filter((room) => room?.request?.client_id === user?.user_id)
              .length ? (
              <span className="messageMenu__unread">
                {
                  rooms.filter(
                    (room) => room?.request?.client_id === user?.user_id
                  ).length
                }
              </span>
            ) : null}
          </li>
          <li
            className={`messageMenu__menuItem ${
              tab?.includes("reservation") && "active"
            }`}
            onClick={() => handleChangeTab("reservation/payment-prosses")}
          >
            <div>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect
                  x="2"
                  y="3"
                  width="20"
                  height="19"
                  rx="3"
                  strokeWidth="2"
                />
                <path
                  d="M7 1V3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17 1V3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 8H22"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 13H9.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 13H16.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 17H9.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 17H16.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Réservations</span>
            </div>
          </li>
          {/* <li
            className={`messageMenu__menuItem ${
              tab?.includes("profile") && "active"
            }`}
          >
            <div>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.9506 17.3802C17.3783 16.5669 14.7849 15.5 12 15.5C9.21513 15.5 6.62175 16.5669 5.04942 17.3802C4.03431 17.9052 3.42295 18.9351 3.2883 20.07L3 22.5H21L20.7117 20.07C20.577 18.9351 19.9657 17.9052 18.9506 17.3802Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 11C14.4853 11 16.5 8.98528 16.5 6.5C16.5 4.01472 14.4853 2 12 2C9.51472 2 7.5 4.01472 7.5 6.5C7.5 8.98528 9.51472 11 12 11Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span> Mon profil</span>
            </div>
          </li> */}
        </ul>
      </div>
      {/* <div className="messageMenu__setting">
        <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13" cy="13" r="4" stroke="#A94E4D" strokeWidth="2" />
          <path
            d="M11.2844 2.86293C12.061 1.56696 13.939 1.56696 14.7156 2.86293L15.4213 4.0407C15.8742 4.79649 16.7684 5.16686 17.6231 4.95268L18.9549 4.61892C20.4204 4.25166 21.7483 5.57958 21.3811 7.0451L21.0473 8.37695C20.8331 9.23162 21.2035 10.1258 21.9593 10.5787L23.1371 11.2844C24.433 12.061 24.433 13.939 23.1371 14.7156L21.9593 15.4213C21.2035 15.8742 20.8331 16.7684 21.0473 17.6231L21.3811 18.9549C21.7483 20.4204 20.4204 21.7483 18.9549 21.3811L17.6231 21.0473C16.7684 20.8331 15.8742 21.2035 15.4213 21.9593L14.7156 23.1371C13.939 24.433 12.061 24.433 11.2844 23.1371L10.5787 21.9593C10.1258 21.2035 9.23162 20.8331 8.37695 21.0473L7.04509 21.3811C5.57958 21.7483 4.25166 20.4204 4.61892 18.9549L4.95268 17.6231C5.16686 16.7684 4.79649 15.8742 4.04069 15.4213L2.86293 14.7156C1.56696 13.939 1.56696 12.061 2.86293 11.2844L4.0407 10.5787C4.79649 10.1258 5.16686 9.23162 4.95268 8.37695L4.61892 7.04509C4.25166 5.57958 5.57958 4.25166 7.0451 4.61892L8.37695 4.95268C9.23162 5.16686 10.1258 4.79649 10.5787 4.04069L11.2844 2.86293Z"
            stroke="#A94E4D"
            strokeWidth="2"
          />
        </svg>
        Paramètres
      </div> */}
    </div>
  );
};

export default MessageMenu;
