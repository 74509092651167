import Hero from "../../components/Hero";
import ActivitiesSection from "../../components/ActivitiesComponent/ActivitiesSection";
import StepBar from "../../components/StepBar";
// import activities from "../../utils/activities.json";
import "./Activities.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getActivities } from "../../actions/activityActions";
import Loading from "../../components/Loading";
import HERO from "../../assets/hero/activity_page.jpg";
import axios from "axios";

const Activities = () => {
  const dispatch = useDispatch();
  const { activities, loading } = useSelector((state) => state.activity);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("maiden_data"))?.destination_id) {
      let destinationId = JSON.parse(
        localStorage.getItem("maiden_data")
      ).destination_id;
      if (destinationId) {
        (async function () {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/public/destinations`
          );
          const destination = data.find((d) => d.id === destinationId);
          dispatch(getActivities(destination.city));
        })();
      }
    } else {
      dispatch(getActivities());
    }
  }, [dispatch]);

  return (
    <div className="activities">
      <Hero title="Sélectionnez vos activités" heroImg={HERO} />

      <div className="activities__container container">
        {loading ? (
          <div className="loading-container-text h-full">
            <Loading />
            S'il vous plaît, attendez...
          </div>
        ) : !activities.length ? (
          <div className="loading-container-text h-full">
            Aucune activité disponible pour cette destination
          </div>
        ) : (
          activities.map((activity, index) => (
            <ActivitiesSection activity={activity} key={index} />
          ))
        )}
      </div>
      <StepBar completedStepOne completedStepTwo loading />
    </div>
  );
};

export default Activities;
