export const emailPatten =
  /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
// export const passwordPatten =
//   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const passwordPatten = /^(?=.*?[0-9]).{6,}$/;

export const validateConnect = (values) => {
  let errors = {};
  if (values.first_name.trim() === "") {
    errors.first_name = "Le prénom ne peut pas être vide";
  }
  if (values.last_name.trim() === "") {
    errors.last_name = "Le nom de famille ne peut pas être vide";
  }
  if (values.phone.trim() === "") {
    errors.phone = "Le téléphone ne peut pas être vide";
  }

  if (values.email.trim() === "") {
    errors.email = "L'e-mail ne peut pas être vide";
  } else {
    if (!values.email.match(emailPatten)) {
      errors.email = "L'e-mail doit être une adresse e-mail valide";
    }
  }

  return {
    errors,
    valid: Object.keys(errors).length < 1,
  };
};

export const validateLogin = (values) => {
  let errors = {};
  if (values?.email.trim() === "") {
    errors.emailLogin = "L'e-mail ne peut pas être vide";
  } else {
    if (!values?.email.match(emailPatten)) {
      errors.emailLogin = "L'e-mail doit être une adresse e-mail valide";
    }
  }

  if (values?.password.trim() === "") {
    errors.password = "Le mot de passe ne peut pas être vide";
  }

  return {
    errors,
    valid: Object.keys(errors).length < 1,
  };
};

export const validateResetPassword = (values) => {
  let errors = {};
  if (!values.password.trim()) {
    errors.password = "Le mot de passe ne peut pas être vide";
  } else {
    if (!values.password.match(passwordPatten)) {
      errors.password =
        "Le mot de passe comporte au moins 6 caractères et il y a 1 chiffre";
    } else {
      if (!values.confirmPassword.trim()) {
        errors.confirmPassword =
          "Confirmer le mot de passe ne peut pas être vide";
      }
      if (values.confirmPassword !== values.password) {
        errors.password =
          "Confirmer le mot de passe et le mot de passe doit être le même";
        errors.confirmPassword =
          "Confirmer le mot de passe et le mot de passe doit être le même";
      }
    }
  }

  return {
    errors,
    valid: Object.keys(errors).length < 1,
  };
};

export const validateContact = (values) => {
  let errors = {};
  if (values.first_name.trim() === "") {
    errors.first_name = "Le prénom ne peut pas être vide";
  }
  if (values.last_name.trim() === "") {
    errors.last_name = "Le nom de famille ne peut pas être vide";
  }
  if (values.phone.trim() === "") {
    errors.phone = "Le téléphone ne peut pas être vide";
  }

  if (values.message.trim() === "") {
    errors.message = "Le message ne peut pas être vide";
  }

  if (values.email.trim() === "") {
    errors.email = "L'e-mail ne peut pas être vide";
  } else {
    if (!values.email.match(emailPatten)) {
      errors.email = "L'e-mail doit être une adresse e-mail valide";
    }
  }

  return {
    errors,
    valid: Object.keys(errors).length < 1,
  };
};
