import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRequest } from "../../actions/requestActions";
import hamMenuIcon from "../../assets/hamMenu.svg";
import searchIcon from "../../assets/search.svg";
import "./Delivery.css";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import useGeolocation from "../../hooks/useGeolocation";
import { getVilla } from "../../actions/villaActions";
import Geocode from "react-geocode";
import { useRef } from "react";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEOCODING_API);

const Delivery = ({
  closePopup,
  show,
  handleSelectLocation,
  popup,
  dataRequest,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  });
  const dispatch = useDispatch();
  const [delivery, setDelivery] = useState("");
  const [location, setLocation] = useGeolocation();
  const [address, setAddress] = useState("");
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const inputAddressMap = useRef(null);
  const { villa } = useSelector((state) => state.villa);

  const handleClickMap = (e) => {
    handleChangeAddress(e.latLng.lat(), e.latLng.lng());
    setLocation({
      ...location,
      coordinates: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const handleChangeDelivery = (d) => {
    setDelivery(d);
    if (!popup) {
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,

          delivery:
            d === "home"
              ? address
              : d === "destination"
              ? villa?.title
              : `Point de retrait au choix :
          2 rue Aumont Thieville 75017 paris
          152 bis boulevard Haussmann 75008 paris
          
          Un rendez-vous vous sera proposé lors de la
          confirmation de votre réservation`,
        })
      );
    }
  };

  const handleChangeAddress = (lat, lng) => {
    Geocode.fromLatLng(`${lat}`, `${lng}`).then((response) => {
      setAddress(response.results[0].formatted_address);
      dispatch(
        setRequest({
          ...dataRequest,
          tag: "original",
          offer_id: null,
          delivery: response.results[0].formatted_address,
        })
      );
    });
  };

  const handleSearchAddress = (e) => {
    e.preventDefault();
    Geocode.fromAddress(inputAddressMap.current?.value).then((response) => {
      const { lat, lng } = response.results[0].geometry.location;
      Geocode.fromLatLng(`${lat}`, `${lng}`).then((response) => {
        setAddress(response.results[0].formatted_address);
        dispatch(
          setRequest({
            ...dataRequest,
            tag: "original",
            offer_id: null,
            delivery: response.results[0].formatted_address,
          })
        );
      });
      setLocation({
        ...location,
        coordinates: {
          lat: lat,
          lng: lng,
        },
      });
    });
  };

  useEffect(() => {
    dispatch(getVilla(dataRequest.property));
  }, [dataRequest, dispatch]);

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <div
        className={`delivery ${!popup && "no-popup"} ${
          show && popup && "show"
        }`}
      >
        <div className="delivery__header">
          <div className="delivery__HeaderText">
            <h4>Livraison</h4>
            <span>Où souhaitez-vous etre livré ?</span>
          </div>
          {popup && (
            <button
              className="filterPopup__close"
              onClick={() => closePopup(delivery)}
            >
              <i className="cross-mark"></i>
            </button>
          )}
        </div>
        <div className="delivery__options">
          <label htmlFor={`delivery-home`} className="radio-container">
            <input
              type="radio"
              onChange={() => handleChangeDelivery("home")}
              id={`delivery-home`}
              name="delivery"
              checked={delivery === "home"}
              className="radio-input"
            />
            <span className="radio"></span>À domicile
          </label>
          {dataRequest.property && (
            <label htmlFor={`delivery-destination`} className="radio-container">
              <input
                type="radio"
                onChange={() => handleChangeDelivery("destination")}
                id={`delivery-destination`}
                name="delivery"
                checked={delivery === "destination"}
                className="radio-input"
              />
              <span className="radio"></span>À la destination
            </label>
          )}

          <label htmlFor={`delivery-Ile-de-France`} className="radio-container">
            <input
              type="radio"
              onChange={() => handleChangeDelivery("Ile-de-France")}
              id={`delivery-Ile-de-France`}
              name="delivery"
              checked={delivery === "Ile-de-France"}
              className="radio-input"
            />
            <span className="radio"></span>RMP en Ile-de-France
          </label>
        </div>
        {delivery === "home" && (
          <>
            <Autocomplete>
              <form
                className="delivery__maps form"
                onSubmit={handleSearchAddress}
              >
                <img src={hamMenuIcon} alt="" className="delivery__mapsMenu" />
                <input
                  type="text"
                  placeholder="Rechercher sur Google Maps"
                  ref={inputAddressMap}
                  onChange={(e) =>
                    dispatch(
                      setRequest({
                        ...dataRequest,
                        tag: "original",
                        offer_id: null,
                        delivery: e.target.value,
                      })
                    )
                  }
                />
                <img
                  src={searchIcon}
                  alt=""
                  onClick={handleSearchAddress}
                  className="delivery__mapsSearch"
                />
              </form>
            </Autocomplete>
          </>
        )}
        {delivery === "Ile-de-France" && (
          <div className="delivery__maps text">
            Point de retrait au choix : <br />
            <ul>
              <li>2, rue Aumont-Thiéville, 75017 Paris</li>
              <li>152, Boulevard Haussmann 75008 Paris</li>
            </ul>
            <br />
            Un rendez-vous vous sera proposé lors de la
            <br />
            confirmation de votre réservation
          </div>
        )}
        {popup && (
          <button
            className="delivery__btn btn"
            onClick={() => handleSelectLocation(delivery)}
          >
            Sélectionner un point de retrait
            <svg
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 5L8.5 1L16.5 5L23.5 1V19L16.5 23L8.5 19L1.5 23V5Z"
                stroke="#FCFCFC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M16.5 5V22.5" stroke="#FCFCFC" strokeWidth="2" />
              <path d="M8.5 1.5V19" stroke="#FCFCFC" strokeWidth="2" />
            </svg>
          </button>
        )}
      </div>
      {delivery === "home" && !location.err && (
        <div className="delivery__mapContainer">
          {!location.err ? (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={{
                lat: location.coordinates?.lat,
                lng: location.coordinates?.lng,
              }}
              options={{
                mapTypeControl: false,
                streetViewControl: false,
              }}
              zoom={15}
              onLoad={() =>
                handleChangeAddress(
                  location.coordinates?.lat,
                  location.coordinates?.lng
                )
              }
              onClick={handleClickMap}
            >
              <Marker
                position={{
                  lat: location.coordinates?.lat,
                  lng: location.coordinates?.lng,
                }}
                onClick={() =>
                  setShowInfoWindow((showInfoWindow) => !showInfoWindow)
                }
              >
                {showInfoWindow && (
                  <InfoWindow
                    position={{
                      lat: location.coordinates?.lat,
                      lng: location.coordinates?.lng,
                    }}
                  >
                    <span>{address}</span>
                  </InfoWindow>
                )}
              </Marker>
            </GoogleMap>
          ) : (
            ""
            // <span className="delivery__errorMap">
            //   S'il vous plaît allumez votre GPS
            // </span>
          )}
        </div>
      )}
    </>
  );
};

export default Delivery;
