import {
  DESTINATIONS_REQUEST,
  DESTINATIONS_SUCCESS,
  DESTINATIONS_FAIL,
  DESTINATION_REQUEST,
  DESTINATION_SUCCESS,
  DESTINATION_FAIL,
  DESTINATION_SEARCH_REQUEST,
  DESTINATION_SEARCH_SUCCESS,
  DESTINATION_SEARCH_FAIL,
  DESTINATION_SUGESSTION_REQUEST,
  DESTINATION_SUGESSTION_SUCCESS,
  DESTINATION_SUGESSTION_FAIL,
} from "../constants/destinationConstants";

export const destinationReducers = (
  state = { destinations: [], suggestions: [], destination: null },
  action
) => {
  switch (action.type) {
    // search
    case DESTINATION_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DESTINATION_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        destinations: action.payload,
      };
    case DESTINATION_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DESTINATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DESTINATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        destinations: action.payload,
        suggestions: action.payload,
      };
    case DESTINATIONS_FAIL:
      return {
        ...state,

        loading: false,
        error: action.payload,
      };

    // one destination
    case DESTINATION_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case DESTINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        destination: action.payload.destination,
        suggestions: action.payload.destinations,
      };
    case DESTINATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const destinationSuggestionReducers = (
  state = { suggestions: [] },
  action
) => {
  switch (action.type) {
    case DESTINATION_SUGESSTION_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case DESTINATION_SUGESSTION_SUCCESS:
      return {
        ...state,
        loading: false,
        suggestions: action.payload,
      };
    case DESTINATION_SUGESSTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
