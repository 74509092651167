// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reservationHeader__tabs {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  border-bottom: 1px solid var(--clr-gray400);
}

.reservationHeader__tabs li {
  padding: 1em 1.5em;
  text-align: center;
  cursor: pointer;
  color: var(--clir-black);
  position: relative;
  font-size: 0.9rem;
  font-weight: bold;
}

.reservationHeader__tabs li.active {
  color: var(--clr-primary);
}

.reservationHeader__tabs li.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  border-bottom: 2px solid var(--clr-primary);
}
`, "",{"version":3,"sources":["webpack://./src/components/ReservationComponents/ReservationHeader/ReservationHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,wBAAwB;EACxB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;;EAEX,2CAA2C;AAC7C","sourcesContent":[".reservationHeader__tabs {\n  display: flex;\n  align-items: center;\n  margin-top: 2rem;\n  width: 100%;\n  border-bottom: 1px solid var(--clr-gray400);\n}\n\n.reservationHeader__tabs li {\n  padding: 1em 1.5em;\n  text-align: center;\n  cursor: pointer;\n  color: var(--clir-black);\n  position: relative;\n  font-size: 0.9rem;\n  font-weight: bold;\n}\n\n.reservationHeader__tabs li.active {\n  color: var(--clr-primary);\n}\n\n.reservationHeader__tabs li.active::after {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n\n  border-bottom: 2px solid var(--clr-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
