import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./ReservationHeader.css";

const ReservationHeader = ({ tab }) => {
  const history = useHistory();

  return (
    <div>
      <ul className="reservationHeader__tabs">
        <li
          className={`${tab === "payment-prosses" && "active"} `}
          onClick={() => history.push("/reservation/payment-prosses")}
        >
          En cours de règlement
        </li>
        <li
          className={`${tab === "soon" && "active"} `}
          onClick={() => history.push("/reservation/soon")}
        >
          À venir
        </li>
        <li
          className={`${tab === "past" && "active"} `}
          onClick={() => history.push("/reservation/past")}
        >
          Passées
        </li>
      </ul>
    </div>
  );
};

export default ReservationHeader;
