import FilterPopupMoreCategory from "./FilterPopupComponents/FilterPopupMoreCategory";
import FilterPopupServices from "./FilterPopupComponents/FilterPopupServices";
import filterLocations from "../../utils/filterLocations.json";
import filterProperties from "../../utils/filterProperties.json";
import filterEquipment from "../../utils/filterEquipment.json";
import filterSportHealthy from "../../utils/filterSportHealthy.json";
import "./FilterPopup.css";
import { useDispatch, useSelector } from "react-redux";
import { filterVilla } from "../../actions/villaActions";
import { VILLAS_RESET_FILTER } from "../../constants/villaConstants";

const FilterPopup = ({ closePopup, show }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.villaFilter);
  const handleClick = () => {
    dispatch(filterVilla(filters));
    closePopup();
  };

  const handleResetFilter = () => {
    dispatch({ type: VILLAS_RESET_FILTER });
  };

  return (
    <div className={`filterPopup ${show && "show"}`}>
      <div className="filterPopup__container">
        <div className="filterPopup__header">
          <h2>Filtres</h2>
          <button className="filterPopup__close" onClick={closePopup}>
            <i className="cross-mark"></i>
          </button>
        </div>
        <div>
          <FilterPopupServices />
          <FilterPopupMoreCategory
            categroyTitle="Type de propriété"
            catergoryName="properties"
            filters={filterProperties}
          />
          <FilterPopupMoreCategory
            categroyTitle="Bien être & sport"
            catergoryName="healties"
            filters={filterSportHealthy}
          />
          <FilterPopupMoreCategory
            categroyTitle="Équipment"
            catergoryName="equipments"
            filters={filterEquipment}
          />
          <FilterPopupMoreCategory
            categroyTitle="Aux alentours"
            catergoryName="arounds"
            filters={filterLocations}
          />
        </div>
      </div>
      <div className="filterPopup__action">
        <button className="btn" onClick={handleResetFilter}>
          Réinitialiser les filtres
        </button>
        <button className="btn" onClick={handleClick}>
          Afficher tous les résultats
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.52179 3.33331L13.9998 7.99998L9.52179 12.6666"
              stroke="#FCFCFC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1="12.822"
              y1="8.35449"
              x2="2.99992"
              y2="8.35449"
              stroke="#FCFCFC"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FilterPopup;
