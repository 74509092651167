import ReservationHeader from "../ReservationHeader";
import "./ReservationSection.css";
import ReservationCard from "../ReservationCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Loading";
import ReservationPopup from "../ReservationPopup";
import axios from "axios";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const ReservationSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { reservationTab } = useParams();
  const [tab, setTab] = useState(reservationTab);

  const [urlParam, setUrlParam] = useState({});
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const { user } = useSelector((state) => state.createRequest);
  const { destinations, loading: loadingDestinations } = useSelector(
    (state) => state.destination
  );
  const { villas, loading: loadingVillas } = useSelector(
    (state) => state.villa
  );

  const handleClose = async () => {
    setUrlParam({});
    try {
      setOrdersLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/orders`,
        {
          headers: {
            Authorization: user.token,
          },
        }
      );

      if (res.status === 204) {
        setOrders(res.data);
        setOrdersLoading(false);
        if (tab === "payment-prosses") {
          history.push("/reservation/payment-prosses");
        } else {
          history.push("/reservation");
        }
      }
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  useEffect(() => {
    async function getOrders() {
      try {
        setOrdersLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/orders`,
          {
            headers: {
              Authorization: user.token,
            },
          }
        );
        setOrders(res.data);
        setOrdersLoading(false);
      } catch (err) {
        setOrdersLoading(false);
        return err;
      }
    }
    (async function () {
      try {
        setLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/quotes`
        );
        if (res.status === 200) {
          setLoading(false);
          setRooms(
            res.data.filter(
              (room) =>
                room?.client_id === user?.user_id &&
                room.aasm_state === "accepted"
            )
          );
        }
      } catch (err) {
        setLoading(false);
        return err;
      }
    })();

    const reservationPaths = ["soon", "past", "payment-prosses"];
    if (reservationTab) {
      const isPath = reservationPaths.find((path) => reservationTab === path);
      if (!isPath) history.go(-1);
    }

    let orderId = window.location.search
      .substring(1)
      .split("&")[0]
      .split("=")[1];

    if (orderId && reservationTab === "payment-prosses") {
      (async function () {
        try {
          const res = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/quotes/update_order_status`,
            { order_id: Number(orderId) },
            {
              headers: {
                Authorization: user.token,
              },
            }
          );
          if (res.status === 204) {
            getOrders();
          }
        } catch (err) {
          console.error(err);
        }
      })();
    } else if (orderId && (reservationTab === "soon" || !reservationTab)) {
      (async function () {
        try {
          const res = await axios.patch(
            `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/update_after_complete_payment`,
            {},
            {
              headers: {
                Authorization: user.token,
              },
            }
          );
          if (res.status === 204) {
            getOrders();
          }
        } catch (err) {
          console.error(err);
        }
      })();
    } else {
      getOrders();
    }

    setTab(!reservationTab ? "soon" : reservationTab);

    window.location.search
      .substring(1)
      .split("&")
      .forEach((loc, idx) => {
        setUrlParam((param) => ({
          ...param,
          [loc.split("=")[0]]: loc.split("=")[1],
        }));
      });

    return () => {
      setRooms([]);
      setOrders([]);
    };
  }, [dispatch, user, history, reservationTab]);

  return (
    <div className="reservationSection">
      <h2>Réservations</h2>
      <ReservationHeader tab={tab} setTab={setTab} />
      <div className="reservationSection__content">
        {loading || ordersLoading ? (
          <div className="loading-container-text h-full">
            <Loading />
            S'il vous plaît, attendez...
          </div>
        ) : rooms.filter((room) => {
            const status = orders.find(
              (order) => order.quote_id === room.id
            )?.status;

            return tab === "past"
              ? status === "paid" &&
                  new Date().getTime() > new Date(room.to).getTime()
              : tab === "soon"
              ? status === "paid" &&
                new Date().getTime() < new Date(room.to).getTime()
              : status === "half_paid";
          }).length ? (
          <div className="reservationSection__items">
            {rooms
              .filter((room) => {
                const status = orders.find(
                  (order) => order.quote_id === room.id
                )?.status;

                return tab === "past"
                  ? status === "paid" &&
                      new Date().getTime() > new Date(room.to).getTime()
                  : tab === "soon"
                  ? status === "paid" &&
                    new Date().getTime() < new Date(room.to).getTime()
                  : status === "half_paid";
              })
              .map((room) => (
                <ReservationCard
                  key={room.id}
                  orderId={
                    orders.find((order) => order.quote_id === room.id)?.id
                  }
                  destinations={destinations}
                  loadingDestinations={loadingDestinations}
                  villas={villas}
                  loadingVillas={loadingVillas}
                  room={room}
                  tab={tab}
                  results={rooms}
                />
              ))}
          </div>
        ) : (
          <div className="loading-container-text h-full">
            Aucune réservation pour le moment
          </div>
        )}
        <ReservationPopup
          show={urlParam.approved}
          text={
            tab === "payment-prosses"
              ? "Vous avez payé 50% et votre réservation est confirmée. N'oubliez pas de solder votre réservation"
              : "Votre réservation a bien été effectuée"
          }
          closePopup={handleClose}
        />
      </div>
    </div>
  );
};

export default ReservationSection;
