import axios from "axios";
import {
  DESTINATIONS_REQUEST,
  DESTINATIONS_SUCCESS,
  DESTINATIONS_FAIL,
  DESTINATION_REQUEST,
  DESTINATION_FAIL,
  DESTINATION_SUCCESS,
  DESTINATION_SEARCH_REQUEST,
  DESTINATION_SEARCH_SUCCESS,
  DESTINATION_SEARCH_FAIL,
  DESTINATION_SUGESSTION_REQUEST,
  DESTINATION_SUGESSTION_SUCCESS,
  DESTINATION_SUGESSTION_FAIL,
} from "../constants/destinationConstants";

export const getDestinations = () => async (dispatch) => {
  dispatch({ type: DESTINATIONS_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/destinations`
    );
    dispatch({
      type: DESTINATIONS_SUCCESS,
      payload: data.sort(
        (a, b) =>
          (a.country < b.country ? -1 : a.country > b.country ? 1 : 0) ||
          (a.region < b.region ? -1 : a.region > b.region ? 1 : 0)
      ),
    });
  } catch (err) {
    dispatch({
      type: DESTINATIONS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getDestination = (id) => async (dispatch) => {
  dispatch({ type: DESTINATION_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/destinations`
    );
    const destination = data.find((d) => d.id === id);
    dispatch({
      type: DESTINATION_SUCCESS,
      payload: { destination, destinations: data },
    });
  } catch (err) {
    dispatch({
      type: DESTINATION_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const destinationSearch = (search) => async (dispatch) => {
  dispatch({ type: DESTINATION_SEARCH_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/destinations?q=${
        search || ""
      }`
    );
    let newData = data.sort((a, b) =>
      a.country < b.country ? -1 : a.country > b.country ? 1 : 0
    );
    if (!data.length && search) {
      const { data: allDests } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/public/destinations`
      );
      newData = allDests.filter(
        (dest) => dest.region?.toLowerCase() === search?.toLowerCase()
      );
    }
    dispatch({
      type: DESTINATION_SEARCH_SUCCESS,
      payload: newData,
    });
  } catch (err) {
    dispatch({
      type: DESTINATION_SEARCH_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getDestinationSuggestions = () => async (dispatch) => {
  dispatch({ type: DESTINATION_SUGESSTION_REQUEST });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/public/destinations`
    );
    dispatch({ type: DESTINATION_SUGESSTION_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: DESTINATION_SUGESSTION_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
