import { currency } from "../../../utils/format";
import VillasDetail from "../VillasDetails";
import "./VillasCard.css";

const VillasCard = ({ villa }) => {
  // to join each array of equipment with dash
  const getEquipment = (features) => {
    const getEachFeature = [];
    features.map((feature) => getEachFeature.push(feature.equipment));
    return getEachFeature.join(" — ");
  };

  const handleShowVillas = () => {
    const villas = document.querySelectorAll(".villasDetails");
    villas.forEach((v) => {
      if (v.classList.contains("show") && v.dataset.id !== villa.id + "villa") {
        v.classList.remove("show");
      } else {
        if (v.dataset.id === villa.id + "villa") {
          v.classList.add("show");
        }
      }
    });
  };

  const handleCloseVillas = () => {
    const villas = document.querySelectorAll(".villasDetails");
    villas.forEach((v) => {
      if (v.classList.contains("show")) {
        v.classList.remove("show");
      }
    });
  };

  return (
    <>
      <div className="villasCard" onClick={handleShowVillas}>
        <div className="villasCard__imgContainer">
          <img
            src={
              villa.images.length
                ? villa.images.sort((a, b) =>
                    a.position < b.position ? -1 : 1
                  )[0].url
                : "/images/villas/image1.png"
            }
            alt=""
            className="villasCard__img"
            loading="lazy"
          />
        </div>
        <div className="villasCard__container">
          <div className="villasCard__details">
            <div>
              <svg
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41881 4.74357C1.41881 3.95999 2.05403 3.32477 2.83761 3.32477H14.188C14.9716 3.32477 15.6068 3.95999 15.6068 4.74357V9.70938H1.41881V4.74357Z"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                />
                <path
                  d="M0.709396 11.1282C0.709396 10.3446 1.34462 9.70941 2.1282 9.70941H14.8974C15.681 9.70941 16.3162 10.3446 16.3162 11.1282V13.2564H0.709396V11.1282Z"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                />
                <rect
                  x="3.9017"
                  y="6.87183"
                  width="3.54701"
                  height="2.83761"
                  rx="0.709402"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                />
                <rect
                  x="9.57692"
                  y="6.87183"
                  width="3.54701"
                  height="2.83761"
                  rx="0.709402"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                />
                <path
                  d="M15.6068 13.2564V14.6752"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.41881 13.2564V14.6752"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>
                {/* {villa.features.reduce((a, q) => a + Number(q.chambers), 0)}{" "} */}
                {villa.bedrooms || 0}&nbsp; chambres
              </span>
            </div>
            <div>
              <svg
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.001 12.5857C11.8753 12.0565 10.2584 11.4829 8.53846 11.4829C6.81857 11.4829 5.20163 12.0565 4.07594 12.5857C3.04164 13.0719 2.4315 14.1085 2.29685 15.2434L2.15385 16.4487H14.9231L14.7801 15.2434C14.6454 14.1085 14.0353 13.0719 13.001 12.5857Z"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.53846 8.29062C10.3015 8.29062 11.7308 6.86138 11.7308 5.09831C11.7308 3.33525 10.3015 1.90601 8.53846 1.90601C6.7754 1.90601 5.34615 3.33525 5.34615 5.09831C5.34615 6.86138 6.7754 8.29062 8.53846 8.29062Z"
                  stroke="#C28382"
                  strokeWidth="1.4188"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>
                {/* {villa.features.reduce((a, q) => a + Number(q.travellers), 0)}{" "} */}
                {villa.guests || 0}&nbsp; voyageurs
              </span>
            </div>
            <div>
              <span>
                À partir de {currency(villa?.price?.toFixed(0)) || 0}€/nuit
              </span>
            </div>
          </div>
          <span className="villasCard__distanceTime">{villa.distanceTime}</span>
          <h3 className="villasCard__title">{villa.title}</h3>
          <p className="villasCard__description">
            {getEquipment(villa.features)}
          </p>
        </div>
      </div>
      <VillasDetail
        details={villa}
        closeDetails={handleCloseVillas}
        id={villa.id + "villa"}
      />
    </>
  );
};

export default VillasCard;
