// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activities {
  overflow-x: hidden;
}

.activities .heroSkeleton,
.activities .hero {
  margin-top: unset !important;
}


.activities__container {
  margin-top: 2rem;
}

.activities__title {
  font-size: 1.5rem;
  color: var(--clr-gray800);
  white-space: nowrap;
}

.activities .hero__imgContainer img {
  object-position: center 85%;
}

@media (min-width: 375px) {
  .activities__title {
    font-size: 1.8rem;
  }
}
@media (min-width: 768px) {
  .activities__container {
    margin-top: 3rem;
  }

  .activities__title {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .activities {
    overflow-x: unset;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Activities/Activities.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,4BAA4B;AAC9B;;;AAGA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".activities {\n  overflow-x: hidden;\n}\n\n.activities .heroSkeleton,\n.activities .hero {\n  margin-top: unset !important;\n}\n\n\n.activities__container {\n  margin-top: 2rem;\n}\n\n.activities__title {\n  font-size: 1.5rem;\n  color: var(--clr-gray800);\n  white-space: nowrap;\n}\n\n.activities .hero__imgContainer img {\n  object-position: center 85%;\n}\n\n@media (min-width: 375px) {\n  .activities__title {\n    font-size: 1.8rem;\n  }\n}\n@media (min-width: 768px) {\n  .activities__container {\n    margin-top: 3rem;\n  }\n\n  .activities__title {\n    font-size: 2rem;\n  }\n}\n@media (min-width: 992px) {\n  .activities {\n    overflow-x: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
