import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRequest } from "../../actions/requestActions";
import InputLabelFloat from "../InputLabelFloat";
import "./SearchBar.css";

const SearchBar = ({
  values,
  handleSearch,
  handleInputValue,
  handleRemoveValue,
  dataRequest,
  isFilter,
  handleShowFilter,
  isSuggestions,
  filterGuests,
}) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.villaFilter);

  return (
    <form className="searchBar" onSubmit={handleSearch}>
      <InputLabelFloat
        name="where"
        label="On va où ?"
        placeholder="On va où ?"
        isSuggestions={isSuggestions}
        filterInvites={filterGuests}
        value={values.where}
        handleInputValue={handleInputValue}
        handleRemoveValue={handleRemoveValue}
      />
      <InputLabelFloat
        name="when"
        label="Quand ?"
        placeholder="Quand ?"
        value={values.when}
        type={{
          type: "date",
          minDate: moment()._d,
          selected: dataRequest.date_from,
        }}
        handleInputDate={(date) => {
          if (date >= dataRequest.date_to) {
            dispatch(
              setRequest({
                ...dataRequest,
                tag: "original",
                offer_id: null,
                date_from: moment(date)._d,
                date_to: moment(date).add(1, "day")._d,
              })
            );
          } else {
            dispatch(
              setRequest({
                ...dataRequest,
                tag: "original",
                offer_id: null,
                date_from: date,
              })
            );
          }
        }}
      />
      <InputLabelFloat
        name="until"
        label="Jusqu’au ?"
        placeholder="Jusqu’au ?"
        type={{
          type: "date",
          minDate: moment(dataRequest.date_from).add(1, "day")._d,
          selected: dataRequest.date_to,
        }}
        value={values.until}
        handleInputDate={(date) =>
          dispatch(
            setRequest({
              ...dataRequest,
              tag: "original",
              offer_id: null,
              date_to: date,
            })
          )
        }
      />
      <InputLabelFloat
        name="addGuest"
        label="Ajouter des invités"
        placeholder="Ajouter des invités"
        maxLength={2}
        type={{
          type: "counter",
        }}
        value={values.addGuest}
        handleInputValue={handleInputValue}
        handleRemoveValue={handleRemoveValue}
      />
      {isFilter && (
        <InputLabelFloat
          name="filtres"
          label="Filtres"
          placeholder="Ajouter des invités"
          value={`${
            filters.arounds.length +
            filters.healties.length +
            filters.equipments.length +
            filters.properties.length
          } sélectionnés`}
          readOnly
          handleInputValue={handleInputValue}
          handleClick={handleShowFilter}
          handleRemoveValue={handleRemoveValue}
        />
      )}

      <button className="destinationsHero__btn btn">
        <span>Rechercher</span>
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.2106 33.4211C27.0589 33.4211 33.4211 27.0589 33.4211 19.2106C33.4211 11.3623 27.0589 5 19.2106 5C11.3623 5 5 11.3623 5 19.2106C5 27.0589 11.3623 33.4211 19.2106 33.4211Z"
            stroke="#FCFCFC"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35 35.0003L30.2632 30.2634"
            stroke="#FCFCFC"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </form>
  );
};

export default SearchBar;
