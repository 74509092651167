import axios from "axios";
import {
  COFFRET_GOODIES_REQUEST,
  COFFRET_GOODIES_SUCCESS,
  COFFRET_GOODIES_FAIL,
  COFFRET_REQUEST,
  COFFRET_SUCCESS,
  COFFRET_FAIL,
  COFFRET_PANIERS_REQUEST,
  COFFRET_PANIERS_SUCCESS,
  COFFRET_PANIERS_FAIL,
} from "../constants/coffretConstants";

export const getCoffretGoodies = () => async (dispatch) => {
  dispatch({ type: COFFRET_GOODIES_REQUEST });
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/public/products`);
    dispatch({ type: COFFRET_GOODIES_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: COFFRET_GOODIES_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getCoffrets = () => async (dispatch) => {
  dispatch({ type: COFFRET_REQUEST });
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/bundles?packaging=coffret`);
    dispatch({ type: COFFRET_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: COFFRET_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
}

export const getCoffretPaniers = () => async (dispatch) => {
  dispatch({ type: COFFRET_PANIERS_REQUEST });
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/bundles?packaging=panier`);
    dispatch({ type: COFFRET_PANIERS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: COFFRET_PANIERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
}
