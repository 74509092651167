import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDestinations } from "../../actions/destinationActions";
import { getVillas } from "../../actions/villaActions";
import MessageMenu from "../../components/MessageComponents/MessageMenu";
import MessageSection from "../../components/MessageComponents/MessageSection";
import ReservationSection from "../../components/ReservationComponents/ReservationSection";
import "./Message.css";

const Message = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.createRequest);
  const [page, setPage] = useState("");

  useEffect(() => {
    if (!user) {
      history.push("/auth");
    }
    if (window.location.pathname?.includes("message")) {
      setPage("message");
    } else {
      setPage("reservation");
    }
    dispatch(getDestinations());
    dispatch(getVillas());
  }, [history, user, dispatch]);
  return (
    <div className="message">
      <section className="message__hero">
        <div className="mesaage__heroText container">
          <h1>
            Bonjour, <span>{user?.first_name} !</span>
          </h1>
        </div>
      </section>
      <section className="message__container container">
        <MessageMenu />
        {page === "message" ? <MessageSection /> : <ReservationSection />}
      </section>
    </div>
  );
};

export default Message;
