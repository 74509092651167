import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessageRooms } from "../../../actions/messageActions";
import BadgeRequest from "../../BadgeRequest";
import "./MessageAllMessage.css";
import avatar from "../../../assets/avatar.svg";
import Loading from "../../Loading";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";

const MessageAllMessage = ({ openChat }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { rooms, loading } = useSelector((state) => state.rooms);
  const { room } = useSelector((state) => state.room);
  const { user } = useSelector((state) => state.createRequest);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(getMessageRooms());
  }, [dispatch]);

  return (
    <div className="messageAllMessage">
      <div>
        <form className="messageAllMessage__searchInput">
          <svg
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 20.5C15.9706 20.5 20 16.4706 20 11.5C20 6.52944 15.9706 2.5 11 2.5C6.02944 2.5 2 6.52944 2 11.5C2 16.4706 6.02944 20.5 11 20.5Z"
              stroke="#6E7191"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22.5L18 18.5"
              stroke="#6E7191"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            type="text"
            placeholder="Rechercher"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          {searchName !== "" && (
            <svg
              className="messageAllMessage__inputClose"
              viewBox="0 0 14 14"
              onClick={() => setSearchName("")}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L13 13"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 13L13 1.00002"
                stroke="#6E7191"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </form>
        <ul className="messageAllMessage__cards">
          {loading ? (
            <div className="loading-container-text h-full">
              <Loading /> Chargement …
            </div>
          ) : rooms.filter((room) => room?.request?.client_id === user?.user_id)
              .length ? (
            rooms
              .filter((room) => room?.request?.client_id === user?.user_id)
              .sort((a, b) => b.last_activity.localeCompare(a.last_activity))
              .map(
                (rm) =>
                  rm.title.toLowerCase().includes(searchName.toLowerCase()) && (
                    <li
                      className={`messageAllMessage__card ${
                        rm.id === room.id && "active"
                      }`}
                      onClick={() => openChat(rm.id)}
                      key={rm.id}
                    >
                      <img
                        src={avatar}
                        alt="person"
                        className="messageAllMessage__imgPerson"
                      />
                      <div>
                        <div
                          className={`messageAllMessage__cardTop ${
                            !Object.keys(room).length && "full-width"
                          }`}
                        >
                          <h3>{rm.title}</h3>
                          <span className="messageAllMessage__time">
                            {new Date(rm.last_activity).getFullYear() ===
                            new Date(moment()).getFullYear()
                              ? new Date(rm.last_activity).getDay() ===
                                new Date(moment().add(-1, "days")).getDay()
                                ? "Hier"
                                : new Date(rm.last_activity).getTime() <
                                  new Date(moment().add(-1, "days")).getTime()
                                ? moment(rm.last_activity).format("DD/MM")
                                : moment(rm.last_activity).format("LT")
                              : moment(rm.last_activity).format("DD/MM/YYYY")}
                          </span>
                        </div>
                        <div className="messageAllMessage__cardBottom">
                          <BadgeRequest room={rm} />
                        </div>
                      </div>
                    </li>
                  )
              )
          ) : (
            <div className="loading-container-text h-full">
              Vous n’avez pas de message pour le moment
            </div>
          )}
        </ul>
      </div>
      <button
        className="messageAllMessage__btnNewReservation btn outline"
        onClick={() => {
          window.scrollTo({ top: 0 });
          history.push("/destinations");
        }}
      >
        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 2V14"
            stroke="#A94E4D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 8L14.5 8"
            stroke="#A94E4D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Nouvelle réservation
      </button>
    </div>
  );
};

export default MessageAllMessage;
