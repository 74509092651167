import React, { useState } from "react";
import "./FaqQuestionBox.css";

const FaqQuestionBox = ({ question }) => {
  const [openAnswer, setOpenAnswer] = useState(false);
  return (
    <div
      className="faqQuestionBox"
      onClick={() => setOpenAnswer((openAnswer) => !openAnswer)}
      key={question.id}
    >
      <span>{question.title}</span>
      {openAnswer && (
        <p dangerouslySetInnerHTML={{ __html: question.answer }} />
      )}
    </div>
  );
};

export default FaqQuestionBox;
