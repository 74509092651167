import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaymentInformation from "../../components/PaymentInformation";
import "./Auth.css";

const Auth = () => {
  const { dataRequest } = useSelector((state) => state.request);
  const { user } = useSelector((state) => state.createRequest);
  const history = useHistory();

  useEffect(() => {
    if (user) history.push("/message");
  }, [user, history]);

  return (
    <div className="auth container">
      <div className="auth__container">
        <h1>Connexion / Inscription</h1>
        <PaymentInformation dataRequest={dataRequest} isLogin={true} />
      </div>
    </div>
  );
};

export default Auth;
