import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setRequest } from "../../../actions/requestActions";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./VillasDetails.css";
import PopupImagesGallery from "../../PopupImagesGallery";
import { currency } from "../../../utils/format";

SwiperCore.use([Pagination, Navigation]);

const VillasDetail = ({
  details,
  closeDetails,
  isQuiz,
  handleClickQuizz,
  id,
  offerPrice,
  offerActivities,
  offerBundles,
  offerProducts,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = useState();
  const [openPopupGallery, setOpenPopupGallery] = useState(false);
  const [selectedShowImageIndex, setSelectedShowImageIndex] = useState(null);
  const { dataRequest } = useSelector((state) => state.request);

  const handleSelect = () => {
    setSelected(true);
    history.push("/activities");
    window.scrollTo({ top: 0 });
    dispatch(
      setRequest({
        ...dataRequest,
        tag: "original",
        offer_id: null,
        property: details?.id,
        destination_id: details?.destination_id,
      })
    );
  };

  const handleOpenGalleryPopup = (idx) => {
    setOpenPopupGallery(true);
    setSelectedShowImageIndex(idx);
  };

  return (
    <>
      <div className={`villasDetails`} data-id={id}>
        <div>
          <div className="villasDetails__imgContainer">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
            >
              {details?.images?.length ? (
                details?.images
                  ?.sort((a, b) => (a.position < b.position ? -1 : 1))
                  .map((detail, idx) => (
                    <SwiperSlide key={detail.id}>
                      <div className="villasDetails__imgContainer">
                        <img
                          src={detail?.url}
                          alt=""
                          className="villasDetails__img"
                          onClick={() => handleOpenGalleryPopup(idx)}
                        />
                      </div>
                    </SwiperSlide>
                  ))
              ) : (
                <>
                  <SwiperSlide>
                    <img
                      src="/images/villas/image1.png"
                      alt=""
                      onClick={() => handleOpenGalleryPopup(0)}
                      className="villasDetails__img"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="/images/villas/image1.png"
                      alt=""
                      onClick={() => handleOpenGalleryPopup(1)}
                      className="villasDetails__img"
                    />
                  </SwiperSlide>
                </>
              )}
            </Swiper>
            <svg
              onClick={closeDetails}
              className="villasDetails__closeIcon"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 36.4167C28.619 36.4167 36.4167 28.619 36.4167 19C36.4167 9.38108 28.619 1.58337 19 1.58337C9.38104 1.58337 1.58333 9.38108 1.58333 19C1.58333 28.619 9.38104 36.4167 19 36.4167ZM13.1304 13.1304C13.7487 12.5121 14.7513 12.5121 15.3696 13.1304L19 16.7608L22.6304 13.1304C23.2487 12.5121 24.2513 12.5121 24.8696 13.1304C25.4879 13.7487 25.4879 14.7513 24.8696 15.3696L21.2392 19L24.8696 22.6304C25.4879 23.2487 25.4879 24.2513 24.8696 24.8696C24.2513 25.4879 23.2487 25.4879 22.6304 24.8696L19 21.2392L15.3696 24.8696C14.7513 25.4879 13.7487 25.4879 13.1304 24.8696C12.5121 24.2513 12.5121 23.2487 13.1304 22.6304L16.7608 19L13.1304 15.3696C12.5121 14.7513 12.5121 13.7487 13.1304 13.1304Z"
                fill="#FCFCFC"
              />
            </svg>
          </div>
          <h2 className="villasDetails__title">{details?.title}</h2>
          <div>
            <div className="villasCard__details">
              <div>
                <svg
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.41881 4.74357C1.41881 3.95999 2.05403 3.32477 2.83761 3.32477H14.188C14.9716 3.32477 15.6068 3.95999 15.6068 4.74357V9.70938H1.41881V4.74357Z"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                  />
                  <path
                    d="M0.709396 11.1282C0.709396 10.3446 1.34462 9.70941 2.1282 9.70941H14.8974C15.681 9.70941 16.3162 10.3446 16.3162 11.1282V13.2564H0.709396V11.1282Z"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                  />
                  <rect
                    x="3.9017"
                    y="6.87183"
                    width="3.54701"
                    height="2.83761"
                    rx="0.709402"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                  />
                  <rect
                    x="9.57692"
                    y="6.87183"
                    width="3.54701"
                    height="2.83761"
                    rx="0.709402"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                  />
                  <path
                    d="M15.6068 13.2564V14.6752"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.41881 13.2564V14.6752"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>
                  {details?.bedrooms || 0}&nbsp;
                  {/* {details?.features.reduce((a, q) => a + Number(q.chambers), 0)}{" "} */}
                  chambres
                </span>
              </div>
              <div>
                <svg
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.001 12.5857C11.8753 12.0565 10.2584 11.4829 8.53846 11.4829C6.81857 11.4829 5.20163 12.0565 4.07594 12.5857C3.04164 13.0719 2.4315 14.1085 2.29685 15.2434L2.15385 16.4487H14.9231L14.7801 15.2434C14.6454 14.1085 14.0353 13.0719 13.001 12.5857Z"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.53846 8.29062C10.3015 8.29062 11.7308 6.86138 11.7308 5.09831C11.7308 3.33525 10.3015 1.90601 8.53846 1.90601C6.7754 1.90601 5.34615 3.33525 5.34615 5.09831C5.34615 6.86138 6.7754 8.29062 8.53846 8.29062Z"
                    stroke="#C28382"
                    strokeWidth="1.4188"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>
                  {details?.guests || 0}&nbsp;
                  {/* {details?.features.reduce((a, q) => a + Number(q.travellers), 0)}{" "} */}
                  voyageurs
                </span>
              </div>
              <div>
                <span>
                  À partir de {currency(details?.price?.toFixed(0)) || 0}€/nuit
                </span>
              </div>
            </div>
          </div>
          <span className="villasCard__distanceTime">
            {details?.distanceTime}
          </span>
          <strong className="villasDetails__moreDetails">
            {/* {details ? details?.details?.split(". ").join(".\n") : ""} */}
          </strong>
          {/* <p className="villasDetails__description"> */}
          {/* {details ? details?.description?.split(". ").join(".\r\n\r\n") : ""} */}
          {/* </p> */}
          <p
            className="villasDetails__description"
            dangerouslySetInnerHTML={{ __html: details?.description?.body }}
          />
          <div className="villaDetails__activityContainer">
            {offerActivities?.length && isQuiz ? (
              <>
                <h4>Activité(s)</h4>
                <ul className="villaDetails__activities">
                  {offerActivities.map((activity) => (
                    <li key={activity.id}>
                      {activity?.image?.url && (
                        <img src={activity?.image?.url} alt={activity.title} />
                      )}

                      {activity.title}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
            {offerBundles?.length && isQuiz ? (
              <>
                <h4>Coffret(s)</h4>
                <ul className="villaDetails__activities">
                  {offerBundles.map((bundle) => (
                    <li key={bundle.id}>
                      {bundle?.image?.url && (
                        <img src={bundle?.image?.url} alt={bundle.name} />
                      )}

                      {bundle.name}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
            {offerProducts?.length && isQuiz ? (
              <>
                <h4>Accessoire(s)</h4>
                <ul className="villaDetails__activities">
                  {offerProducts.map((acc) => (
                    <li key={acc.id}>
                      {acc?.image?.url && (
                        <img src={acc?.image?.url} alt={acc.title} />
                      )}

                      {acc.title}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
            {details?.services?.length ? (
              <>
                <h4>Service(s)</h4>
                <ul className="villaDetails__activities">
                  {details?.services.map((service, idx) => (
                    <li key={idx + service.label}>
                      {service?.image?.url && (
                        <img src={service?.image?.url} alt={service.label} />
                      )}

                      {service.label}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
            {details?.equipments?.length ? (
              <>
                <h4>Équipement(s)</h4>
                <ul className="villaDetails__activities">
                  {details?.equipments.map((equipment, idx) => (
                    <li key={idx + equipment.label}>
                      {equipment?.image?.url && (
                        <img
                          src={equipment?.image?.url}
                          alt={equipment.label}
                        />
                      )}

                      {equipment.label}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
            {details?.vicinities?.length ? (
              <>
                <h4>Environ</h4>
                <ul className="villaDetails__activities">
                  {details?.vicinities.map((vicinity, idx) => (
                    <li key={idx + vicinity.label}>
                      {vicinity?.image?.url && (
                        <img src={vicinity?.image?.url} alt={vicinity.label} />
                      )}

                      {vicinity.label}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        {!isQuiz && (
          <div className="villasDetails__bottom">
            {isQuiz ? (
              <h4 className="villasDetails__price">
                À partir de €{currency(offerPrice.toFixed(0)) || 0}€
                <small>/nuit</small>
              </h4>
            ) : (
              <h4 className="villasDetails__price">
                €{currency(details?.price?.toFixed(0)) || 0}
                <small>/nuit</small>
              </h4>
            )}

            {isQuiz ? (
              <button className={`btn`} onClick={handleClickQuizz}>
                Aller au paiement
              </button>
            ) : (
              <button
                className={`btn ${
                  (dataRequest.property === details?.id || selected) &&
                  "selected"
                }`}
                onClick={handleSelect}
              >
                {dataRequest.property === details?.id || selected
                  ? "Ajouté"
                  : "Ajouter au devis"}
                <i className="tick-mark"></i>
              </button>
            )}
          </div>
        )}
      </div>
      {openPopupGallery && (
        <PopupImagesGallery
          closePopup={() => setOpenPopupGallery(false)}
          images={details?.images.sort((a, b) =>
            a.position < b.position ? -1 : 1
          )}
          selectedShowImageIndex={selectedShowImageIndex}
        />
      )}
    </>
  );
};

export default VillasDetail;
