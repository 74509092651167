// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth {
  padding: 3em 0;
}

.auth__container {
  max-width: 45rem;
  width: 100%;
}

.auth__container h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  .auth {
    padding: 1em 0 5em 0;
  }

  .auth__container h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    padding-left: 5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Auth.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;AACF","sourcesContent":[".auth {\n  padding: 3em 0;\n}\n\n.auth__container {\n  max-width: 45rem;\n  width: 100%;\n}\n\n.auth__container h1 {\n  font-size: 2rem;\n}\n\n@media (min-width: 768px) {\n  .auth {\n    padding: 1em 0 5em 0;\n  }\n\n  .auth__container h1 {\n    font-size: 2.5rem;\n    margin-bottom: 2rem;\n    padding-left: 5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
