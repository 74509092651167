export const selectBoxes = [
    {
    id: 1,
    title: "La box classique 150€",
    description: `Dans cette box vous trouverez tout le nécessaire pour créer une ambiance festive et décorer la chambre de la future mariée (banderoles, guirlandes, ballons, accessoires…) sur le thème et la couleur de votre choix. Rendez son arrivée et la découverte de sa chambre unique grâce à notre box décoration, un détail que nous vous conseillons sans hésiter. `
    },
    {
    id: 2,
    title: "La box Pop 300€",
    description: `Dans cette box vous trouverez tout le nécessaire pour créer une ambiance festive et décorer la chambre de la future mariée ainsi qu’une pièce commune de votre logement.<br />
    Surprenez la bride to be dès son arrivée sur les lieux de l’événement avant même qu’elle découvre sa chambre. Et profitez de ce lieu personnalisé le temps de votre séjour. (Banderoles, guirlandes, ballons, bouées, accessoires,) !
    `
    },
];

export const selectServices = [
    {
        id: 1,
        title: "Service de décoration",
        description: `Une décoration clé en main ! <br />
        Notre rôle : Celui de transformer un lieu pendant quelques heures ou plusieurs jours, pour faire de votre évènement, quel qu’il soit, un moment inoubliable. 
        Nous nous occupons de tous les détails pour embellir le lieu que vous aurez choisi. Nous définissions ensemble votre thème, votre code couleur, pour créer une scénographie en fonction de vos envies et de vos attentes. Nous serons force de proposition pour vous conseiller et vous accompagner au mieux dans votre projet. <br />
        Tips Maiden : Nous conseillons à nos clientes de garder un fil conducteur dans la décoration de leurs événements.`
    },
    {
        id: 2,
        title: "Box de décoration",
        description: `Une décoration personnalisée en Kit <br />
        Nous vous faisons parvenir une box avec les éléments indispensables pour décorer votre lieu. <br />
        Vous aimez réaliser vous-même vos décorations ? recevez chez vous votre kit prêt à l’emploi pour pouvoir décorer votre événement home made et en faire un moment unique.`
    },
]