import React, { useState } from "react";
import filledStar from "../../assets/filled_star.svg";
import FaqQuestionBox from "../../components/FaqQuestionBox";
import Hero from "../../components/Hero";
import heroImg from "../../assets/heroImg2.png";
import "./Faq.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../../actions/faqActions";

const Faq = () => {
  const dispatch = useDispatch();
  const { faqs } = useSelector((state) => state.faq);
  const [searchKey, setSearchKey] = useState("");

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    dispatch(getFaq());
  }, [dispatch]);
  return (
    <div className="faq">
      <Hero
        title="Des questions ? On y répond !"
        heroImg={heroImg}
        model="Model 2"
        isInput={true}
        headText="FAQ"
        handleSearch={handleSearch}
        height="medium"
      />
      <div className="container">
        <div className="faq__topSection">
          {faqs.map(
            (faq) =>
              faq.featured && (
                <div className="faq__topBox" key={faq.id}>
                  <img src={filledStar} alt="" />
                  <h3>{faq.title}</h3>
                  <span>{faq.description}</span>
                </div>
              )
          )}
        </div>
        <div className="faq__questions">
          {faqs
            .filter((faq) => faq.title?.toLowerCase()?.includes(searchKey))
            .map((faq) => (
              <FaqQuestionBox question={faq} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
