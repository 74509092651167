// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment {
  padding-top: 2em;
}

.payment__header > div {
  display: flex;
  align-items: center;
}

.payment__header > div svg {
  display: block;
  width: 1rem;
  margin-right: 0.5rem;
}

.payment__response span {
  color: var(--clr-primary);
}

.payment__container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
}

.payment__imgDestination {
  /* aspect-ratio: 5 / 1.8; */
  width: 100%;
  object-fit: cover;
  height: 18rem;
  border-radius: 1.5em;
  /* max-width: 10rem; */
}

@media (min-width: 992px) {
  .payment__container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    gap: 1rem;
  }

  .payment__detail {
    width: 100%;
    flex: 1 1 40%;
  }

  .payment__imgDestination {
    max-width: 35rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Payment/Payment.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,SAAS;EACX;;EAEA;IACE,WAAW;IACX,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".payment {\n  padding-top: 2em;\n}\n\n.payment__header > div {\n  display: flex;\n  align-items: center;\n}\n\n.payment__header > div svg {\n  display: block;\n  width: 1rem;\n  margin-right: 0.5rem;\n}\n\n.payment__response span {\n  color: var(--clr-primary);\n}\n\n.payment__container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 2rem;\n  gap: 1rem;\n}\n\n.payment__imgDestination {\n  /* aspect-ratio: 5 / 1.8; */\n  width: 100%;\n  object-fit: cover;\n  height: 18rem;\n  border-radius: 1.5em;\n  /* max-width: 10rem; */\n}\n\n@media (min-width: 992px) {\n  .payment__container {\n    display: flex;\n    flex-direction: row;\n    margin-top: 2rem;\n    gap: 1rem;\n  }\n\n  .payment__detail {\n    width: 100%;\n    flex: 1 1 40%;\n  }\n\n  .payment__imgDestination {\n    max-width: 35rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
