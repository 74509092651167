import {
  BLOG_FAIL,
  BLOG_REQUEST,
  BLOG_SUCCESS,
} from "../constants/blogConstants";

export const blogReducers = (state = { posts: [] }, action) => {
  switch (action.type) {
    case BLOG_REQUEST:
      return { loading: true };
    case BLOG_SUCCESS:
      return {
        loading: false,
        posts: action.payload,
      };
    case BLOG_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
