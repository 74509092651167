import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getDestinations } from "../../actions/destinationActions";
import { createQuizzAnswers, getQuizzes } from "../../actions/quizzActions";
import { setRequest } from "../../actions/requestActions";
import { getVillas } from "../../actions/villaActions";
import Loading from "../../components/Loading";
import QuizzCard from "../../components/QuizzComponents/QuizzCard";
import ReservationCard from "../../components/ReservationComponents/ReservationCard";
import { arrowLeft, arrowRight, handleScroll } from "../../utils/navArrow";
import "./Quizz.css";

const Quizz = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [results, setResutls] = useState([]);
  const [loadingResults, setLoadingResutls] = useState(false);
  const { loading, quizzes } = useSelector((state) => state.quizz);
  const [offers, setOffers] = useState([]);
  // const { user } = useSelector((state) => state.createRequest);
  const { answers } = useSelector((state) => state.quizzAnswer);
  const { destinations, loading: loadingDestinations } = useSelector(
    (state) => state.destination
  );
  const { villas, loading: loadingVillas } = useSelector(
    (state) => state.villa
  );

  const handleNextQuizz = () => {
    if (currentQuestion <= quizzes.length - 1) {
      setCurrentQuestion((currentQuestion) => currentQuestion + 1);
      if (currentQuestion === quizzes.length - 1) {
        dispatch(getDestinations());
        dispatch(getVillas());
        const resFil = offers.filter(
          (data) =>
            data.tag_list
              .map((tag) => tag.trim()?.toLowerCase())
              .filter((tag) =>
                answers
                  .map((answer) => answer.answer.trim()?.toLowerCase())
                  ?.includes(tag)
                  ? tag
                  : null
              ).length
        );
        setResutls(resFil);
        dispatch(createQuizzAnswers([]));
      }
    }
  };

  const handlePrevQuizz = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((currentQuestion) => currentQuestion - 1);
    }
  };

  const handleClickResultQuiz = (result) => {
    dispatch(setRequest({ ...result, tag: "quizz" }));
    history.push("/payment");
  };

  //   for results quiz handle clicked arrow left right and when scroll it.
  const contentContainer = useRef(null);
  const mainContainer = useRef(null);
  const [navShow, setNavShow] = useState({
    left: false,
    right: true,
  });

  useEffect(() => {
    dispatch(getQuizzes());
    (async function () {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/offers`
        );
        if (res.status === 200) {
          setOffers(res.data);
        }
      } catch (err) {
        return err;
      }
    })();
  }, [dispatch]);

  return (
    <div className="quizz container">
      <div className="quizz__header">
        <h1>Maiden’s Quizz</h1>
        <p>
          L’organisation de l’EVJF de votre meilleure amie est lancée mais vous
          ne savez pas où partir. Nous sommes là pour vous aider ! Répondez à
          notre quizz en fonction de ce qu’elle aime et nous vous proposerons
          une ou plusieurs destinations pour le séjour !
        </p>
      </div>
      <div className="quizz__content">
        {loading ? (
          <div className="loading-container-text">
            <Loading />
            S'il vous plaît, attendez...
          </div>
        ) : currentQuestion > quizzes.length - 1 ? (
          <>
            <h2 className="quizz__quizzTitle">
              Voici les séjours qui correspondent à votre meilleure amie !
            </h2>
            <div
              className={`quizz__resultsContainer ${
                results.length > 2 && "max-left-side"
              }`}
              ref={mainContainer}
            >
              {navShow.left && (
                <svg
                  className="quizz__chevron left"
                  viewBox="0 0 80 80"
                  onClick={() =>
                    arrowLeft(
                      contentContainer,
                      mainContainer,
                      navShow,
                      setNavShow,
                      "max-right-side",
                      "max-left-side"
                    )
                  }
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M46.6667 63.3333L23.4562 40.1228L46.6667 16.9122"
                    stroke="#14142B"
                    strokeWidth="6.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <div
                className="quizz__results"
                ref={contentContainer}
                onScroll={() =>
                  handleScroll(
                    contentContainer,
                    mainContainer,
                    setNavShow,
                    "max-right-side",
                    "max-left-side"
                  )
                }
              >
                {loadingResults ? (
                  <div className="loading-container-text">
                    <Loading />
                    S'il vous plaît, attendez...
                  </div>
                ) : results.length ? (
                  results.map((result) => (
                    <ReservationCard
                      key={result.id}
                      destinations={destinations}
                      loadingDestinations={loadingDestinations}
                      villas={villas}
                      loadingVillas={loadingVillas}
                      room={result}
                      results={offers}
                      isQuiz={true}
                      handleClick={handleClickResultQuiz}
                    />
                  ))
                ) : (
                  <div className="loading-container-text">
                    Aucune offre ne correspond à vos réponses, veuillez
                    réessayer
                  </div>
                )}
              </div>
              {navShow.right && results.length > 2 ? (
                <svg
                  className="quizz__chevron right"
                  viewBox="0 0 80 80"
                  onClick={() =>
                    arrowRight(
                      contentContainer,
                      mainContainer,
                      navShow,
                      setNavShow,
                      "max-right-side",
                      "max-left-side"
                    )
                  }
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.3333 16.6667L56.5439 39.8773L33.3333 63.0878"
                    stroke="#14142B"
                    strokeWidth="6.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <>
            <h2 className="quizz__quizzTitle">
              <span className="quizz__number">{currentQuestion + 1}</span>
              {quizzes[currentQuestion].question.split("?")[0]}&nbsp;?
            </h2>
            <div className="quizz__cardContainer">
              {quizzes[currentQuestion].options.map((quizz) => (
                <QuizzCard
                  quizz={quizz}
                  key={quizz.id}
                  currentQuestion={currentQuestion}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <div
        className={`quizz__nav ${
          currentQuestion === quizzes.length && "end-nav"
        }`}
      >
        {currentQuestion <= quizzes.length - 1 && (
          <div>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="11" stroke="#14142B" strokeWidth="2" />
              <path
                d="M12 7V13L14.5 15.5"
                stroke="#14142B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Temps estimé : 5 minutes</span>
          </div>
        )}
        <div>
          <svg
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.05132 15.6617L18.0525 1.66054C18.0891 1.64879 18.132 1.63592 18.1805 1.62278C18.3837 1.56774 18.6757 1.51068 19.0188 1.50595C19.6849 1.49676 20.5653 1.68066 21.4201 2.53553C22.275 3.3904 22.4589 4.27079 22.4497 4.93692C22.445 5.27995 22.3879 5.57197 22.3329 5.77516C22.3198 5.82368 22.3069 5.86655 22.2951 5.90318L8.29396 19.9044L3.47278 20.4829L4.05132 15.6617Z"
              stroke="#14142B"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M14.7671 3.53162L20.1546 8.9191"
              stroke="#14142B"
              strokeWidth="2"
            />
          </svg>
          <span>
            Progression :{" "}
            {((currentQuestion / quizzes.length) * 100).toFixed(1)}%
          </span>
        </div>
        {currentQuestion <= quizzes.length - 1 && (
          <div className="quizz__navBtnContainer">
            <button className="btn outline" onClick={handlePrevQuizz}>
              <svg
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.47818 3.33334L2.00013 8L6.47818 12.6667"
                  stroke="#A94E4D"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="1"
                  y1="-1"
                  x2="10.822"
                  y2="-1"
                  transform="matrix(1 0 0 -1 2.17807 7.35449)"
                  stroke="#A94E4D"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button className="btn" onClick={handleNextQuizz}>
              Suivant
              <svg
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5218 3.33334L13.9999 8L9.5218 12.6667"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="12.8219"
                  y1="8.35449"
                  x2="2.9999"
                  y2="8.35449"
                  stroke="#FCFCFC"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Quizz;
