import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDestinations } from "../../actions/destinationActions";
import { setRequest } from "../../actions/requestActions";
import { filterVilla } from "../../actions/villaActions";
import FilterPopup from "../../components/FilterPopup";
import Hero from "../../components/Hero";
import Loading from "../../components/Loading";
import StepBar from "../../components/StepBar";
import VillasCard from "../../components/VillasComponents/VillasCard";
import HERO from "../../assets/hero/villa_page.jpg";
import "./Villas.css";
import { VILLAS_ADD_FILTER } from "../../constants/villaConstants";

const Villas = () => {
  const dispatch = useDispatch();
  const [isSuggestions, setIsSuggestions] = useState(false);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const { villas, loading } = useSelector((state) => state.villa);
  const [filterGuests, setFilterGuests] = useState(1);
  const { destinations } = useSelector((state) => state.destination);

  const [values, setValues] = useState({
    where: "",
    when: "",
    until: "",
    addGuest: "",
  });
  const { dataRequest } = useSelector((state) => state.request);
  const { filters } = useSelector((state) => state.villaFilter);
  const [currentPage, setCurrentPage] = useState(1);

  const [villasPerPage] = useState(6);
  const newVillas = villas.filter((d) =>
    filters.destinationId
      ? Number(filters.destinationId)
        ? d.destination_id === Number(filters.destinationId)
        : d.destination.country?.toLowerCase().trim() ===
            filters.destinationId ||
          d.destination.region?.toLowerCase().trim() === filters.destinationId
      : d
  );
  const indexOfLastVilla = currentPage * villasPerPage;
  const indexOfFirstVilla = indexOfLastVilla - villasPerPage;
  const currentVillas =
    !loading && newVillas.slice(indexOfFirstVilla, indexOfLastVilla);
  let pageNumbers = [];
  if (!loading) {
    for (let i = 1; i <= Math.ceil(newVillas.length / villasPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const handlePreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
    window.scrollTo(0, 0);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (loading) return;
    if (filters.invites !== filterGuests) {
      dispatch(filterVilla(filters));
      setFilterGuests(filters.invites);
    }
    const searchCity = destinations.find(
      (destination) =>
        destination?.city?.toLowerCase() === values?.where?.toLowerCase()
    );
    if (!values.where.trim()) return;
    if (searchCity) {
      dispatch({
        type: VILLAS_ADD_FILTER,
        payload: {
          ...filters,
          destinationId: searchCity.id,
        },
      });
    } else {
      dispatch({
        type: VILLAS_ADD_FILTER,
        payload: {
          ...filters,
          destinationId: filters.destinationId || values?.where?.toLowerCase(),
        },
      });
    }
  };

  const handleInputValue = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.name === "budget"
          ? e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
          : e.target.value,
    });

    setIsSuggestions(false);
    if (e.target.name === "where") {
      setIsSuggestions(true);
    }
  };

  const handleRemoveValue = (type) => {
    if (filters.invites > filterGuests) {
      dispatch(filterVilla(filters));
      setFilterGuests(filterGuests.invites);
    }
    setValues({
      ...values,
      [type]: "",
    });
    if (!filters.destinationId && !values.where.trim()) return;

    dispatch({
      type: VILLAS_ADD_FILTER,
      payload: {
        ...filters,
        destinationId: "",
      },
    });
  };

  useEffect(() => {
    // dispatch(getVillas());
    dispatch(filterVilla(filters));

    dispatch(getDestinations());

    document.onclick = (e) => {
      if (e.target.closest(`where-${Date.now()}`) === null) {
        setIsSuggestions(false);
      }
    };

    return () => {
      setIsSuggestions(false);
    };

    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="villas">
      <Hero
        title="Sélectionnez votre villa"
        heroImg={HERO}
        handleSearch={handleSearch}
        searchBar={{
          show: true,
          filterGuests,
          values,
          handleInputValue,
          handleRemoveValue,
          isSuggestions,
          isFilter: true,
          handleShowFilter: () => setOpenFilterPopup(true),
        }}
      />

      {/* <FilterBar /> */}
      {loading ? (
        <div className="loading-container-text h-full">
          <Loading />
          S'il vous plaît, attendez...
        </div>
      ) : newVillas.length ? (
        <>
          <div className="villas__container container">
            {currentVillas.map((villa) => (
              <VillasCard key={villa.id} villa={villa} />
            ))}
          </div>
          <div className="pagination-nav">
            {currentPage > 1 && (
              <button onClick={handlePreviousPage}>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.97546 1.33325L1.33335 5.97537L5.97546 10.6175"
                    stroke="#FCFCFC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
            <ul className="pagination-bullets">
              {Math.ceil(newVillas.length / villasPerPage) >= currentPage
                ? pageNumbers.map((pageNumber) => (
                    <li
                      className={`pagination-bullet ${
                        pageNumber === currentPage && "active"
                      }`}
                      key={`${pageNumber}-page`}
                    ></li>
                  ))
                : ""}
            </ul>
            {currentPage < Math.ceil(newVillas.length / villasPerPage) && (
              <button onClick={handleNextPage}>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.99988 1.33325L6.64199 5.97537L1.99988 10.6175"
                    stroke="#FCFCFC"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="loading-container-text h-full">
          Aucun logement disponible pour vos critères
          <button
            className="btn villas__btnReset"
            onClick={() => {
              dispatch({
                type: VILLAS_ADD_FILTER,
                payload: {
                  ...filters,
                  destinationId: "",
                },
              });
              dispatch(
                setRequest({
                  ...dataRequest,
                  tag: "original",
                  offer_id: null,
                  destination_id: null,
                })
              );
            }}
          >
            Voir toutes les villas
          </button>
        </div>
      )}
      <FilterPopup
        closePopup={() => setOpenFilterPopup(false)}
        show={openFilterPopup}
      />
      <StepBar completedStepOne loading />
    </div>
  );
};

export default Villas;
